import { connectToMqtt } from "./mqttService";

class AuthService {
  private webAddressPrefix: string = process.env.REACT_APP_LOCAL_BASE_URL as string;
  
  public async smartecLogin(
    email: string,
    password: string
    ): Promise<any | null> {
      try {
      const response = await fetch(`${this.webAddressPrefix}/auth/login`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const setCookieHeader = response.headers.get("Set-Cookie");
        const data = await response.json();
        let organizationsArray = data.orgs;
        let userId = data.user_id.toString();
        connectToMqtt(organizationsArray, userId); //Inside the array we have the orgIds
        // Extract cookies from the setCookieHeader if needed
        //return "success";
        return { result: "success", organizationsArray };
      } else {
        return null; // Invalid credentials or error
      }
    } catch (error) {
      console.error("Login error:", error);
      return null;
    }
  }
}

const authService = new AuthService();
export default authService;
