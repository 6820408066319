import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ElectricParameters } from "../../../types/interfaces";

const initialState: ElectricParameters = {
  current: "",
  currentTimestamps: "",
  currentSelected: false,
  voltage: "",
  voltageTimestamps: "",
  voltageSelected: false,
  temperature: "",
  temperatureTimestamps: "",
  temperatureSelected: false,
  apparentPower: "",
  apparentPowerTimestamps: "",
  apparentPowerSelected: false,
  activePower: "",
  activePowerTimestamps: "",
  activePowerSelected: false,
  frequency: "",
  frequencyTimestamps: "",
  frequencySelected: false,
  apparentEnergy: "",
  apparentEnergyTimestamps: "",
  apparentEnergySelected: false,
  activeEnergy: "",
  activeEnergyTimestamps: "",
  activeEnergySelected: false,
  powerGridVoltage: "",
  powerGridVoltageTimestamps: "",
  powerGridVoltageSelected: false
};

const electricParametersSlice = createSlice({
  name: "electricParameters",
  initialState: initialState,
  reducers: {
    getConsumption: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      current: action.payload.current,
      currentTimestamps: action.payload.currentTimestamps
    }),

    manageConsumptionSelected: (previousData) => ({
      ...previousData,
      currentSelected: !previousData.currentSelected,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getVoltage: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      voltage: action.payload.voltage,
      voltageTimestamps: action.payload.voltageTimestamps
    }),

    manageVoltageSelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: !previousData.voltageSelected,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getTemperature: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      temperature: action.payload.temperature,
      temperatureTimestamps: action.payload.temperatureTimestamps
    }),

    manageTemperatureSelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: !previousData.temperatureSelected,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getApparentPower: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      apparentPower: action.payload.apparentPower,
      apparentPowerTimestamps: action.payload.apparentPowerTimestamps
    }),

    manageApparentPowerSelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: !previousData.apparentPowerSelected,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getActivePower: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      activePower: action.payload.activePower,
      activePowerTimestamps: action.payload.activePowerTimestamps
    }),
    
    manageActivePowerSelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: !previousData.activePowerSelected,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getFrequency: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      frequency: action.payload.frequency,
      frequencyTimestamps: action.payload.frequencyTimestamps
    }),

    manageFrequencySelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: !previousData.frequencySelected,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getApparentEnergy: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      apparentEnergy: action.payload.apparentEnergy,
      apparentEnergyTimestamps: action.payload.apparentEnergyTimestamps
    }),

    manageApparentEnergySelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: !previousData.apparentEnergySelected,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),

    getActiveEnergy: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      activeEnergy: action.payload.activeEnergy,
      activeEnergyTimestamps: action.payload.activeEnergyTimestamps
    }),

    manageActiveEnergySelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: !previousData.activeEnergySelected,
      powerGridVoltageSelected: false
    }),

    getExternalVolt: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      powerGridVoltage: action.payload.powerGridVoltage,
      powerGridVoltageTimestamps: action.payload.powerGridVoltageTimestamps
    }),

    manageExternalVoltSelected: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: !previousData.powerGridVoltageSelected
    }),

    resetParameters: (previousData) => ({
      ...previousData,
      current: "",
      currentTimestamps: "",
      voltage: "",
      voltageTimestamps: "",  
      temperature: "",
      temperatureTimestamps: "",
      apparentPower: "",
      apparentPowerTimestamps: "",
      activePower: "",
      activePowerTimestamps: "",
      frequency: "",
      frequencyTimestamps: "",
      apparentEnergy: "",
      apparentEnergyTimestamps: "",
      activeEnergy: "",
      activeEnergyTimestamps: "",
      powerGridVoltage: "",
      powerGridVoltageTimestamps: "",
    }),

    resetButtons: (previousData) => ({
      ...previousData,
      currentSelected: false,
      voltageSelected: false,
      temperatureSelected: false,
      apparentPowerSelected: false,
      activePowerSelected: false,
      frequencySelected: false,
      apparentEnergySelected: false,
      activeEnergySelected: false,
      powerGridVoltageSelected: false
    }),
  },
});

export const electricParametersReducer = electricParametersSlice.reducer;

export const {
  getConsumption: getConsumptionActionCreator,
  manageConsumptionSelected: manageConsumptionSelectedActionCreator,
  getVoltage: getVoltageActionCreator,
  manageVoltageSelected: manageVoltageSelectedActionCreator,
  getTemperature: getTemperatureActionCreator,
  manageTemperatureSelected: manageTemperatureSelectedActionCreator,
  getApparentPower: getApparentPowerActionCreator,
  manageApparentPowerSelected: manageApparentPowerSelectedActionCreator,
  getActivePower: getActivePowerActionCreator,
  manageActivePowerSelected: manageActivePowerSelectedActionCreator,
  getFrequency: getFrequencyActionCreator,
  manageFrequencySelected: manageFrequencySelectedActionCreator,
  getApparentEnergy: getApparentEnergyActionCreator,
  manageApparentEnergySelected: manageApparentEnergySelectedActionCreator,
  getActiveEnergy: getActiveEnergyActionCreator,
  manageActiveEnergySelected: manageActiveEnergySelectedActionCreator,
  getExternalVolt: getExternalVoltActionCreator,
  manageExternalVoltSelected: manageExternalVoltSelectedActionCreator,
  resetParameters: resetParametersActionCreator,
  resetButtons: resetButtonsActionCreator
} = electricParametersSlice.actions;
