import { useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);

function SavingsChart() {
  const { t } = useTranslation();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context:any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += context.parsed.y + '%';
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        offset: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          font: {
            size: 16,
          }
        }
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      }
    }  
  };

  const getRandomNums = (min:number, max:number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const falseData = [0, 0, 0, 0, 0, 0, 0, 0];

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"]
  const data = {
    labels: labels,
    datasets: [
      {
        label: t("consumed"),
        data: falseData /* Array.from({length: 7}, () => getRandomNums(0, 100)) */,
        backgroundColor: "#E8F3FC",
        borderRadius: 4
      },
      {
        label: t("saved"),
        data: falseData /* Array.from({length: 7}, () => getRandomNums(0, 100)) */,
        backgroundColor: "#BFE9C6",
        borderRadius: 4
      }
    ]
  };

  return (
    <Bar width={600} height={206} options={options} data={data} />
  )
}

export default SavingsChart;
