import { useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { DataCard } from "../DataCard/DataCard";
import { DataCardListStyled } from "./DataCardListStyled";
import alarm from "../../assets/new_icons/Alarm.svg";
import disconnected from "../../assets/new_icons/Node disconnected.svg";
import lightBulb from "../../assets/new_icons/luminaire detailCard.svg";
import notPositioned from "../../assets/new_icons/Not positioned.svg";
import OFF from "../../assets/new_icons/OFF.svg";
import ON from "../../assets/new_icons/ON.svg";

export const DataCardList = () => {
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);

  const nodes:any = useAppSelector((state) => state.devices.nodes);
  const unpositionedNodes = nodes.filter((node: any) => !node.latitude || !node.longitude);

  const disconnectedLuminaires = nodes
    .filter((luminaire:any) => !luminaire.online)
    .length.toString();

  const onLuminaries = nodes
    .filter((luminaire:any) => luminaire.on && luminaire.online)
    .length.toString();

  const offLuminaries = nodes
    .filter((luminaire:any) => !luminaire.on && luminaire.online)
    .length.toString();

  const alarms = nodes
    .filter((luminaire:any) =>
      luminaire.alarm_status != 0 && luminaire.alarm_status != 2 ? luminaire.alarm_status : 0
    )
    .length.toString();

  const onNodes = nodes.filter((node:any) => node.online && node.on);

  let totalNodes = nodes.length;

  {
    /* VARIABLE COMENTADA HASTA QUE ARREGLEMOS EL TEMA DEL P.ENERGY EN EL BACK
  COMMENTED VARIABLE UNTIL WE TALK ABOUT P.ENERGY ISSUE ON THE BACK        */
  }
  /* const totalConsumption =
  nodes.length > 1 && onNodes.length > 0
    ? nodes //Ponía onNodes, pero lo he tenido que cambiar por la manera errática de funcionar de Shuncom. Javi
        .map((node) => Number.parseFloat(node.pEnergy))
        .filter((pEnergy) => pEnergy)
        .reduce((accumulator, pEnergy) => (accumulator += pEnergy / 1000))
        .toFixed(2)
        .toString() + " W/h"
    : "0 W/h"; */
  const { t } = useTranslation();

  return (
    <>
      {nodes && (
        <DataCardListStyled>
          <DataCard
            title={t("Total luminaires")}
            content={totalNodes.toString()}
            mainIcon={lightBulb}
          />
          <DataCard
            title={t("Not positioned")}
            content={unpositionedNodes.length.toString()}
            mainIcon={notPositioned}
          ></DataCard>
          <DataCard
            title={t("Disconnected")}
            content={disconnectedLuminaires}
            mainIcon={disconnected}
          />
          <DataCard
            title={t("ON luminaires")}
            content={onLuminaries}
            mainIcon={ON}
          />
          <DataCard
            title={t("OFF luminaires")}
            content={offLuminaries}
            mainIcon={OFF}
          />
          <DataCard
            title={t("Alarms")}
            content={alarms}
            mainIcon={alarm}
            isAlert
          />
          {/* CARD COMENTADA HASTA QUE ARREGLEMOS EL TEMA DEL P.ENERGY EN EL BACK
          COMMENTED CARD UNTIL WE TALK ABOUT P.ENERGY ISSUE ON THE BACK        */}
          {/* <DataCard
            title={cardInfo.Consumption}
            content={totalConsumption}
            mainIcon={bolt}
          />      */}
        </DataCardListStyled>
      )}
    </>
  );
};
