import { createSlice } from "@reduxjs/toolkit";
import { MqttIds } from "../../../types/interfaces";

const initialState: MqttIds = {
  nodeId: [],
  error: false
};

const mqttSlice = createSlice({
  name: "mqtt",
  initialState: initialState,
  reducers: {
    //function for pushing ids
    addIds: (previousState, action) => {     
      let tempArray: any = [];
      action.payload.forEach((id: any) => {
        tempArray.push(id)
      });
    
      return {
        ...previousState,
        error: false,
        nodeId: tempArray,
      }
    },

    //function for deleting ids
    deleteIds: (previousState, action) => {
      const updatedNodeIds = previousState.nodeId.filter(
        (item: any) => item !== action.payload
      );
      const updatedError = updatedNodeIds.length > 0 ? false : true;

      return {
        ...previousState,
        error: updatedError,
        nodeId: [...updatedNodeIds],
      };
    },

    //function for turning error to false
    errorToInitialState: (previousState) => ({
      ...previousState,
      error: false,
    })
  },
});

export const mqttReducer = mqttSlice.reducer;

export const { 
  addIds: addIdsActionCreator,
  deleteIds: deleteIdsActionCreator,
  errorToInitialState: errorToInitialStateActionCreator
 } = mqttSlice.actions;
