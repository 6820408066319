import { store } from '../../redux/store';
import { saveEnergyInfoActionCreator, saveSunriseHourActionCreator, saveSunsetHourActionCreator } from "../../redux/slices/strategiesSlice/strategiesSlice";

export const getLocalSunsetAndSunrise = (daySunset: any, daySunrise: any) => {
    let daysArray = [daySunset, daySunrise];

    daysArray.map((el: any, index: any) => {
        let date = new Date(el);
        let hours: any = date.getHours();
        let minutes: any = date.getMinutes();

        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let finalHour = hours + ':' + minutes;
        if (index === 0) {
            store.dispatch(saveSunsetHourActionCreator({ sunsetHour: finalHour }))
        } else if (index === 1) {
            store.dispatch(saveSunriseHourActionCreator({ sunriseHour: finalHour }))
        }
    })
}

export const parseLabelsAndData = (labels: any, data: any) => {
    let cleanLabels = [];
    let cleanData:any = [];

    for (let i = 0; i < data.length; i++) {
        if (data[i] !== null && data[i] !== undefined) {
        cleanLabels.push(labels[i]);
        cleanData.push(data[i]);
        }
    };

    let parsedHours = cleanLabels.map((item) => {
        let itemString = String(item);
        let result = itemString.indexOf(':') !== -1 ? itemString : itemString + ':00';
        return result;
    });

    let result = [parsedHours, cleanData];

    return result;
}

export const getHoursAndValues = (labels: any, data: any) => {
    let newLabels : any = [];
    for (let i = 0; i < labels.length; i++) {
        if (labels[i] === '254:254') {
          if (labels[i-1] === "Sunrise") {
            newLabels[newLabels.length - 1] = "11:59";
            break;
          }
          break;
        }
    
        if (labels[i] === "Sunrise" || labels[i] === "Sunset") {
          if (i === labels.length-1) {
            newLabels.push("11:59");
          } else {
            newLabels.push("12:00");
          }
        } else {
            newLabels.push(labels[i]);
        }
    }
    
    let hoursTurnedDate = newLabels.map((hour:any) => {
        let [hours, minutes] = hour?.split(':');
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        return date;
    });

    let hoursDiff = hoursTurnedDate.map((hour:any, i:any) => {
        let nextDate:any = hoursTurnedDate[(i + 1) % hoursTurnedDate.length];
        let diff = nextDate - hour;
        if (diff < 0) {
        diff += 24 * 60 * 60 * 1000;
        }
        return diff / (60 * 60 * 1000);
    });
    
    let hoursWithValues = hoursDiff.map((diff:any, i:any) => ({
        value: data[i],
        hour: diff
    }));
    
    hoursWithValues.pop();
    
    return hoursWithValues;
}

export const calculateEnergySaving = (hoursWithValues: any) => {
    let summedHours = hoursWithValues.reduce((total:any, item:any) => total + item.hour, 0);
    let multipliedHours = summedHours * 100;
    let resultsToSum : any = [];
    hoursWithValues.forEach((item: any) => {
        resultsToSum.push(item.value * item.hour);
    });

    let resultToSplit = resultsToSum.reduce((a: number, b: number) => a + b, 0);
    let resultToSubstract = resultToSplit / multipliedHours * 100;
    let resultToRound = 100 - resultToSubstract;
    let result = Math.round(resultToRound);

    //esta línea habrá que quitarla cuando usemos la api de tiempo y el astrodim sea real
    if (Math.sign(result) === -1) {
        result = 0
    }

    store.dispatch(saveEnergyInfoActionCreator({ saving: result }))
}
