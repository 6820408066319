import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { changeBackgroundDashboardActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { useLightingService } from "../../../services/lightingService";
import { languages } from "../../../utils/languages/languages";
import { DimmingPopUpStyled } from "./DimmingPopUpStyled";
import { googleIcons } from "../../../utils/googleFontsIcons/icons";
import { Text, TextBox } from "../../../styles/shared-styles/text-sizes";
import { useTranslation } from "react-i18next";

export const DimmingPopUp = () => {
  const [value, setValue] = useState(5);
  //node.dimming
  const [barColor, setBarColor] = useState("#d2d4d9");

  const { actualLanguage } = useAppSelector((state) => state.ui);
  const txt = languages[actualLanguage];
  const filteredDevices:any = useAppSelector((state) => state.filteredDevices);
  const { sendCommandToLuminaire } = useLightingService();

  const { close, lightBulb } = googleIcons;

  const dispatch = useAppDispatch();
  const changeBackground = () => {
    dispatch(changeBackgroundDashboardActionCreator());
  };

  const handleChange = (event: any) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
    setBarColor(
      `linear-gradient(to right, #ffc107 ${newValue}%, #d2d4d9 ${newValue}%)`
    );
  };

  const handleDimming = () => {
    const nodesToSwitch = filteredDevices.nodes.filter(
      (node:any) => node.online
    );

    sendCommandToLuminaire(nodesToSwitch, 3, value, 0);
    changeBackground();
  };

  const { t } = useTranslation();

  return (
    <DimmingPopUpStyled>
      <div className="popup__button-container">
        <span
          onClick={() => {
            changeBackground();
          }}
          className="material-symbols-rounded popup__button-container__icon"
        >
          {close}
        </span>
      </div>
      <div className="popup__body-container">
        <Text
          fontWeight="700"
          marginBottom="8px"
          textType="subTitle"
          color="#000000"
        >
          {t(txt.dimmingPopUpTitle)}
        </Text>
        <Text
          fontWeight="400"
          marginBottom="18px"
          textType="caption"
          color="#000000"
          style={{ fontSize: "12px" }}
        >
          {t(txt.dimmingPopUpSubtitle)}
        </Text>
        <div className="popup__body-container__box">
          <div
            className="popup__body-container__box__title"
            style={{ marginBottom: "8px" }}
          >
            <TextBox color="#A3AED0">
              {t(txt.dimming)} {value}%
            </TextBox>
            <span className="material-symbols-rounded popup__body-container__box__title--icon">
              {lightBulb}
            </span>
          </div>
          <div>
            <input
              type="range"
              min="5"
              max="100"
              step="5"
              value={value}
              onChange={handleChange}
              className="popup__body-container__box__progress-bar"
              style={{ background: barColor }}
            />
            <div style={{ background: barColor }}></div>
          </div>
          <div className="popup__body-container__box__percentages">
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming5}
            </TextBox>
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming50}
            </TextBox>
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming100}
            </TextBox>
          </div>
        </div>
        <div
          className="popup__body-container__button"
          onClick={() => {handleDimming()}}
        >
          <TextBox
            fontWeight="500"
            color="#000000"
            style={{ fontSize: "16px" }}
          >
            {t(txt.dimmingAll)}
          </TextBox>
        </div>
      </div>
    </DimmingPopUpStyled>
  );
};
