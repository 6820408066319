import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Select } from "antd";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { manageConsumptionSelectedActionCreator, manageVoltageSelectedActionCreator, manageTemperatureSelectedActionCreator, manageApparentPowerSelectedActionCreator,
  manageActivePowerSelectedActionCreator, manageFrequencySelectedActionCreator, manageApparentEnergySelectedActionCreator, manageActiveEnergySelectedActionCreator,
  manageExternalVoltSelectedActionCreator, resetParametersActionCreator, resetButtonsActionCreator
} from "../../../redux/slices/electricParametersSlice/electricParametersSlice";
import ElectricalParametersChart from "./ElectricalParametersChart";
import { useConsumptionsService } from "../../../services/consumptionsService";
import { languages } from "../../../utils/languages/languages";
import { NextButton } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import arrowBack from "../../../assets/new_icons/Arrow left.svg";
import arrowForward from "../../../assets/new_icons/Arrow right.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";

interface ElectricalParametersCardProps {
  selectedLamp: any
}

export const ElectricalParametersCard = ({ selectedLamp }: ElectricalParametersCardProps) => {
  const dispatch = useAppDispatch();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [errorNoParams, setErrorNoParams] = useState(false);
  const [errorNoData, setErrorNoData] = useState(false);

  const { getLight, getLightTwoDates, getPower, getPowerTwoDates, getEnergy, getEnergyTwoDates } = useConsumptionsService();
  const currentSelected = useAppSelector((state) => state.electricParameters.currentSelected);
  const voltageSelected = useAppSelector((state) => state.electricParameters.voltageSelected);
  const temperatureSelected = useAppSelector((state) => state.electricParameters.temperatureSelected);
  const apparentPowerSelected = useAppSelector((state) => state.electricParameters.apparentPowerSelected);
  const activePowerSelected = useAppSelector((state) => state.electricParameters.activePowerSelected);
  const frequencySelected = useAppSelector((state) => state.electricParameters.frequencySelected);
  const apparentEnergySelected = useAppSelector((state) => state.electricParameters.apparentEnergySelected);
  const activeEnergySelected = useAppSelector((state) => state.electricParameters.activeEnergySelected);
  const powerGridVoltageSelected = useAppSelector((state) => state.electricParameters.powerGridVoltageSelected);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const { Option } = Select;
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  const manageElectricalInfo = async () => {
    setErrorNoParams(false);
    /* if (startDate === null || endDate === null || (!currentSelected && !voltageSelected && !activePowerSelected && !powerGridVoltageSelected)) {
      setErrorNoParams(true)
    } */

    if (currentSelected) {
      if (startDate.getTime() !== endDate.getTime()){
        const result = await getLightTwoDates(selectedLamp.node_id, startDate, endDate, "current")
        if (!result) {
          setErrorNoData(true)
        }
      } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getLight(selectedLamp.node_id, startDate, "current")
        console.log(result)
        if (!result) {
          setErrorNoData(true)
        }
      }
    }
    
    if (voltageSelected) {
      if (startDate.getTime() !== endDate.getTime()){
        const result = await getLightTwoDates(selectedLamp.node_id, startDate, endDate, "voltage")
        if (!result) {
          setErrorNoData(true)
        }
      } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getLight(selectedLamp.node_id, startDate, "voltage")
        if (!result) {
          setErrorNoData(true)
        }
      }
    }
  
    if (activePowerSelected) {
      if (startDate.getTime() !== endDate.getTime()){
        const result = await getPowerTwoDates(selectedLamp.node_id, startDate, endDate, "activePower")
        if (!result) {
          setErrorNoData(true)
        }
      } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getPower(selectedLamp.node_id, startDate, "activePower")
        if (!result) {
          setErrorNoData(true)
        }
      }
    }
  
    if (powerGridVoltageSelected) {
      if (startDate.getTime() !== endDate.getTime()){
        const result = await getEnergyTwoDates(selectedLamp.node_id, startDate, endDate, "powerGridVoltage")
        if (!result) {
          setErrorNoData(true)
        }
      } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getEnergy(selectedLamp.node_id, startDate, "powerGridVoltage")
        if (!result) {
          setErrorNoData(true)
        }
      }
    }

    setTimeout(() => {
      setErrorNoParams(false);
      setErrorNoData(false);
    }, 2000);
  }

  useEffect(() => {
    if (firstRender) {
      dispatch(resetParametersActionCreator());
      dispatch(resetButtonsActionCreator());
      //dispatch(manageConsumptionSelectedActionCreator());
      //getLightTwoDates(selectedLamp.node_id, startDate, endDate, "current");
      //cuidado que esto está falseado

    }
    //que se parsee el time start y end de forma automática? En vez de hacerlo en el service
    setFirstRender(false)

    /* if (currentSelected) {
      if (endDate === null) {
        getLight(selectedLamp.node_id, startDate, "current")
      } else if (startDate.getTime() !== endDate.getTime()){
        getLightTwoDates(selectedLamp.node_id, startDate, endDate, "current")
      } else if (startDate.getTime() === endDate.getTime()) {
        getLight(selectedLamp.node_id, startDate, "current")
      }
    }
    
    if (voltageSelected) {
      if (endDate === null) {
        getLight(selectedLamp.node_id, startDate, "voltage")
      } else if (startDate.getTime() !== endDate.getTime()){
        getLightTwoDates(selectedLamp.node_id, startDate, endDate, "voltage")
      } else if (startDate.getTime() === endDate.getTime()) {
        getLight(selectedLamp.node_id, startDate, "voltage")
      }
    }

    if (temperatureSelected) {
      if (endDate === null) {
        getLight(selectedLamp.node_id, startDate, "temperature")
      } else if (startDate.getTime() !== endDate.getTime()){
        getLightTwoDates(selectedLamp.node_id, startDate, endDate, "temperature")
      } else if (startDate.getTime() === endDate.getTime()) {
        getLight(selectedLamp.node_id, startDate, "temperature")
      }
    }  
           
    if (apparentPowerSelected) {
      if (endDate === null) {
        getPower(selectedLamp.node_id, startDate, "apparentPower")
      } else if (startDate.getTime() !== endDate.getTime()){
        getPowerTwoDates(selectedLamp.node_id, startDate, endDate, "apparentPower")
      } else if (startDate.getTime() === endDate.getTime()) {
        getPower(selectedLamp.node_id, startDate, "apparentPower")
      }
    }
  
    if (activePowerSelected) {
      if (endDate === null) {
        getPower(selectedLamp.node_id, startDate, "activePower")
      } else if (startDate.getTime() !== endDate.getTime()){
        getPowerTwoDates(selectedLamp.node_id, startDate, endDate, "activePower")
      } else if (startDate.getTime() === endDate.getTime()) {
        getPower(selectedLamp.node_id, startDate, "activePower")
      }
    }
  
    if (frequencySelected) {
      if (endDate === null) {
        getPower(selectedLamp.node_id, startDate, "frequency")
      } else if (startDate.getTime() !== endDate.getTime()){
        getPowerTwoDates(selectedLamp.node_id, startDate, endDate, "frequency")
      } else if (startDate.getTime() === endDate.getTime()) {
        getPower(selectedLamp.node_id, startDate, "frequency")
      }
    }
  
    if (apparentEnergySelected) {
      if (endDate === null) {
        getEnergy(selectedLamp.node_id, startDate, "apparentEnergy")
      } else if (startDate.getTime() !== endDate.getTime()){
        getEnergyTwoDates(selectedLamp.node_id, startDate, endDate, "apparentEnergy")
      } else if (startDate.getTime() === endDate.getTime()) {
        getEnergy(selectedLamp.node_id, startDate, "apparentEnergy")
      }
    }
  
    if (activeEnergySelected) {
      if (endDate === null) {
        getEnergy(selectedLamp.node_id, startDate, "activeEnergy")
      } else if (startDate.getTime() !== endDate.getTime()){
        getEnergyTwoDates(selectedLamp.node_id, startDate, endDate, "activeEnergy")
      } else if (startDate.getTime() === endDate.getTime()) {
        getEnergy(selectedLamp.node_id, startDate, "activeEnergy")
      }
    }
  
    if (powerGridVoltageSelected) {
      if (endDate === null) {
        getEnergy(selectedLamp.node_id, startDate, "powerGridVoltage")
      } else if (startDate.getTime() !== endDate.getTime()){
        getEnergyTwoDates(selectedLamp.node_id, startDate, endDate, "powerGridVoltage")
      } else if (startDate.getTime() === endDate.getTime()) {
        getEnergy(selectedLamp.node_id, startDate, "powerGridVoltage")
      }
    } */
  }, [/* currentSelected, voltageSelected, temperatureSelected, apparentEnergySelected, activeEnergySelected, frequencySelected, apparentPowerSelected, activePowerSelected, powerGridVoltageSelected */]);

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.electricParams)}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div>
      </div>
      <div style={{ display: "flex", gap: "16px", alignContent: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "25%" }}>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update:any) => {
              if (Array.isArray(update)) {
                setDates(update);
              } else {
                setDates([update, update]);
              }
            }}
          />
        </div> */}
        <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "25%" }}>
          <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("startDate")}</Text>
          <DatePicker
            className="datePickerElectrical"
            placeholderText={t("selectDate")}
            selectsRange={false}
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "25%" }}>
          <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("endDate")}</Text>
          <DatePicker
            className="datePickerElectrical"
            placeholderText={t("selectDate")}
            selectsRange={false}
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
          />
        </div>
        <div style={{ position: "relative", width: "25%", marginTop: "21px" }}>         
          <Select
            className="selectElectrical"
            style={{ width: "100%", marginBottom: "0px" }}
            optionFilterProp="children"
            value={currentSelected ? txt.current : voltageSelected ? txt.voltage : activePowerSelected ? txt.activePower : powerGridVoltageSelected ? txt.powerGridVoltage : ""}
            onChange={(value) => {
              switch(value) {
                case "current":
                  dispatch(manageConsumptionSelectedActionCreator());
                  break;
                case "voltage":
                  dispatch(manageVoltageSelectedActionCreator());
                  break;
                case "activePower":
                  dispatch(manageActivePowerSelectedActionCreator());
                  break;
                case "powerGridVoltage":
                  dispatch(manageExternalVoltSelectedActionCreator());
                  break;
                  default:
                    break;
              };
              dispatch(resetParametersActionCreator());
            }}
          >
            <Option value="current">
              {txt.current}
            </Option>
            <Option value="voltage">
              {txt.voltage}
            </Option>
            <Option value="activePower">
              {txt.activePower}
            </Option>
            <Option value="powerGridVoltage">
              {txt.powerGridVoltage}
            </Option>
          </Select>
        </div>
        <NextButton style={{ alignSelf: "end", width: "25%", padding: "8px 8px", fontSize: "12px", marginLeft: "6px",
          boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)",
          opacity: !startDate || !endDate || (!currentSelected && !voltageSelected && !activePowerSelected && !powerGridVoltageSelected) ? 0.5 : 1,
          cursor: !startDate || !endDate || (!currentSelected && !voltageSelected && !activePowerSelected && !powerGridVoltageSelected) ? "not-allowed" : "pointer",
          }}
          onClick={() => {manageElectricalInfo()}}
          disabled={!startDate || !endDate || (!currentSelected && !voltageSelected && !activePowerSelected && !powerGridVoltageSelected)}
        >
          {t("viewData")}
        </NextButton>
      </div>
      {errorNoParams ?
        <Text marginBottom="0px" color="red" style={{ textAlign: "center" }}>{t("errorBlankInput")}</Text>
      :
        errorNoData ?
          <Text marginBottom="0px" color="red" style={{ textAlign: "center" }}>{t("errorNoData")}</Text>
        :
          <></>
      }
      {/* <div
        className="row"
        style={{ textAlign: "center", paddingLeft: "128px", paddingRight: "128px" }}
      >
        <div className="col-12">
          <button
            style={{
              height: "32px",
              padding: "0",
              background: "transparent",
              float: "left",
              border: "none",
            }}
          >
            <img src={arrowBack} height={16} />
          </button>
          <button
            style={{
              padding: "0",
              background: "transparent",
              fontSize: "24px",
              fontWeight: "700",
              color: "#1B2559",
              border: "none",
            }}
          >
            March 6 - March 12
          </button>
          <button
            style={{
              height: "32px",
              padding: "0",
              background: "transparent",
              float: "right",
              border: "none",
            }}
          >
            <img src={arrowForward} height={16} />
          </button>
        </div>
      </div> */}
      {/* <div style={{ display: " flex", alignItems: "center", /* gap: "10px"  gap: "64px" }}> */}
        {/* <div style={{ display: " flex", flexWrap: "wrap", justifyContent:"left" }}>
          <div
            onClick={() => {dispatch(manageConsumptionSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: currentSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.current}</TextSpan>
          </div>
          <div
            onClick={() => {dispatch(manageVoltageSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: voltageSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.voltage}</TextSpan>
          </div> */}
          {/* <div
            onClick={() => {dispatch(manageTemperatureSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: temperatureSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.temperature}</TextSpan>
          </div>
          <div
            onClick={() => {dispatch(manageFrequencySelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: frequencySelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.frequency}</TextSpan>
          </div>
          <div
            onClick={() => {dispatch(manageApparentPowerSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: apparentPowerSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.apparentPower}</TextSpan>
          </div> */}
          {/* <div
            onClick={() => {dispatch(manageActivePowerSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: activePowerSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.activePower}</TextSpan>
          </div> */}
          {/* <div
            onClick={() => {dispatch(manageApparentEnergySelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: apparentEnergySelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.apparentEnergy}</TextSpan>
          </div>
          <div
            onClick={() => {dispatch(manageActiveEnergySelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: activeEnergySelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.activeEnergy}</TextSpan>
          </div> */}
          {/* <div
            onClick={() => {dispatch(manageExternalVoltSelectedActionCreator()); dispatch(resetParametersActionCreator())}}
            style={{display: "flex", alignItems: "center", margin: "8px 4px", padding: "4px 12px", background: "#FFFFFF", borderRadius: "30px", cursor: "pointer",
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))",
            border: powerGridVoltageSelected ? "2px solid black" : "none"
          }}>
            <TextSpan>{txt.powerGridVoltage}</TextSpan>
          </div>
        </div>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update:any) => {
            if (Array.isArray(update)) {
              setDates(update);
            } else {
              setDates([update, update]);
            }
          }}
        />
      </div> */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ElectricalParametersChart />
      </div>
      {/* <div className="row">
        <div className="col-12">
          <div
            className="chart-container"
            style={{ height: "344px", width: "100%" }}
          >
            {" "}
            <ElectricalParametersChart />
          </div>
        </div>
      </div> */}
    </div>
  );
};
