import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SunCalc from 'suncalc';
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { changeStrategyChartActionCreator, changeAstroDimActionCreator, saveOffsetValuesActionCreator, saveIndexActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { changeBackgroundStrategiesActionCreator, turnBackgroundStrategiesFalseActionCreator } from "../../../../redux/slices/graphicsSlice/graphicsSlice";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import dragData from "chartjs-plugin-dragdata";
import { getLocalSunsetAndSunrise } from "../../StrategiesLogic";
import { languages } from "../../../../utils/languages/languages";
import { dimmingRegex, hourRegex, minuteRegex, numericRegex, offsetRegex } from "../../../../utils/regex";
import { ThemeProvider } from "styled-components";
import { SwitchComponent } from "./DimmingStyled";
import { Input } from "../../../../components/Input/InputStyled";
import { InputField, NextButton } from "../StrategiesStepsStyled";
import { styledMainTheme } from "../../../../styles/shared-styles/styleMainTheme";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import clock from "../../../../assets/new_icons/Clock.svg";
import deleteIcon from "../../../../assets/new_icons/Delete.svg";
import editIcon from "../../../../assets/new_icons/Edit.svg";
import plus from "../../../../assets/new_icons/plus.svg";
import sunriseIcon from "../../../../assets/new_icons/Sunrise.svg";
import sunsetIcon from "../../../../assets/new_icons/Sunset.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  dragData,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface DimmingChartProps {
  showDimming?: any;
  setNewChartData?: any;
  newChartData?: any;
  setErrorSunsetOffsetValue?: any,
  errorSunsetOffsetValue?: any,
  setErrorSunriseOffsetValue?: any,
  errorSunriseOffsetValue?: any
}

function DimmingChart({ showDimming, setNewChartData, newChartData, setErrorSunsetOffsetValue, errorSunsetOffsetValue, setErrorSunriseOffsetValue, errorSunriseOffsetValue 
}: DimmingChartProps) {
  const dispatch = useAppDispatch();
  const color = useAppSelector((state) => state.strategies.color);
  const astroDim = useAppSelector((state) => state.strategies.astroDim);
  const sunsetHour = useAppSelector((state) => state.strategies.sunsetHour);
  const sunriseHour = useAppSelector((state) => state.strategies.sunriseHour);
  const offsetSunset = useAppSelector((state) => state.strategies.offsetSunset);
  const offsetSunrise = useAppSelector((state) => state.strategies.offsetSunrise);
  const indexRedux = useAppSelector((state) => state.strategies.index);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const latitude = useAppSelector((state) => state.selectedOrganization.latitude);
  const longitude = useAppSelector((state) => state.selectedOrganization.longitude);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [sunsetOffsetValue, setSunsetOffsetValue] = useState(offsetSunset);
  const [sunriseOffsetValue, setSunriseOffsetValue] = useState(offsetSunrise);
  const [dimmingsList, setDimmingsList]: any = useState();
  const [selectedCard, setSelectedCard]: any = useState();
  const [hourFromInput, setHourFromInput] = useState("");
  const [minuteFromInput, setMinuteFromInput] = useState("");
  const [dimmingFromInput, setDimmingFromInput] = useState("");
  const [hoursList, setHoursList]: any = useState();
  const [hourFromList, setHourFromList]: any = useState();
  const [minuteFromList, setMinuteFromList]: any = useState();
  const [errorInvalidData, setErrorInvalidData] = useState(false);
  const [errorExistingPoint, setErrorExistingPoint] = useState(false);
  const [errorInvalidLimitPoint, setErrorInvalidLimitPoint] = useState(false);
  const [chartData, setChartData] = useState({
    //labels: ["17", "18", "19", "20", "21", "22", "23", "00", "01", "02", "03", "04", "05", "06", "07", "08"],
    labels: newChartData?.labels || ["12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
    datasets: newChartData?.datasets || [
      {
        label: "Standard",
        //data: newStrategy.data ? [newStrategy.data] : [100, , , , , , , , , , , , , , , 0],
        data: [ , , , , , 100, , , , , , , , , , , , , , , 0, , , ,],
        //borderColor: "rgba(246, 120, 120)",
        borderColor: color.value,
        //backgroundColor: "#eb9d9d80",
        //backgroundColor: color.value ? color.value+"80" : "#eb9d9d80",
        backgroundColor: color.value + "80",
        fill: true,
        stepped: true,
        pointRadius: 5,
        pointBackgroundColor: "white",
        padding: 10,
        showLine: true,
        spanGaps: true
      },
    ],
  });

  const theme = {
    primaryColor: styledMainTheme?.light?.smartecGreen,
  };

  const totalPoints = chartData.datasets[0].data.filter((point: any) => point != null && point != undefined).length;
  
  /* console.log("el nuevo", newChartData)
  console.log("el inicial", chartData) */

  const optionsDragable = {
    responsive: true,
    elements: {
      line: {
        tension: 0
      }
    },
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
      dragX: true,
      dragData: {
        round: 0,
        onDragEnd: (value:any, datasetIndex:any) => {
          setChartData(chartData => {
            chartData.datasets[0].data[value] = datasetIndex;

            return {
              ...chartData,
              datasets: chartData.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });

          setNewChartData((newChartData: any) => {
            newChartData.datasets[0].data[value] = datasetIndex;

            return {
              ...newChartData,
              datasets: newChartData.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });

          /* if (newChartData === undefined) {
            setNewChartData({
              labels: chartData.labels,
              datasets: chartData.datasets
            });
          } else {
            setChartData(newChartData => {
              newChartData.datasets[0].data[value] = datasetIndex;

              return {
                ...newChartData,
                datasets: newChartData.datasets.map((dataset:any) => ({
                  ...dataset,            
                }))
              }
            })
          } */
        }
      },
    },
    scales: {
      /* yAxes: {
        display: false,        
      }, */
      y: {
        display: true,
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      /* yAxes: {
        display: false,        
      }, */
      y: {
        display: true,
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: false,
  };

  const handleOffsetInputsChange = (e: any, type: string) => {
    if (offsetRegex.test(e.target.value) && (e.target.value !== "00" && e.target.value !== "-0")) {
      if(e.target.value.includes("-")) {
        if (e.target.value.length <= 3) {
          const numericValue = parseInt(e.target.value, 10);
          if (numericValue >= -60 || e.target.value === "-") {
            if (type === "sunset") {
              setSunsetOffsetValue(e.target.value);
              setErrorSunsetOffsetValue(false);
            } else if (type === "sunrise") {
              setSunriseOffsetValue(e.target.value);
              setErrorSunriseOffsetValue(false);
            }
          } else {
            if (type === "sunset") {
              setErrorSunsetOffsetValue(true);
            } else if (type === "sunrise") {
              setErrorSunriseOffsetValue(true);
            }
          }
        } else {
          if (type === "sunset") {
            setErrorSunsetOffsetValue(true);
          } else if (type === "sunrise") {
            setErrorSunriseOffsetValue(true);
          }
        }
      } else {
        if (e.target.value.length <= 2) {
          if (e.target.value >= 1 && e.target.value <= 60) {
            if (type === "sunset") {
              setSunsetOffsetValue(e.target.value);
              setErrorSunsetOffsetValue(false);
            } else if (type === "sunrise") {
              setSunriseOffsetValue(e.target.value);
              setErrorSunriseOffsetValue(false);
            }
          } else {
            if (type === "sunset") {
              setErrorSunsetOffsetValue(true);
            } else if (type === "sunrise") {
              setErrorSunriseOffsetValue(true);
            }
          }
        } else {
          if (type === "sunset") {
            setErrorSunsetOffsetValue(true);
          } else if (type === "sunrise") {
            setErrorSunriseOffsetValue(true);
          }
        }
      }
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      if (type === "sunset") {
        setErrorSunsetOffsetValue(false);
      } else if (type === "sunrise") {
        setErrorSunriseOffsetValue(false);
      }
    }
  };

  const formatTimeforList = async (index: any) => {
    const [hours, minutes] = hoursList[index].split(':');
    setHourFromList(hours);
    setMinuteFromList(minutes);
  };

  const handleHoursInputsChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2) {
        if (type === "hour" && hourRegex.test(e.target.value)) {
          setHourFromInput(e.target.value);
        } else if (type === "minute" && minuteRegex.test(e.target.value)) {
          setMinuteFromInput(e.target.value);
        } else {
          e.target.value = "";
        }
      } else if (e.target.value.length <= 2) {
        if (type === "hour") {
          setHourFromInput(e.target.value);
        } else if (type === "minute") {
          setMinuteFromInput(e.target.value);
        }
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      if (type === "hour") {
        setHourFromInput("");
      } else if (type === "minute") {
        setMinuteFromInput("");
      }
    }
  };

  const handleDimmingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dimmingRegex.test(e.target.value)) {
      setDimmingFromInput(e.target.value);
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      setDimmingFromInput("")
    }
  };

  const orderLabel = (value: string, labels: any) => {
    const [hours, minutes] = value.split(':');
  
    let valueInMinutes = parseInt(hours) * 60 + parseInt(minutes);

    let hoursListInMinutes = hoursList.map((time: any) => {
      let [hours, minutes] = time.split(':');
      return parseInt(hours) * 60 + parseInt(minutes)
    });

    //revisar estas casuísticas, dice Javi que debe ser más simple
    if (selectedCard === 0) {
      //más adelante hacer que esto funcione aunque solo haya start y end
      /* if (hoursList.length !== 2) {
        if (hoursListInMinutes[1] > hoursListInMinutes[0] && hoursListInMinutes[1] <= 23 * 60 + 59) {
          if (valueInMinutes < hoursListInMinutes[1] /* && valueInMinutes <= 23 * 60 + 59 && !(valueInMinutes >= 0 && valueInMinutes < hoursListInMinutes[2])) {
          } else {
            return false;
          }
        } else if (hoursListInMinutes[1] < hoursListInMinutes[0] && hoursListInMinutes[1] >= 0) {
            if (valueInMinutes < hoursListInMinutes[1] && valueInMinutes >= 0 /* && !(valueInMinutes >= 0 && valueInMinutes < hoursListInMinutes[2])) {
            } else if (valueInMinutes > hoursListInMinutes[1] && (valueInMinutes >= 0 && valueInMinutes < hoursListInMinutes[1])) {
            } else {
              return false;
            }
        }
      } */
    } else if (selectedCard === hoursList.length - 1) {
      /* if (hoursList.length !== 2) {
        if (hoursListInMinutes[hoursListInMinutes.length - 2] > hoursListInMinutes[hoursListInMinutes.length - 1] && hoursListInMinutes[hoursListInMinutes.length - 2] <= 23 * 60 + 59) {
          //falta el camino de una vez esto se ha cumplido, el value y el length-1 son mayores que el -2, por lo que no deja editar porque el -2 ya no es mayor que -1, estan en ambos bandos
          if (valueInMinutes < hoursListInMinutes[hoursListInMinutes.length - 2] && (valueInMinutes > hoursListInMinutes[hoursListInMinutes.length - 2] && valueInMinutes <= 23 * 60 + 59)) {
          } else if (valueInMinutes > hoursListInMinutes[hoursListInMinutes.length - 2] && valueInMinutes <= 23 * 60 + 59) {
          } else {
            return false;
          }
 
        } else if (hoursListInMinutes[hoursListInMinutes.length - 2] < hoursListInMinutes[hoursListInMinutes.length - 1] && hoursListInMinutes[hoursListInMinutes.length - 2] >= 0) {
            if (valueInMinutes > hoursListInMinutes[hoursListInMinutes.length - 2] && valueInMinutes <= 23 * 60 + 59 && !(valueInMinutes <= 23 * 60 + 59 && valueInMinutes > hoursListInMinutes[hoursListInMinutes.length - 3])) {
            } else {
              return false;
            }
        }
      } */
    } else {
      //if ((totalMinutes >= 17 * 60 && totalMinutes <= 23 * 60 + 59) || (totalMinutes >= 0 && totalMinutes < 8 * 60)) {
      if ((valueInMinutes >= hoursListInMinutes[0] && valueInMinutes <= 23 * 60 + 59) || (valueInMinutes >= 0 && valueInMinutes < hoursListInMinutes[hoursListInMinutes.length - 1])) {    
      } else {
        return false
      } 
    }


/* 
    const [hours, minutes] = value.split(':');
let valueInMinutes = parseInt(hours) * 60 + parseInt(minutes);

// Si la hora es menor que 17:00 (1020 minutos), asumimos que es del día siguiente.
if (valueInMinutes < 17 * 60) {
    valueInMinutes += 24 * 60;
}

let hoursListInMinutes = hoursList.map((time: any) => {
    let [hours, minutes] = time.split(':');
    let timeInMinutes = parseInt(hours) * 60 + parseInt(minutes);

    // Si la hora es menor que 17:00, asumimos que es del día siguiente.
    if (timeInMinutes < 17 * 60) {
        timeInMinutes += 24 * 60;
    }

    return timeInMinutes;
}); */






    let numericValue = parseInt(hours) + (minutes ? parseInt(minutes) / 60 : 0);

    if (numericValue < parseInt(labels[0])) {
      numericValue += 24;
    }

    let index = 0;
    while (index < labels.length) {
      const [currentHours, currentMinutes] = labels[index].split(':');
      let currentNumericValue = parseInt(currentHours) + (currentMinutes ? parseInt(currentMinutes) / 60 : 0);

      if (currentNumericValue < parseInt(labels[0])) {
        currentNumericValue += 24;
      }

      if (currentNumericValue > numericValue) {
        break;
      }

      index++;
    }

    return index
  };

  const addData = (hour: string, minute: string) => {
    if (hour.length === 2 && minute.length === 2 && dimmingFromInput.length !== 0) {
      if (totalPoints !== 7) {
        let value = [hour, minute].join(":")
        handleAddOrDeleteData(value, "addData")
      }
    } else {
      setErrorInvalidData(true)
    }
  };

  const deleteData = (index: any) => {
    let value = hoursList[index]
    handleAddOrDeleteData(value, "deleteData")
  };

  const handleAddOrDeleteData = (value: string, action: string) => {
    const [hours, minutes] = value.split(':');
    
    let index: any = orderLabel(value, chartData.labels);
    if (index === false) {
      setErrorInvalidLimitPoint(true);
      return
    }

    let newLabel = chartData.labels;
    let newData = chartData.datasets;

    if (action === "addData") {
      if (minutes === "00") {
        if (chartData.labels.includes(hours) && newData[0].data[index-1] !== undefined) {
          setErrorExistingPoint(true)
        } else if (chartData.labels.includes(hours) && newData[0].data[index-1] === undefined) {
          newData[0].data[index-1] = dimmingFromInput;
        } else if (!chartData.labels.includes(hours)) {
          newData[0].data.splice(index, 0, dimmingFromInput);
          newLabel = newLabel.slice();
          newLabel.splice(index, 0, hours.toString());
        }
      } else if (chartData.labels.includes(value) && newData[0].data[index-1] !== undefined) {  
        setErrorExistingPoint(true)
      } else if (chartData.labels.includes(value) && newData[0].data[index-1] === undefined) {  
        newData[0].data[index-1] = dimmingFromInput;
      } else if (!chartData.labels.includes(value)) {
        newData[0].data.splice(index, 0, dimmingFromInput);
        newLabel = newLabel.slice();
        newLabel.splice(index, 0, value.toString());
      }
    }

    if (action === "deleteData") {
      if (minutes === "00") {
        newData[0].data[index-1] = null;
      } else {
        newData[0].data.splice(index-1, 1);
        let tempNewLabel = newLabel;
        newLabel = tempNewLabel.filter((label:any) => label !== value)
      }
    }

    const tempArray = newData[0].data.map((element:any) => (element === undefined ? "" : element));
    let lastDimmingPoint = tempArray.lastIndexOf(tempArray.filter((element:any) => element !== null)[tempArray.filter((element:any) => element !== null).length-1]);
    newData[0].data[lastDimmingPoint] = 0;

    setChartData({
      labels: newLabel,
      datasets: newData.map((dataset: any) => ({
        ...dataset,
      })),
    });

    setNewChartData({
      labels: newLabel,
      datasets: newData.map((dataset: any) => ({
        ...dataset,
      })),
    });
  };

  const handleEditData = (oldIndex: any) => {
    if (dimmingsList[selectedCard] !== dimmingFromInput && (hourFromList === hourFromInput && minuteFromList === minuteFromInput)) {
      if (hoursList[selectedCard].includes(":00")) {
        hoursList[selectedCard] = hoursList[selectedCard].replace(":00", "");
      }
      const index = chartData.labels.findIndex((label: any) => label === hoursList[selectedCard]);
      let newData = chartData.datasets;
      newData[0].data[index] = dimmingFromInput;

      setChartData({
        labels: chartData.labels,
        datasets: newData.map((dataset:any) => ({
          ...dataset,   
        }))
      });
  
      setNewChartData({
        labels: chartData.labels,
        datasets: newData.map((dataset:any) => ({
          ...dataset,   
        }))
      });
      return;
    }
  
    let oldValue = hoursList[oldIndex];
    const [oldHours, oldMinutes] = oldValue.split(':');

    let oldPosition: any;
    if (oldMinutes === "00") {
      chartData.labels.map((dataset: any, index: any) => {
        if (oldHours === dataset) {
          oldPosition = index;
        }
      });
    } else {
      chartData.labels.map((dataset: any, index: any) => {
        if (oldValue === dataset) {
          oldPosition = index;
        }
      });
    }

    let newLabel = chartData.labels;
    let newData = chartData.datasets;
    let newValue: any;

    if (hourFromInput.length === 2 && minuteFromInput.length === 2 && dimmingFromInput.length !== 0) {
      newValue = [hourFromInput, minuteFromInput].join(":")

      let index: any = orderLabel(newValue, newLabel);
      if (index === false) {
        setErrorInvalidLimitPoint(true);
        return
      }

      let existingIndex: any;
      newLabel.map((dataset: any, index: any) => {
        if ((hourFromInput === dataset && minuteFromInput === "00") || newValue === dataset) {
          existingIndex = index;
        }
      });
      if (newData[0].data[existingIndex] !== undefined && newData[0].data[existingIndex] === dimmingFromInput) {
        setErrorExistingPoint(true);
        return;
      }

      if (oldMinutes === "00") {
        newData[0].data[oldPosition] = undefined;
      } else {
        newData[0].data.splice(oldPosition, 1);
        let tempNewLabel = newLabel;
        newLabel = tempNewLabel.filter((label:any) => label !== oldValue)
      }

      if (minuteFromInput === "00") {
        if (newLabel.includes(hourFromInput) && newData[0].data[index-1] === undefined) {
          let indexFromLabel = newLabel.indexOf(hourFromInput);
          newData[0].data[indexFromLabel] = dimmingFromInput;
          //newData[0].data[index-1] = dimmingFromInput;
        } else if (!newLabel.includes(hourFromInput)) {
          newData[0].data.splice(index, 0, dimmingFromInput);
          newLabel.splice(index, 0, hourFromInput.toString());
        }
      } /* else if (newLabel.includes(newValue) && newData[0].data[index-1] === undefined) {  
        newData[0].data[index-1] = dimmingFromInput; // este creo que no sirve
      }  */ else if (!newLabel.includes(newValue)) {
        newData[0].data.splice(index, 0, dimmingFromInput);
        newLabel = newLabel.slice();
        newLabel.splice(index, 0, newValue.toString());
      }
    } else {
      setErrorInvalidData(true)
    }

    const tempArray = newData[0].data.map((element:any) => (element === undefined ? "" : element));
    let lastDimmingPoint = tempArray.lastIndexOf(tempArray.filter((element:any) => element !== null)[tempArray.filter((element:any) => element !== null).length-1]);
    newData[0].data[lastDimmingPoint] = 0;

    setChartData({
      labels: newLabel,
      datasets: newData.map((dataset:any) => ({
        ...dataset,   
      }))
    });

    setNewChartData({
      labels: newLabel,
      datasets: newData.map((dataset:any) => ({
        ...dataset,   
      }))
    });
  };

  const resetStates = () => {
    setHourFromInput("");
    setMinuteFromInput("");
    setDimmingFromInput("");
    setSelectedCard(false);
    dispatch(turnBackgroundStrategiesFalseActionCreator());
  };

  useEffect(() => {
    if (newChartData === undefined) {
      setNewChartData({
        labels: chartData.labels,
        datasets: chartData.datasets,
      });
    }

    const newArray = chartData.datasets[0].data.map((element:any) => (element === undefined ? "" : element));
    let lastDimmingPoint = newArray.lastIndexOf(newArray.filter((element:any) => element !== null)[newArray.filter((element:any) => element !== null).length-1]);
    let firstDimmingPoint = newArray.findIndex((element:any) => element !== null && element !== undefined);
    newArray[lastDimmingPoint] = 0;
  
    dispatch(changeStrategyChartActionCreator({labels: chartData.labels, data: newArray}));

    if (astroDim) {
      let firstDay = SunCalc.getTimes(new Date(), latitude, longitude);
      let day = new Date();
      day.setDate(day.getDate() + 1);
      let secondDay = SunCalc.getTimes(day, latitude, longitude);
      getLocalSunsetAndSunrise(firstDay.sunset, secondDay.sunrise);
      dispatch(saveOffsetValuesActionCreator({ offsetSunset: sunsetOffsetValue === "0" ? "" : sunsetOffsetValue, offsetSunrise: sunriseOffsetValue === "0" ? "" : sunriseOffsetValue }))

      /* let tempLabel = [...chartData.labels];

      tempLabel[firstDimmingPoint] = "SN";
      tempLabel[lastDimmingPoint] = sunriseHour;

      orderLabel(tempLabel[firstDimmingPoint], chartData.labels)

      setChartData({
        labels: tempLabel,
        datasets: chartData.datasets.map((dataset:any) => ({
          ...dataset,   
        }))
      });
  
      setNewChartData({
        labels: tempLabel,
        datasets: chartData.datasets.map((dataset:any) => ({
          ...dataset,   
        }))
      }); */
    } else {
      setSunsetOffsetValue(""); setSunriseOffsetValue(""); //quizás esta línea no cal?
      dispatch(saveOffsetValuesActionCreator({ offsetSunset: "", offsetSunrise: "" }))
    }

    let dimmingsArr : any = [];
    let hoursArr : any = [];
    chartData.datasets[0].data.map((point: any, index: number) => {
      if (point != null && point != undefined) {
        dimmingsArr.push(point);
        hoursArr.push(chartData.labels[index])
      }
    })
    dimmingsArr[dimmingsArr.length - 1] = 0;

    hoursArr.forEach((hour: any, index: any) => {
      if (!hour.includes(':')) {
        hoursArr[index] += ':00';
      }
    })

    setDimmingsList(dimmingsArr);
    setHoursList(hoursArr);

    if (selectedCard === 0 || selectedCard) {
      setHourFromInput(hourFromList);
      setMinuteFromInput(minuteFromList);
      setDimmingFromInput(dimmingsList[selectedCard]);
    }

  }, [chartData, newChartData, astroDim, sunsetOffsetValue, sunriseOffsetValue, selectedCard]);

  const { t } = useTranslation();

  return (
    <div style={{ paddingRight: "32px" }}>
      {/* este bloque podría llevarlo al archivo Dimming, pero no vale la pena porque cuando le aplique lógica visual hacia el gráfico, me irá mejor si está aquí */}
      {showDimming ?
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "74px" }}>
            {/* <Text fontWeight="600" marginBottom="0px" style={{fontSize: "18px"}}>{txt.dimmingConfigurationTitle}</Text> */}
            <div>
              <div style={{ display: "flex", alignItems: "center", marginBottom: "0px", gap: "18px" }}>
                <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{txt.activateAstroDIM}</Text>
                {astroDim ? 
                  <ThemeProvider theme={theme}>
                    <SwitchComponent
                      checked
                      checkedChildren="On" 
                      unCheckedChildren="Off"
                      onClick={() => {dispatch(changeAstroDimActionCreator()); setErrorSunsetOffsetValue(false); setErrorSunriseOffsetValue(false)}}
                      />
                  </ThemeProvider>
                :
                  <SwitchComponent checkedChildren="On" unCheckedChildren="Off" checked={false} onClick={() => {dispatch(changeAstroDimActionCreator())}} />
                }
              </div> 
              {astroDim ?
                <>
                  <Text textType="caption" fontWeight="400" marginBottom="-2px" style={{ fontSize: "12px" }}>{txt.activatedAstroDIMTitle}</Text>
                  <Text textType="caption" fontWeight="400" marginBottom="0px" style={{ fontSize: "12px" }}>{txt.activatedAstroDIMSubtitle}<strong>{txt.activatedAstroDIMSubtitleBold}</strong>.</Text>
                </>
              :
                <></>
              }
            </div>
            {astroDim ? 
              <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                  <div style={{ display: "flex", gap: "8px", alignItems: "end", width: "130px" }}>
                    <img src={sunsetIcon} width={24} style={{marginBottom: "4px"}} />
                    <div>
                      <Text textType="caption" marginBottom="2px" color="#A3AED0" style={{ fontSize: "12px" }}>{txt.sunsetOffset.toUpperCase()}</Text>
                      <InputField style={{ height: "30px", color: errorSunsetOffsetValue ? "red" : "#A3AED0" }} defaultValue={sunsetOffsetValue} onChange={(e) => {handleOffsetInputsChange(e, "sunset")}} placeholder={txt.max60} />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "end", width: "130px" }}>
                    <img src={sunriseIcon} width={24} style={{marginBottom: "4px"}} />
                    <div>
                      <Text textType="caption" marginBottom="2px" color="#A3AED0" style={{ fontSize: "12px" }}>{txt.sunriseOffset.toUpperCase()}</Text>
                      <InputField style={{ height: "30px", color: errorSunriseOffsetValue ? "red" : "#A3AED0" }} defaultValue={sunriseOffsetValue} onChange={(e) => {handleOffsetInputsChange(e, "sunrise")}} placeholder={txt.max60} />
                    </div>
                  </div>
                </div>
                {errorSunsetOffsetValue ?
                    <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px", alignSelf: "flex-end" }}>{txt.errorSunsetOffsetValue}</Text>
                  :
                    <></>
                }
                {errorSunriseOffsetValue ?
                    <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px", alignSelf: "flex-end" }}>{txt.errorSunriseOffsetValue}</Text>
                  :
                    <></>
                }
              </div>
            :
              <></>
            }
          </div>
        :
          <></>
      }
      {showDimming &&
        <div style={{ marginTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text textType="caption" fontWeight="500" marginBottom="0px" color="#A3AED0" style={{ alignSelf: "end", fontSize: "14px", marginRight: "11px" }}>{totalPoints}/7 {txt.times}</Text>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{txt.dimmingConfigurationSideMenu}</Text>
                <Text textType="caption" fontWeight="400" marginBottom="0px" style={{ fontSize: "12px" }}>{txt.dimmingConfigurationBoxes}</Text>
              </div>
              <button
                style={{ display: "flex", alignItems: "center", backgroundColor: totalPoints === 7 ? "#e3e1e1" : "#ffffff", color: "#475569", fontSize: "12px", paddingRight: "14px",
                  paddingLeft: "5px", border: "1px solid #475569", borderRadius: "64px", cursor: totalPoints === 7 ? "not-allowed" : "pointer"
                }}
                onClick={() => {
                  if (totalPoints !== 7) {
                    dispatch(changeBackgroundStrategiesActionCreator()); setErrorInvalidData(false); setErrorExistingPoint(false); setErrorInvalidLimitPoint(false)
                  }
                }}
              >
                <img src={plus} height={26} style={{ marginRight: "2px" }} />
                <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "12px" }}>{txt.addTime}</Text>
              </button>
            </div>
          </div>
        </div>
      }
      <div style={{ marginTop: showDimming ? "20px" : "30px", marginBottom: "25px" }}>
        {!showDimming && <Text textType="caption" fontWeight="500" marginBottom="14px" style={{ fontSize: "14px" }}>{txt.strategyConfiguration}</Text>}
        <div style={{ display: "flex", gap: "20px" /* paddingLeft: "50px", paddingRight: "50px", gap: "51px" */ /* gap: "69px" */ }}>
          {dimmingsList?.map((point: any, index: number) => {
            return (
              <div key={index} style={{ display: "flex", flexDirection: "column", width: "110px", gap: "4px", marginLeft: index === dimmingsList.length - 1 ? "auto" : "0" }}>
                <div style= {{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {index === 0 ?
                    <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.start.toUpperCase()}</Text>
                  : index === dimmingsList.length - 1 ?
                      <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.end.toUpperCase()}</Text>
                    :
                      index === 1 ?
                        <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.time2.toUpperCase()}</Text>
                      :
                        index === 2 ?
                          <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.time3.toUpperCase()}</Text>
                        :
                          index === 3 ?
                            <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.time4.toUpperCase()}</Text>
                          :
                            index === 4 ?
                              <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.time5.toUpperCase()}</Text>
                            :
                              index === 5 ?
                                <Text fontWeight="500" color={showDimming ? "#475569" : "#A3AED0"} style={{marginBottom: "0px", fontSize: "14px"}}>{txt.time6.toUpperCase()}</Text>
                              :
                                <></>
                  }
                  {showDimming &&
                    <>
                      { index === dimmingsList.length - 1 ? 
                          astroDim ?
                            <></>
                          :
                            <img src={editIcon} height={8} style={{ marginRight: "2px", opacity: 0.5, cursor: "pointer" }} onClick={() => {dispatch(saveIndexActionCreator({ index: index }));
                              setErrorInvalidData(false); setErrorExistingPoint(false); setErrorInvalidLimitPoint(false); dispatch(changeBackgroundStrategiesActionCreator());
                              setSelectedCard(index); formatTimeforList(index)}}
                            />
                        :
                          <img src={editIcon} height={8} style={{ marginRight: "2px", opacity: 0.5, cursor: "pointer" }} onClick={() => {dispatch(saveIndexActionCreator({ index: index }));
                            setErrorInvalidData(false); setErrorExistingPoint(false); setErrorInvalidLimitPoint(false); dispatch(changeBackgroundStrategiesActionCreator());
                            setSelectedCard(index); formatTimeforList(index)}}
                          />
                      }
                      { index !== 0 && index !== dimmingsList.length - 1 ? 
                          <img src={deleteIcon} height={8} style={{ marginLeft: "-26px", opacity: 0.5, cursor: "pointer" }} onClick={async() => {deleteData(index)}} />
                        :
                          <></>
                      }
                    </>
                  }
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", marginBottom: "1px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  {astroDim ?
                    index === 0 ?
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{txt.sunset.toUpperCase()}</Text>
                    : index === hoursList.length - 1 ?
                        <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{txt.sunrise.toUpperCase()}</Text>    
                      :
                        <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{hoursList[index]}</Text>
                  :
                    <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{hoursList[index]}</Text>        
                  }    
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  {index === dimmingsList.length - 1 && astroDim ?
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{txt.OFF}</Text>
                    :
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: "#A3AED0" }}>{dimmingsList[index]}%</Text>
                  }
                </div>
              </div>
            )
          })}
          {background ? 
            <div style={{ background: "#FFFFFF", zIndex: 6, position: "absolute", left: 659, top: 295, width: "220px", height: selectedCard !== dimmingsList.length - 1 ? 
              selectedCard === 0 && astroDim ? "201px" : "290px" : "201px", borderRadius: "0.5rem", padding: "20px", paddingTop: "16px", paddingBottom: "16px" }}
            >
              {selectedCard === 0 || selectedCard ?
                selectedCard === 0 ?
                  <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.start.toUpperCase()}</Text>
                : selectedCard === dimmingsList.length - 1 ?
                    <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.end.toUpperCase()}</Text>
                  :
                    selectedCard === 1 ?
                      <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.time2.toUpperCase()}</Text>
                    :
                      selectedCard === 2 ?
                        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.time3.toUpperCase()}</Text>
                      :
                        selectedCard === 3 ?
                          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.time4.toUpperCase()}</Text>
                        :
                          selectedCard === 4 ?
                            <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.time5.toUpperCase()}</Text>
                          :
                            selectedCard === 5 ?
                              <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.modify.toUpperCase()} {txt.time6.toUpperCase()}</Text>
                            :
                              <></>
                
              :
                <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.addTime.toUpperCase()}</Text>
              }
              {astroDim && selectedCard === 0 ?
                <></>
              :
                <div style={{ display: "flex", marginTop: "20px", marginBottom: "16px", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                  <div>
                    <Input
                      style={{border: "none", borderRadius: 0, width: "76px", height: "60px", background: "rgba(163, 174, 208, 0.2)", textAlign: "center", fontSize: "30px",
                        color: "#1B2559", fontWeight: "500"
                      }}
                      defaultValue={selectedCard === 0 || selectedCard ? hourFromList : ""}
                      onChange={(e) => {handleHoursInputsChange(e, "hour")}}
                    />
                    <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.hour}</Text>
                  </div>
                  <div style={{fontWeight: 700, fontSize: "30px", marginBottom: "20px"}}>:</div>
                  <div>
                    <Input
                      style={{border: "none", borderRadius: 0, width: "76px", height: "60px", /* background: "rgba(0, 0, 0, 0.1)", */ background: "rgba(163, 174, 208, 0.2)",
                        textAlign: "center", fontSize: "30px", color: "#1B2559", fontWeight: "500"
                      }}
                      defaultValue={selectedCard === 0 || selectedCard ? minuteFromList : ""}
                      onChange={(e) => {handleHoursInputsChange(e, "minute")}}
                    />
                    <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.minute}</Text>
                  </div>
                </div>
              }
              {selectedCard !== dimmingsList.length - 1 ?
                <>
                  <Input
                    style={{border: "none", borderRadius: 0, width: "180px", height: "60px", background: "rgba(163, 174, 208, 0.2)", textAlign: "center", fontSize: "30px",
                      color: "#1B2559", fontWeight: "500", marginTop: selectedCard === 0 && astroDim ? "20px" : "0px"
                    }}
                    defaultValue={dimmingsList[selectedCard]}
                    onChange={(e) => {handleDimmingInputChange(e)}}
                  />
                  <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{txt.dimming} %</Text>
                </>
              :
                <></>
              }  
              <div style={{ display: "flex", marginTop: "20px", alignItems: "center", justifyContent: "space-between" }}>
                <img src={clock} height={20} style={{ opacity: 0.7, cursor: "pointer" }} />
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Text marginBottom="0px" fontWeight="500" style={{ color: "rgba(163, 174, 208)", cursor: "pointer", fontSize: "16px" }} onClick={() => {resetStates()}}>{txt.cancel.toUpperCase()}</Text>
                  <NextButton style={{ padding: "3px 16px", height: "30px", cursor: "pointer", boxShadow: "none" }} onClick={() => {
                    selectedCard === 0 || selectedCard ?
                      handleEditData(indexRedux)
                    :
                      addData(hourFromInput, minuteFromInput)
                   
                    resetStates()}}
                  >
                    {txt.ok}
                  </NextButton>
                </div>
              </div>
            </div>
          :
            <></>
        }
        </div>
      </div>
      <div style={{ display: "flex", flexDirection:"column", marginTop: "0px", alignItems: "center", justifyContent: "center"}}>
        {
          totalPoints === 7 && showDimming ?
            <Text textType="caption" fontWeight="500" marginBottom="2px" style={{ fontSize: "14px" }}>{txt.errorStrategyLimit}</Text>
          :
            <></>
        }
        {
          errorInvalidData ?
            <Text textType="caption" fontWeight="500" marginBottom="2px" style={{ fontSize: "14px" }}>{txt.errorBlankInput}</Text>
          :
            <></>
        }
        {
          errorExistingPoint ?
            <Text textType="caption" fontWeight="500" marginBottom="2px" style={{ fontSize: "14px" }}>{txt.errorExistingPoint}</Text>
          :
            <></>
        }
        {
          errorInvalidLimitPoint ?
            <Text textType="caption" fontWeight="500" marginBottom="2px" style={{ fontSize: "14px" }}>{txt.errorInvalidLimitPoint}</Text>
          :
            <></>
        }
      </div>
      <div style={{ overflow: "auto" }}>
        <Line height={80} options={showDimming ? optionsDragable : options} data={chartData} />
      </div>
    </div>
  )
}

export default DimmingChart;
