import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import favourite from "../../../assets/new_icons/Favourite.svg";
import favouriteSelected from "../../../assets/new_icons/Favourite filled.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";

interface GroupsCardProps {
  selectedLampId: string;
}

export const GroupsCard = ({ selectedLampId }: GroupsCardProps) => {
  const nodes = useAppSelector((state) => state.devices.nodes);
  const groupsList = useAppSelector((state) => state.groups.groups);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
        gap: "30px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <div>
          <Text color="#A3AED0">{t("Groups")}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div style={{ flexGrow: 1, display: "flex", justifyContent: "center", alignContent: "center", marginTop: "-80px" }}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", marginBottom: "-3px" }}>
          {nodes && groupsList.length > 0 ?
            nodes.map((node:any) => {
              if (node.node_id === selectedLampId) {
                const filteredGroups = groupsList.filter((item:any) => node.groups.includes(item.groupId.toString()));
                if (filteredGroups.length > 0) {
                  const itemsToShow = filteredGroups.slice(0, 14); // Obtén los primeros 8 elementos
                  const hasMoreItems = filteredGroups.length > 14; // Comprueba si hay más de 8 elementos
                  return (
                    <>
                      {itemsToShow.map((item:any) =>
                        <div
                          style={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            margin: "8px 4px",
                            padding: "4px 12px",
                            borderRadius: "2px",
                            background: "#E8F3FC",
                            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                          }}
                        >
                          {/* {item.star === "Y" ?
                            <img src={favouriteSelected} style={{ height: "12px" }} />
                          :
                            <img src={favourite} style={{ height: "12px" }} />
                          } */}
                          <TextSpan>{item.groupName}</TextSpan>
                        </div>
                      )}
                      {hasMoreItems && 
                        <div
                          title={filteredGroups.slice(14).map((item:any) => item.groupName).join(', ')}
                          style={{
                            display: "flex",
                            gap: "6px",
                            alignItems: "center",
                            margin: "8px 4px",
                            padding: "4px 12px",
                            borderRadius: "2px",
                            background: "#E8F3FC",
                            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                            filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                          }}
                        >
                          <TextSpan>...</TextSpan>
                        </div>
                      }
                    </>
                  );
                } else {
                  return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px"}}>
                      <Text>{t("noGroupAssigned")}</Text>
                    </div>
                  )
                }
              }
            })
          :
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px"}}>
              <Text>{t("noGroupAssigned")}</Text>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
