import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardSimple } from "../../LuminairePageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import energyGreen from "../../../../assets/new_icons/Energy-green.svg";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";
import solarPannel from "../../../../assets/new_icons/Solar-pannel.svg";

interface PannelProps {
  selectedLamp: any
}

export const Pannel = ({ selectedLamp }: PannelProps) => {
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [spPower, setSpPower] = useState(selectedLamp.sp_power);
  const { t } = useTranslation();

  useEffect(() => {
    setSpPower(selectedLamp.sp_power);
  }, [selectedLamp.sp_power]);
  
  return (
    <CardSimple style={{ /* maxHeight: "87%" */ }}>
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <div>
          <Text color="#A3AED0">{t("pannel")}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      {/* <img src={solarPannel} style={{ opacity: 0.6, width: "130px" }} /> */}
      <img src={solarPannel} style={{ opacity: 0.6, width: "160px" }} />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-5px", marginLeft: "60px", gap: "3px" }}>
        <img src={energyGreen} width={14} />
        <Text marginBottom="0">{!spPower && spPower !== 0 ? '\"\"' : spPower % 1 !== 0 ? Math.round(spPower).toFixed(2) : spPower} {t("w/h")}</Text>
      </div>
      {/* (Math.round(selectedLamp.sp_power * 100) / 100).toFixed(2) */}
    </CardSimple>
  );
};
