import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import SavingsChart from "./SavingsChart";
import { languages } from "../../../utils/languages/languages";
import { NextButton } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import arrowDown from "../../../assets/new_icons/Arrow down.svg";
import arrowForward from "../../../assets/new_icons/Arrow right.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";

interface SavingsCardProps {
  selectedLamp?: any
}

export const SavingsCard = ({ selectedLamp }: SavingsCardProps) => {
  const dispatch = useAppDispatch();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [dates, setDates] = useState([null, null]);
  const [startDate, endDate]:any = dates;
  const [startDatee, setStartDatee]:any = useState();
  const [endDatee, setEndDatee]:any = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [iconOpacity, setIconOpacity] = useState(0.5);

  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t("Savings")}</Text>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center", textAlign: "right", marginTop: "-2px", cursor: "pointer" }}>
          <Text marginBottom="0px">
            {t("monthly")}
          </Text>
          <img src={arrowDown} height={16} />
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div style={{ height: "206px" }}>
          <SavingsChart />
        </div>
        <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "start", justifyItems: "start", height: "206px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: "31px" }}>
            <Text marginBottom="19px">{t("energy")}</Text>
            <Text marginBottom="19px">{t("money")}</Text>
            <Text>{t("co2")}</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
            <Text textType="caption">{t("consumed")}</Text>
            <Text fontWeight="600" style={{ fontSize: "16px" }}>0 kWh</Text>
            <Text fontWeight="600" style={{ fontSize: "16px" }}>0 EUR</Text>
            <Text fontWeight="600" style={{ fontSize: "16px" }}>0 t</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
            <Text textType="caption">{t("saved")}</Text>
            <Text fontWeight="600" color="#56C568" style={{ fontSize: "16px" }}>0 kWh</Text>
            <Text fontWeight="600" color="#56C568" style={{ fontSize: "16px" }}>0 EUR</Text>
            <Text fontWeight="600" color="#56C568" style={{ fontSize: "16px" }}>0 t</Text>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12">
          <div
            className="chart-container"
            style={{ height: "344px", width: "100%" }}
          >
            {" "}
            <ElectricalParametersChart />
          </div>
        </div>
      </div> */}
    </div>
  );
};
