import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { turnBackgroundStrategiesFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { Modal } from "react-bootstrap";
import { Details } from "../StrategiesSteps/Step1Details/Details";
import { Dimming } from "../StrategiesSteps/Step2Dimming/Dimming";
import { Review } from "../StrategiesSteps/Step3Review/Review";
import { languages } from "../../../utils/languages/languages";
import { LayoutGreyShadow } from "./NewStrategyModalStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import back from "../../../assets/new_icons/back.svg";
import check from "../../../assets/new_icons/icon-selected.svg";
import checkSelected from "../../../assets/new_icons/check-circle.svg";
import smartecBackground from "../../../assets/new_icons/trama_forms_opacity.png";

interface NewStrategyModalProps {
  showModal: boolean;
  setShowModal: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const NewStrategyModal = ({ showModal, setShowModal }: NewStrategyModalProps) => {
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false)
  const [showDetails, setShowDetails] = useState(true);
  const [showDimming, setShowDimming] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newChartData, setNewChartData] = useState();
  const [errorMessage, setErrorMessage] = useState(false);

  const name = useAppSelector((state) => state.strategies.name);
  const selectedOption = useAppSelector((state) => state.strategies.color?.value);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const goToDetails = async () => {
    setShowDetails(true);
    setShowDimming(false);
    setShowReview(false)
  }

  const goToDimming = async () => {
    if (name === "" || selectedOption === undefined) {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowDimming(true);
      setShowReview(false);
    }
  };

  const goToReview = async () => {
    if (name === "" || selectedOption === "") {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowDimming(false);
      setShowReview(true);
    }
  };
  
  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center",  top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{txt.closeStrategyPopupTitle}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(turnBackgroundStrategiesFalseActionCreator()); setShowModal(!showModal)}}>{txt.accept}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{txt.cancel}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        justifyContent: "space-between"}}
      >
        <div style={{ borderRight: "1px solid rgba(71, 85, 105, 0.1)", background: "rgba(236, 236, 236, 0.92)", borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }} >
          <div style={{ width: "280px", height: "100%", background: `url(${smartecBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "54px", paddingLeft: "40px" }}>
              <Text fontWeight="700" marginBottom="5px" color="#475569" style={{fontSize: "22px" }}>{txt.newStrategy}</Text>
              <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "60px"}}>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDetails()}}>
                  <img width={16} src={showDimming || showReview ? checkSelected : check} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color="#1B2559" fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("generalDetails")}</Text>
                    <Text color="#475569" fontWeight="500" style={{fontSize: "10px"}}>{t("generalDetailsSideMenuStrategies")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDimming()}}>
                  <img width={16} src={showReview ? checkSelected : check} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color="#1B2559" fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{txt.dimming}</Text>
                    <Text color="#475569" fontWeight="500" style={{fontSize: "10px"}}>{txt.dimmingConfigurationSideMenu}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToReview()}}>
                  <img width={16} src={check} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color="#1B2559" fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{txt.review}</Text>
                    <Text color="#475569" fontWeight="500" style={{fontSize: "10px"}}>{txt.reviewStrategySideMenu}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem" }}
        >
          {background ? (
            <LayoutGreyShadow />
          ) : (
            <></>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {
              showDimming ?
                <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                  onClick={() => {setShowDetails(true); setShowDimming(false); setShowReview(false)}}
                >
                  <img src={back} height={12} alt="" style={{ marginRight: "8px" }} />
                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{txt.backToGeneralDetails}</Text>
                </div>
              : showReview ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    onClick={() => {setShowDetails(false); setShowDimming(true); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ marginRight: "8px" }} />
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{txt.backToDimmingConfiguration}</Text>
                  </div>
                :
                  <div></div>
            }
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "white",
                zIndex: 2
              }}
            />
          </div>
          {showDetails && 
            <Details showDimming={showDimming} goToDimming={goToDimming} errorMessage={errorMessage} setErrorMessage={setErrorMessage}
          />}
          {showDimming && 
            <Dimming setShowDetails={setShowDetails} setShowDimming={setShowDimming} showDimming={showDimming} setShowReview={setShowReview} showReview={showReview} 
            setNewChartData={setNewChartData} newChartData={newChartData}
          />}
          {showReview && 
            <Review onHide={setShowModal} setShowDetails={setShowDetails} setShowDimming={setShowDimming} showDimming={showDimming} setShowReview={setShowReview} 
            setNewChartData={setNewChartData} newChartData={newChartData}
          />}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
