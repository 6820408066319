import { getGroupsActionCreator } from "../../slices/groupsSlice/groupsSlice";
import { loadDevicesActionCreator } from "../../slices/devices/devicesSlice";

export interface GroupInfo {
  groupId: number;
  groupName: string;
  star: string;
  nodes: any[];
}

export const getGroupsAction =
  (groups: GroupInfo | any) => async (dispatch: any) => {
    dispatch(getGroupsActionCreator(groups));
  };

export const postGroupAction = (tempDevices: any) => async (dispatch: any) => {
  dispatch(loadDevicesActionCreator(tempDevices));
};
