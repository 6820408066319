import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { store } from "../../redux/store";
import { getUsersAction } from "../../redux/actions/usersActions/usersActions";
import { useUsersService } from "../../services/usersService";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { NewUserModal } from "./NewUserModal/NewUserModal";
import { UsersDetailsCard } from "./UsersDetailsCard/UsersDetailsCard";
import { UsersStructure } from "./UsersList/UsersStructure";
import { DropdownUsersStyled, UsersContainer } from "./UsersStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { TextSpan } from "../../styles/shared-styles/text-sizes";
import plusIcon from "../../assets/plus.svg";

interface UsersProps {}

export const Users = () => {
  const [showOrgsList, setShowOrgsList] = useState<boolean>(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const { t } = useTranslation();

  const { getUsers } = useUsersService();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchManageUserData = async () => {
      try {
        const token = Cookies.get("token") || "";
        setLoading(true);
        let response = await getUsers(token, setLoading); // Make the API call to fetch users
        if (response) {
          store.dispatch(getUsersAction(response));
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };

    fetchManageUserData(); // Call the fetchManageUserData function when the component mounts
  }, []);
  {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-100px", marginLeft: "-65px" }}>
    <Loader />
  </div> */}

  return (
    <UsersContainer>
      {loading ?
        <LoaderModal showLoader={loading} />
      : 
        <>
          {showNewUserModal && (
            <NewUserModal
              showModal={showNewUserModal}
              setShowModal={setShowNewUserModal}
            />
          )}
          <div className="row" style={{ marginBottom: "44px", justifyContent: 'flex-end' }}>
            {/* <div className="col-2">
              <DropdownUsersStyled
                style={{ paddingBottom: showOrgsList ? "20px" : "0", zIndex: 6 }}
              >
                {" "}
                <div onClick={() => setShowOrgsList(!showOrgsList)} className="filter__title-container" style={{ zIndex: 6 }}>
                  <span className="title-container__title">{t("All")}</span>
                  <span className="material-symbols-rounded title-container__icon">
                    {showOrgsList ? "expand_less" : "expand_more"}
                  </span>
                </div>
                {showOrgsList && (
                  <>
                    <ul className="list">
                      <div className="option-container">
                        <TextSpan>Option 1</TextSpan>
                      </div>
                    </ul>
                  </>
                )}
              </DropdownUsersStyled>
            </div> */}
            <div className="col-10" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Button buttonType="primary" onClick={() => {setShowNewUserModal(true)}}>
                <span>
                  <img src={plusIcon} style={{ height: "16px", marginRight: "8px" }} />
                </span>
                {t("New User")}
              </Button>
            </div>
          </div>
          <div style={{ display: "flex", gap: "25px" }}>
            <div style={{ width: "350px" }}>
              <UsersStructure />
            </div>
            <div style={{ width: "1425px" }}>
              <UsersDetailsCard />
            </div>
          </div>
        </>
      }
    </UsersContainer>
  );
};
