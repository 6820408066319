import { Icon } from "leaflet";
import alarm from "../assets/new_icons/Alarm.svg";
import disconnected from "../assets/new_icons/Node disconnected.svg";
import OFF from "../assets/new_icons/OFF.svg";
import ON from "../assets/new_icons/ON.svg";
import selectedIcon from "../assets/new_icons/icon-selected.svg";

export type Status = "on" | "off" | "error" | "disconnected";

export const getIcon = (status: Status, selected: boolean) => {
  const shadowUrl = selected ? selectedIcon : "";
  const shadowSize = [40, 40];

  // esto es una especie de switch, se itera y entra solo en la opción de status que se manda en la línea 43. por lo que aquí debería gestionar el relleno según el id del svg
  // Nota+: ahora mismo el bulb off es como el bulb on pero rellenadod e gris. Poner un icono que no tenga rallitas como si estuviese encendido
  const ICONS = {
    on: new Icon({
      iconUrl: ON,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    off: new Icon({
      iconUrl: OFF,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    error: new Icon({
      iconUrl: alarm,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    disconnected: new Icon({
      iconUrl: disconnected,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
  };

  // aquí se indica el status del nodo
  return ICONS[status];
};
