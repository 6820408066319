import styled from "styled-components";

export const UsersContainer = styled.div`
  margin-top: 50px;
`;

export const DropdownUsersStyled = styled.section`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.contentFontColor};
  align-items: left;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px #00000026;
  padding: 0px 16px 8px 16px;
  z-index: 1;
  cursor: default;

  .filter {
    &__title-container {
      padding: 8px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: inherit;
    }
  }

  .title-container {
    &__title {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #475569;
    }

    &__icon {
      color: inherit;
      font-weight: 400;
    }
  }

  ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      ),
      #ffffff;
  }

  ul::-webkit-scrollbar {
    position: absolute;
    right: 0;
    width: 3px;
    height: 50%;
  }

  ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #a3aed034;
    border-radius: 5px;
  }

  ul::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.377);
    border-radius: 5px;
  }

  li {
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0px 5px;
  }

  li:hover {
    background-color: #f0f0f0;
  }

  .option-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
`;

export const CardContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: 711px;
`;

export const EmptyCardContainer = styled.div`
  background-color: #ffffff;
  padding: 32px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: 711px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageProfile = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ListOrganization = styled.li`
  display: inline-flex;
  align-items: left;
  margin-right: 0px;
  vertical-align: middle;
`;
export const OrganizationCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 0 16px;
  margin-bottom: 24px;
`;
export const Table = styled.table`
  margin-bottom: 4px;
`;
export const CompanyProfile = styled.img`
  /* border-radius: 50%; */
  height: 56px;
  /* width: 56px; */
`;

export const GroupCardStyled = styled.div`
  max-width: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
  margin: 8px 4px;
  padding: 4px 12px;
  background: rgb(255, 255, 255);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 3px 1px;
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 1px 2px);
`;

export const UsersListCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 8px;
  margin-bottom: 24px;
`;

export const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  bottom: 50vh;
  left: 150vh;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  z-index: 10;
  width: 45%;

  .row {
    font-size: 14px;
    text-align: left;
  }
  @media (min-width: 1768px) {
    bottom: 60vh;
  }
`;

export const FilterDropdownMenu = styled.div`
  top: 33px;
  width: 267px;
  right: -98px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 12px 6px #00000026;
  border-radius: 4px;
  padding: 24px;
  z-index: 10;

  .row {
    font-size: 14px;
    text-align: left;
  }

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 49.5%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const InputFormFiltering = styled.input`
  width: 150px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;

export const ButtonFilter = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #1b2559;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
  width: 100%;
`;

export const SelectFormFiltering = styled.select`
  width: 150px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;

export const SelectFilterDropdown = styled.select`
  border-radius: 5px;
  height: 36px;
  z-index: 2;
  background-color: transparent;
  position: relative;
  cursor: pointer;
`;

export const SelectFormFilteringSmall = styled.select`
  width: 100px;
  margin: 0 16px;
  border-radius: 16px;
  height: 36px;
`;

export const OptionSelect = styled.option`
  font-size: 14px !important;
  font-weight: 400;
`;

export const GroupList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
`;

export const ArrowImg = styled.img`
  position: absolute;
  right: 0.575rem;
  top: 12px;
  height: 12px;
  z-index: 1;
`;
