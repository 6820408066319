import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { store } from "../../../../redux/store";
import { getGroupsAction, postGroupAction } from "../../../../redux/actions/groupsActions/groupsActions";
import { resetGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import { GroupsModalMap } from "../../../CustomMaps/CustomMap/GroupsModalMap";
import { useGroupsService } from "../../../../services/groupsService";
import { LoaderModal } from "../../../../components/LoaderModal/LoaderModal";
import { CardContainer, ButtonStrategy } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { LogoStyled } from "../../../Header/HeaderStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import luminaireIcon from "../../../../assets/new_icons/luminaire detailCard.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";

interface ReviewProps {
  onHide?: any;
  setShowDetails?: any;
  setShowDevices?: any;
  showDevices?: any;
  setShowReview?: any;
}

export const Review = ({
  onHide,
  setShowDetails,
  setShowDevices,
  showDevices,
  setShowReview
}: ReviewProps) => {
  const [showLoader, setShowLoader] = useState(false);
  const { getGroups, postGroup }: any = useGroupsService();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.groups.name);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const services = useAppSelector((state) => state.devices.services);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const serviceName = services.map((service:any, index:any) => {
    if (service.service_id === selectedServiceId) {
      return service.service_name
    }
  })
  const devicesInArea = useAppSelector((state) => state.filteredDevices.nodesInArea);
  const { t } = useTranslation();

  const sendInfo = async () => {
    setShowLoader(true);
    let postGroupResponse = await postGroup(selectedOrgId, selectedServiceId, setShowLoader);
    if (postGroupResponse) {
      store.dispatch(postGroupAction(postGroupResponse));
    } else {
      setShowLoader(false);
      console.log("error in postGroup api");
      return false;
    }
    let getGroupsResponse = await getGroups(selectedOrgId, selectedServiceId, setShowLoader);
    if (getGroupsResponse) {
      store.dispatch(getGroupsAction({ groups: getGroupsResponse }));
    } else {
      setShowLoader(false);
      console.log("error in getGroups api");
      return false;
    }
    dispatch(resetGroupInfoActionCreator());
    onHide();
  };

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <LoaderModal showLoader={showLoader} background={"rgba(0, 0, 0, 0.5)"} />
      <div style={{ marginBottom: "4px" }}>
        {/* <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{txt.step3}</Text> */}
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step2")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewConfiguration")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("reviewConfigurationSubtitleGroups")}</Text>
      </div>
      <div style={{display: "flex", gap: "20px", marginBottom: "20px"}}>
        <div style={{width: "350px" }}>
          <Text fontWeight="600" color="#475569" marginBottom="10px" style={{fontSize: "14px"}}>{t("groupName")}</Text>
          <div className="card" style={{ padding: "14px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)", borderRadius: "10px", justifyContent: "center", height: "71%" }}
          >
            <Text fontWeight="600" color="#475569" marginBottom="0px" style={{fontSize: "14px"}}>{name}</Text>         
          </div>
        </div>
        <div style={{width: "350px" }}>
          <Text fontWeight="600" color="#475569" marginBottom="10px" style={{fontSize: "14px"}}>{t("organizationAndService")}</Text>
          <div className="card" style={{ padding: "14px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)", borderRadius: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LogoStyled src={company} alt="smartec logo" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text fontWeight="600" color="#475569" marginBottom="2px" style={{fontSize: "14px"}}>{orgName}</Text>
                <div style={{ display: "flex", gap: "6px", opacity: 0.4 }}>
                  <img src={luminaireIcon} width={16} />
                  <Text fontWeight="400" marginBottom="0px" style={{fontSize: "10px", marginTop: "2px"}}>{serviceName}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Text fontWeight="600" color="#475569" marginBottom="10px" style={{fontSize: "14px"}}>{t("devicesSelected")}</Text>
      <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", gap: "20px", height: "420px", width: "96%" }}>
        <div style={{ position: "relative", width: "72%" }}>
          <GroupsModalMap />
        </div>
        <article style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", borderRadius: "4px", width: "28%",
          background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff", overflowY: "auto" }}
        >
          {devicesInArea.map((device:any, index:any) => (
            <>
              <style>
                {`
                  .org-span:hover {
                    background-color: #1c1b1f11;
                  }
                `}
              </style>
              <span className="org-span" key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", isolation: "isolate",
                paddingLeft: "15px", paddingRight: "6px", width: "100%", minHeight: "56px", cursor: "pointer", textAlign: "left" }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <img src={luminaireIcon} style={{ marginBottom: "1px" }} />
                  {device.name}
                </div>
              </span>
            </>
          ))}
        </article>
      </div>
      {/* <div style={{ display: "flex", alignContent: "center", justifyContent: "flex-start", position: "relative", width: "620px", height: "580px" }}>
        <GroupsMap />
      </div> */}
      <ButtonStrategy buttonType="primary" style={{justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "30px", marginLeft: "-40px", padding: "8px 32px", lineHeight: "none" }}
        onClick={() => {sendInfo()}}
      >
        {t("create")}
      </ButtonStrategy>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
        {/* <img src={stepDot} width={8} style={{ opacity: 0.3 }} /> */}
        <img src={stepLine} width={22} />
      </div>
    </CardContainer>
  );
};
