import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetGroupInfoActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { updateStrategyIdActionCreator } from "../../../redux/slices/nodeSlice/manageNodeSlice";
import { Modal } from "react-bootstrap";
import { useStrategiesService } from "../../../services/strategiesService";
import StrategiesChart from "../../StrategiesPage/StrategiesChart";
import styled from "styled-components";
import { ButtonStrategy } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";
import sunrise from "../../../assets/new_icons/Sunrise - Filled.svg";
import sunset from "../../../assets/new_icons/Sunset - Filled.svg";

interface StrategiesListModalProps {
  showModal: boolean;
  setShowModal: any;
  selectedLampId?: string | undefined;
  selectedLampsIds?: Array<[]>;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const StrategiesListModal = ({ showModal, setShowModal, selectedLampId, selectedLampsIds }: StrategiesListModalProps) => {
  const { assignStrategy } = useStrategiesService();
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState({
    index: "",
    state: false,
  });
  const [selectedLampStrategyId, setSelectedLampStrategyId]: any = useState("");

  const strategies = useAppSelector((state) => state.strategies.strategies);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);

  const { t } = useTranslation();
  
  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const sendInfo = async () => {
    if (selectedLampId) {
      await assignStrategy([selectedLampId], parseInt(selectedLampStrategyId), selectedOrgId, selectedServiceId);
    } else if (selectedLampsIds) {
      await assignStrategy(selectedLampsIds, parseInt(selectedLampStrategyId), selectedOrgId, selectedServiceId);
    }
    setShowModal(false);
    dispatch(updateStrategyIdActionCreator({ strategy_id: selectedLampStrategyId }))
  };

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center",  top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(resetGroupInfoActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
          <div>
            <Text fontWeight="700" marginBottom="5px" color="#475569" style={{fontSize: "22px" }}>{t("setDefaultStrategyTitle")}</Text>
            <Text fontWeight="400" marginBottom="5px" color="#475569" style={{fontSize: "16px" }}>{t("setDefaultStrategySubtitle")}</Text>
          </div>
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", paddingBottom: "20px", paddingTop: "20px", justifyContent: "center", overflowY: "scroll", maxHeight: "630px" }}>
            {strategies && strategies?.map((item: any, index: any) => (
              <div
                key={index}
                className="card"
                style={{
                  padding: "24px",
                  border: selectedCard.index === index && selectedCard.state === true ? "2px solid #000" : "none",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                  backdropFilter: "blur(2px)",
                  borderRadius: "16px",
                  width: "31%",
                }}
                onClick={() => {
                  setSelectedLampStrategyId(item.id);
                  selectedCard.index === index ? setSelectedCard({ index: index, state: !selectedCard.state }) : setSelectedCard({ index: index, state: true })
                }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px", gap: "10px" }}>
                  <div style={{width: "50%"}}>
                    <Text fontWeight="700" marginBottom="2px">{item.strgy_name}</Text>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "50%" }}>
                    {item.time1 === "98:98" ?
                      <>
                        <div style={{ display: "flex", gap: "5px" }}> 
                          <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                          <Text marginBottom="0px">{t("yes")}</Text>
                        </div>
                        <div style={{ display: "flex", gap: "16px" }}>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <img src={sunset} alt="" height={16} />
                            <Text marginBottom="0px">{item.sunset_off} {t("min")}</Text>
                          </div>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <img src={sunrise} alt="" height={16} />
                            <Text marginBottom="0px">{item.sunrise_off} {t("min")}</Text>
                          </div>
                        </div>
                      </>
                    :
                      <div style={{ display: "flex", gap: "5px" }}> 
                        <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                        <Text marginBottom="0px">{t("no")}</Text>
                      </div>
                    }
                  </div>
                </div>
                <Text color="#A3AED0" textType="caption" marginBottom="2px">{item.description}</Text>
                <StrategiesChart
                  chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.dim6, item.dim7, item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7]}
                  color={item.color}
                />
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px", height: "20px" }}>
                  <EnergyIcon height={16} style={{ color: item.color }} />
                  <TextSpan color={item.color} fontWeight="500">{item.saving}{t("percentageEnergySaved")}</TextSpan>
                </div>
              </div>
            ))}
          </div>
          {/* arreglar el style */}
          <div style={{display: "flex", justifyContent: "center", alignContent: "flex-end"}}>
            <ButtonStrategy buttonType="primary" style={{ marginLeft: "0px", opacity: !selectedCard.state ? 0.5 : 1, cursor: !selectedCard.state ? "not-allowed" : "pointer", width: "300px", marginTop: "30px", padding: "8px 32px", lineHeight: "none" }}
              onClick={() => {selectedCard.state === true ? sendInfo() : <></>}}
            >
              {t("assign")}
            </ButtonStrategy>
          </div>
          {/* <ButtonStrategy buttonType="primary" style={{ opacity: !selectedCard.state ? 0.5 : 1, cursor: !selectedCard.state ? "not-allowed" : "pointer", justifyContent: "center",
            alignSelf: "center", width: "300px", marginTop: "30px", padding: "8px 32px", lineHeight: "none" }}
            onClick={() => {selectedCard.state === true ? sendInfo() : <></>}}
          >
            {t("assign")}
          </ButtonStrategy> */}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
