import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../../types/interfaces";

const initialState: User = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  language_name: "",
  role_name: "",
  user_active: "",
  color: ""
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loadUser: (previousData, action: PayloadAction<User>) => ({
      ...action.payload
    }),

    updatePersonalInfo: (previousData, action: PayloadAction<User>) => ({
      ...previousData,
      first_name: action.payload.first_name,
      last_name: action.payload.last_name,
      phone_number: action.payload.phone_number
    }),

    updateDefaultLanguage: (previousData, action: PayloadAction<User>) => ({
      ...previousData,
      language_name: action.payload.language_name
    }),
  },
});

export const { 
  loadUser: loadUserActionCreator,
  updatePersonalInfo: updatePersonalInfoActionCreator,
  updateDefaultLanguage: updateDefaultLanguageActionCreator
} = userSlice.actions;

export default userSlice.reducer;
