import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MapContainer, TileLayer, ZoomControl, useMapEvents, Marker } from "react-leaflet";
import { Icon, LatLng, LeafletMouseEvent } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setFiltersActionCreator, setManageAreaActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filteredAllDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { setBoundsToFlyActionCreator, setIsStaticViewActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setHomeVisibleActionCreator,
  setFirstRenderMapToFalseActionCreator, changeRgbActionCreator, setNewNodeLatLongActionCreator, setSelectedNodeIdActionCreator, allowFlyModeActionCreator,
  setNodesInAreaActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
  import { useGroupsService } from "../../../services/groupsService";
  import CustomMarker from "../CustomMarker/CustomMarker";
  import { DetailPopup } from "../../DetailPopUp/DetailPopUp";
  import { DrawTool } from "../../DrawTool/DrawTool";
  import { FilterByGroup } from "../../FilterByGroup/FilterByGroup";
  import { FilterElement } from "../../FilterBar/FilterElement/FilterElement";
  import { GroupSelectionPopup } from "../../GroupSelectionPopup/GroupSelectionPopup";
import { MapEventHandler } from "../../MapEventHandler/MapEventHandler";
import { CustomMapStyled } from "./CustomMapStyled";
import { FilterBarStyled } from "../../FilterBar/FilterBarStyled";
import { Input } from "../../Input/InputStyled";
import "../../../styles/index.css";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { calculateDevicesInfo, getAllpositions } from "../../../utils/auxiliaryFunctions";
import { gateway, initialLamp, initialLampsGroup } from "../../../utils/data";
import alarm from "../../../assets/new_icons/Alarm.svg";
import cross from "../../../assets/new_icons/Cross.svg";
import disconnected from "../../../assets/new_icons/Node disconnected.svg";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";
import selectArea from "../../../assets/new_icons/select_area.svg";
import selectedIcon from "../../../assets/new_icons/icon-selected.svg";
import sun from "../../../assets/new_icons/Solar.svg";

//esto es necesario?
/*interface MapBounds {
  xOriginPixel: number;
  xOriginBounds: number;
  widthPixel: number;
  widthBounds: number;
  yOriginPixel: number;
  yOriginBounds: number;
  heightPixel: number;
  heightBounds: number;
}*/

interface CustomMapProps {
  center: LatLng;
  mapSize: React.CSSProperties;
  showFilters?: boolean;
}

export const CustomMap = ({
  center,
  mapSize,
  showFilters = true,
}: CustomMapProps) => {
  const selectedNodeId: any = useAppSelector((state) => state.ui.selectedNodeId);
  const newNodeLatLong: any = useAppSelector((state) => state.ui.newNodeLatLong);
  const allDevices = useAppSelector((state) => state.devices);
  const devices = useAppSelector((state) => state.filteredDevices);
  const nodesFromGroups = useAppSelector((state) => state.filteredDevices.nodesFromGroups);
  const isRgbVisible = useAppSelector((state) => state.ui.rgb);
  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  /* let cleanLamps = nodesInArea ?
    devices.nodesInArea?.filter((value:any, index:any) => {return devices.nodesInArea.indexOf(value) === index && value.node_id !== selectedNodeId})
  :
    devices.nodes?.filter((value:any, index:any) => {return devices.nodes.indexOf(value) === index && value.node_id !== selectedNodeId}); */


  /* let cleanLamps = nodesInArea ?
    isRgbVisible ?
      devices.rgbNodes?.filter((value:any, index:any) => {return devices.rgbNodes.indexOf(value) === index && value.node_id !== selectedNodeId})
    :
      devices.nodesInArea?.filter((value:any, index:any) => {return devices.nodesInArea.indexOf(value) === index && value.node_id !== selectedNodeId})
  :
    devices.nodes?.filter((value:any, index:any) => {return devices.nodes.indexOf(value) === index && value.node_id !== selectedNodeId}); */

  let cleanLamps = isRgbVisible ?
    nodesInArea ?
      devices.nodesInArea?.filter((value:any, index:any) => {return devices.nodesInArea.indexOf(value) === index && value.node_id !== selectedNodeId})
    :
      devices.rgbNodes?.filter((value:any, index:any) => {return devices.rgbNodes.indexOf(value) === index && value.node_id !== selectedNodeId})
  :
    nodesInArea ?
      devices.nodesInArea?.filter((value:any, index:any) => {return devices.nodesInArea.indexOf(value) === index && value.node_id !== selectedNodeId})
    :
      devices.nodes?.filter((value:any, index:any) => {return devices.nodes.indexOf(value) === index && value.node_id !== selectedNodeId});
  console.log("los nodos", cleanLamps);
  const detailPopUp = useAppSelector((state) => state.ui.isOpenedDetail);
  const groupPopUp = useAppSelector((state) => state.ui.isOpenedGroup);
  const firstRender = useAppSelector((state) => state.ui.firstRenderMap);
  const fullView = useAppSelector((state) => state.ui.isFullView);

  const dispatch = useAppDispatch();

  const [map, setMap] = useState(0);
  const [selectedLamp, setSelectedLamp] = useState<string>(initialLamp.node_id);
  const [nodesGroup, setNodesGroup] = useState<Array<string>>(initialLampsGroup.group);
  const [draw, setDraw] = useState(false);
  const [scale, setScale]:any = useState();
  const { t } = useTranslation();

  const newDrawSetState = (newValue: boolean) => {
    setDraw(newValue);
  };

  const addMarker = (e: LeafletMouseEvent) => {
    if (!selectedNodeId) return;
    dispatch(setNewNodeLatLongActionCreator({lat: e.latlng.lat, lng: e.latlng.lng}));
  };

  const MapClickHandler = () => {
    useMapEvents({ click: addMarker });
    return null;
  };

  const markerIcon = new Icon({
    iconUrl: OFF,
    iconSize: [20, 20],
    shadowUrl: selectedIcon,
    shadowSize: [40, 40],
  });

  window.addEventListener("beforeunload", async () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(
      setManageAreaActionCreator({
        all: false,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
  });

  /* if (!cleanLamps?.length) {
    //alert("No devices found");
    dispatch(filteredAllDevicesActionCreator(allDevices));
    dispatch(filteredAllDevicesActionCreator(devices));
    dispatch(
      setFiltersActionCreator({
        all: false,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
    dispatch(setIsStaticViewActionCreator(true));
  } else {
    const hasLatLng = cleanLamps.some((lamp:any) => lamp.latitude && lamp.longitude);

    if (hasLatLng) {
      dispatch(setBoundsToFlyActionCreator(cleanLamps));
    }
  } */

  useEffect(() => {
    if (!cleanLamps?.length) {
      dispatch(filteredAllDevicesActionCreator(allDevices));
      //dispatch(filteredAllDevicesActionCreator(devices));
      dispatch(setNodesInAreaActionCreator(false));
      dispatch(
        setFiltersActionCreator({
          all: false,
          area: false,
          on: false,
          off: false,
          disconnected: false,
          alarmas: false,
        })
      );
      dispatch(setIsStaticViewActionCreator(true));
    } else {
      const hasLatLng = cleanLamps.some((lamp:any) => lamp.latitude && lamp.longitude);

      if (hasLatLng) {
        dispatch(setBoundsToFlyActionCreator(cleanLamps));
      }
    }
  }, [cleanLamps, allDevices, devices, dispatch, devices.rgbNodes, isRgbVisible]);

  useEffect(() => {
    if (!detailPopUp && !groupPopUp) {
      setSelectedLamp(initialLamp.node_id);
      setNodesGroup(initialLampsGroup.group);
    }

    if (firstRender) {
      dispatch(setIsStaticViewActionCreator(false));
      //dispatch(setFiltersActionCreator({ all: true, area: false, on: false, off: false, disconnected: false, alarmas: false }));
      dispatch(setFirstRenderMapToFalseActionCreator());
    } else if (!detailPopUp || !groupPopUp) {
      dispatch(setIsStaticViewActionCreator(true));
    }

    /* if (areaState) {
      if (detailPopUp || !groupPopUp) {
        dispatch(
          setFiltersActionCreator({
            all: true,
            area: false,
            on: false,
            off: false,
            disconnected: false,
            alarmas: false,
          })
        );
        /* dispatch(filteredAllDevicesActionCreator(allDevices))
      }
    } */

    dispatch(setHomeVisibleActionCreator());
    //dispatch(setSelectedNodeIdActionCreator(""))
  
  //Ejemplos para tratar el sunset-sunrise según la fecha que le den
  /* var times = SunCalc.getTimes(new Date(), latitude, longitude);
  var times2 = SunCalc.getTimes(new Date('2024-01-11'), latitude, longitude);
  var date = new Date();
  date.setDate(date.getDate() + 1);
  var times3 = SunCalc.getTimes(date, latitude, longitude);
  console.log("los times", times.sunset, times.sunrise, times, times2, times3) */

  }, [detailPopUp, groupPopUp, dispatch, firstRender, isRgbVisible]);
  
  // este código me marca una zona limitada pero no puedo hacer zoom y encima se carga el tope de zoom que he puesto

  /*const southWest: LatLngExpression = [24.812, -77.627]; // Coordenadas del límite suroeste
  const northEast: LatLngExpression = [24.974, -77.625]; // Coordenadas del límite noreste
  const bounds = new LatLngBounds(southWest, northEast); // Crea el objeto de límites

  function MapBounds() {
    const ew = useMap();

    ew.fitBounds(bounds); // Establece los límites en el objeto de mapa

    return null;
  }*/
  /*<MapBounds />*/

  /* const { deleteGroup } = useGroupsService();
  const [groupId, setGroupId] = useState(""); */

  return (
    <CustomMapStyled id="map-container" style={mapSize}>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={5}
        scrollWheelZoom={true}
        maxZoom={18}
        minZoom={2}
        zoomControl={false}
        attributionControl={false}
        /*maxBounds={bounds}*/
      >
        <TileLayer
          key={"map layer"}
          //url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
        <MapClickHandler />
        {newNodeLatLong && selectedNodeId && (
          <Marker icon={markerIcon} position={newNodeLatLong}></Marker>
        )}

        {/* gestionar el lamp filtered y lampgroups desde aquí */}
        <MapEventHandler
          positions={getAllpositions(cleanLamps)}
          setNodesGroup={setNodesGroup}
          lamps={cleanLamps}
          setDraw={setDraw}
          draw={draw}
          key={"map events handler"}
          setMapBounds={setMap}
        ></MapEventHandler>

        <DrawTool
          markers={cleanLamps}
          nodes={cleanLamps}
          setNodesGroup={setNodesGroup}
          nodesGroup={nodesGroup}
          title={t("Select an area")}
        />

        <MarkerClusterGroup
          chunkedLoading
          onClick={() => {}}
          maxClusterRadius={50}
          removeOutsideVisibleBounds
          disableClusteringAtZoom={15}
          spiderfyOnMaxZoom={false}
          zoom={10}
        >
          {cleanLamps?.map((lamp:any) => {
            if(lamp.latitude && lamp.longitude) {
              //aquí tendré que poner un icono u otro según el tipo de nodo
              return (
                <CustomMarker
                  setNodesGroup={setNodesGroup}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedLamp === lamp.node_id}
                  editable={false}
                  interactive={true}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              )
            }
          })}
        </MarkerClusterGroup>
      </MapContainer>
      {nodesInArea &&
        <div style={{ position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", margin: "8px 4px", padding: "8px 8px", top: "70px", left: "30px",
          //"#525252" //"#363636" //"#0f0f0f"
          boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)", borderRadius: "30px", backgroundColor: "white", border: "2px solid #1B2559", cursor: "pointer", gap: "10px",
          transition: "transform 0.1s ease-in-out", transform: `scale(${scale})`
        }} 
        onMouseEnter={() => setScale(1.03)}
        onMouseLeave={() => setScale(1)} 
        onClick={() => {dispatch(setNodesInAreaActionCreator(false)); dispatch(setIsOpenedGroupActionCreator(false)); dispatch(filteredAllDevicesActionCreator(allDevices))}}>
          <img src={cross} style={{ width: "13px", height: "13px", marginTop: "2px", padding: "1px" }} />
          <Text marginBottom="0px" style={{ color: "#1b2559", fontWeight: "bold", fontSize: "14px", marginRight: "10px" }}>{t("removeArea")}</Text>
        </div>
      }

      {detailPopUp && devices.nodes.length && (
        <DetailPopup
          setSelectedMarker={setSelectedLamp}
          setNodesGroup={setNodesGroup}
          node={cleanLamps?.filter((lamp:any) => lamp.node_id === selectedLamp)[0]}
        />
      )}

      {groupPopUp && devices.nodes.length && nodesGroup.length > 0 && (
        <GroupSelectionPopup
          setSelectedMarker={setSelectedLamp}
          setSelectedGroup={setNodesGroup}
          devicesInfo={calculateDevicesInfo(
            cleanLamps?.filter((node:any) => nodesGroup.includes(node.node_id)),
            [gateway]
          )}
        />
      )}

      {showFilters && (
        <>
          {!isRgbVisible ? (
            <>
              <FilterByGroup />
            </>
          ) : (
            <></>
          )}
          {devices.rgbNodes?.length !== 0 && fullView ? (
            <div style={{ position: "absolute", top: "30px", right: "30px", padding: "8px 8px", background: "#ffffff", border: isRgbVisible ? "2px solid #1B2559" : "1.5px solid #a3aed0",
              borderRadius: "30px", cursor: "pointer", boxShadow: isRgbVisible ? "rgba(0, 0, 0, 0.15) 0px 4px 8px 3px, rgba(0, 0, 0, 0.3) 0px 1px 3px" : "" }}
              onClick={() => {dispatch(setIsOpenedDetailActionCreator(false)); dispatch(setIsOpenedGroupActionCreator(false)); dispatch(setNodesInAreaActionCreator(false));
                dispatch(changeRgbActionCreator()); !isRgbVisible && dispatch(filteredAllDevicesActionCreator(allDevices)) }}
            >
              <Text marginBottom="0px" color="rgb(27, 37, 89)" style={{ fontWeight: isRgbVisible ? "bold" : "", marginLeft: "10px", marginRight: "10px" }}>
                RGB ({devices.rgbNodes?.length})
              </Text>
            </div>
          ) : (
            <></>
          )}
          {/* <Input onChange={(e) => setGroupId(e.target.value)} />
          <Button onClick={() => {deleteGroup(groupId)}}>DELETE</Button> */}
          <FilterBarStyled>
            {/* <FilterElement
              type="selectArea"
              icon={selectArea}
              title={t("Select an area")}
              newDrawSetState={newDrawSetState}
            /> */}
            {/* commented code for backup */}
            {/* {(nodesFromGroups?.some((item:any) => item.on === 1) || filteredLamps.some(item => item.on === 1)) && <FilterElement type="ON" icon={ON} title={t("ON")} />}
            {(nodesFromGroups?.some((item:any) => item.on === 0) || filteredLamps.some(item => item.on === 0)) && <FilterElement type="OFF" icon={OFF} title={t("OFF")} />}
            {(nodesFromGroups?.some((item:any) => item.online === 0) || filteredLamps.some(item => item.online === 0)) && <FilterElement type="Disconnected" icon={disconnected} title={t("Disconnected")} />} */}
            <FilterElement type="All" icon={sun} title={t("All")} />
            {!isRgbVisible ? (
              <>
                {!nodesFromGroups || nodesFromGroups.length === 0 ?
                  nodesInArea ?
                    allDevices.nodesInArea.some((item:any) => item.on === 1 && item.online === 1) && <FilterElement type="ON" icon={ON}
                      title={t("ON").charAt(0).toUpperCase() + t("ON").slice(1).toLowerCase()} />
                  :
                    allDevices.nodes.some((item:any) => item.on === 1 && item.online === 1) && <FilterElement type="ON" icon={ON}
                      title={t("ON").charAt(0).toUpperCase() + t("ON").slice(1).toLowerCase()} />
                :
                  nodesFromGroups?.some((item:any) => item.on === 1 && item.online === 1) && <FilterElement type="ON" icon={ON}
                    title={t("ON").charAt(0).toUpperCase() + t("ON").slice(1).toLowerCase()} />
                }
                {!nodesFromGroups || nodesFromGroups.length === 0 ?
                  nodesInArea ?
                      allDevices.nodesInArea.some((item:any) => item.on === 0 && item.online === 1) && <FilterElement type="OFF" icon={OFF}
                        title={t("OFF").charAt(0).toUpperCase() + t("OFF").slice(1).toLowerCase()} />
                    :
                      allDevices.nodes.some((item:any) => item.on === 0 && item.online === 1) && <FilterElement type="OFF" icon={OFF}
                        title={t("OFF").charAt(0).toUpperCase() + t("OFF").slice(1).toLowerCase()} />
                  :
                    nodesFromGroups?.some((item:any) => item.on === 0 && item.online === 1) && <FilterElement type="OFF" icon={OFF}
                      title={t("OFF").charAt(0).toUpperCase() + t("OFF").slice(1).toLowerCase()} />
                }
                {!nodesFromGroups || nodesFromGroups.length === 0 ?
                  nodesInArea ?
                      allDevices.nodesInArea.some((item:any) => item.online === 0) && <FilterElement type="Disconnected" icon={disconnected} title={t("Disconnected")} />
                    :
                      allDevices.nodes.some((item:any) => item.online === 0) && <FilterElement type="Disconnected" icon={disconnected} title={t("Disconnected")} />
                  :
                    nodesFromGroups?.some((item:any) => item.online === 0) && <FilterElement type="Disconnected" icon={disconnected} title={t("Disconnected")} />
                }
              </>
            ) : (
              <></>
            )}
            {/* <FilterElement
              type="Alarmed"
              icon={alarm}
              title={t(txt.alarmsCapitalLetter)}
            /> */}
          </FilterBarStyled>
        </>
      )}
    </CustomMapStyled>
  );
};
