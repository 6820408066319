import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import Roles from "../Step2Roles/Roles";
import { changeStepActionCreator, registerUserActionCreator, setOrganizationPermitsActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import {
  InputField,
  CardContainer,
  NextButton,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import back from "../../../../assets/new_icons/Back_2.svg";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import Language from "../../../../assets/new_icons/Language.svg";
import Company from "../../../../assets/new_icons/Company.svg";
import maps from "../../../../assets/new_icons/maps.svg";
import OnMetric from "../../../../assets/new_icons/OnMetric.svg";
import OffMetric from "../../../../assets/new_icons/OffMetric.svg";

interface NameProps {
  showDevices?: any;
  goToDevices?: any;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

const OrganizationPermit = ({
  showDevices,
  goToDevices,
  goToReview,
  errorMessage,
  setErrorMessage,
}: NameProps) => {
  const dispatch = useAppDispatch();

  const groupInfo = useAppSelector((state) => state.groups);
  const { user } = useAppSelector((state) => state.userRegistration);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const { organizations } = useAppSelector((state) => state.devices);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const [formError, setFormError] = useState<any>(null);
  const { t } = useTranslation();
  const [selectedPermitId, setSelectedPermitId] = useState(user?.organization_id ?? null);
  const [name, setName] = useState(groupInfo.name);


  const handleCheckboxChange = (id: any) => {
    setSelectedPermitId(id);
  };

  useEffect(() => {
    if (name) {
      setErrorMessage(false);
    }

    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  const organizationPermitSubmit = () => {
    if (!selectedPermitId) {
      return setFormError('Please select a permit')
    }
    dispatch(changeStepActionCreator(4));
    dispatch(registerUserActionCreator({ organization_id: selectedPermitId }));
  }


  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "55px",
          paddingLeft: "40px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "-31px",
            marginTop: "-40px",
            marginBottom: "30px",
            fontSize: "12px",
            fontWeight: "400",
            color: "#BDBDBD",
            cursor: "pointer",
          }}
          onClick={() => dispatch(changeStepActionCreator(2))}
        >
          <img src={back} alt="back" style={{ marginRight: "10px" }} />
          <span>Back to Roles and Language</span>
        </div>
        <Text
          fontWeight="500"
          style={{ marginBottom: "4px", fontSize: "14px" }}
        >
          {t("Step 3")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("organizationPermit")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("organizationPermitDescription")}
        </Text>
      </div>

      <div style={{ width: "80%", margin: "auto" }}>
        <div className="row">
          {organizations.map((permit: any) => (
            <div className="col-6" key={permit.id}>
              <div
                className="card px-3 py-3 d-flex justify-content-between flex-row align-items-center"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 3px 6px #d6d5d1",
                  border: "0px",
                  marginBottom: "33px",
                }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={Language}
                    alt="flag"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  />
                </div>
                <div className="flex-grow-1 mx-4">
                  <h6 className="mb-0" style={{ fontSize: "14px", fontWeight: "600" }}>
                    {permit.organization_name}
                  </h6>
                  <div className="d-flex align-items-center">
                    <p className="mb-0 me-2">
                      <img src={Company} alt="company" />
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "#A3AED0",
                          marginLeft: "3px",
                        }}
                      >
                        Company
                      </span>
                    </p>
                    <p className="mb-0 me-2">
                      <img src={maps} alt="maps" />
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "#A3AED0",
                          marginLeft: "3px",
                        }}
                      >
                        {permit.city ? permit.city + ", " : ""}
                        {permit.country}
                      </span>
                    </p>
                    <p className="mb-0">
                      <img src={OnMetric} alt="toggle" />
                      <span
                        style={{
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "#A3AED0",
                          marginLeft: "3px",
                        }}
                      >
                        {permit.organization_active === "Y" ? "yes" : "no"}
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <input
                    className="form-check-input org-permit-checkbox"
                    type="checkbox"
                    id={`checkboxNoLabel${permit.id}`}
                    value=""
                    style={{
                      height: "18px",
                      width: "18px",
                      border: "2px solid",
                      borderRadius: "2px",
                      cursor: "pointer"
                    }}
                    checked={selectedPermitId === permit.id}
                    onChange={() => handleCheckboxChange(permit.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {formError && <p style={{ color: 'red', display: 'flex', justifyContent: 'center' }}>{formError}</p>}
      <NextButton
        style={{
          justifySelf: "end",
          alignSelf: "center",
          width: "374px",
          marginTop: "100px",
        }}
        onClick={organizationPermitSubmit}
      >
        {t("continue")}
      </NextButton>
    </CardContainer>
  );
};

export default OrganizationPermit;
