import styled from "styled-components";

export const HeaderStyled = styled.header`
  height: 96px;
  min-width: 880px;
  width: 100%;
  font-family: ${(props) => props.theme.mainFont};
  color: ${(props) => props.theme.mainTitleColor};
`;

export const NavStyled = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  background-color: #fbfcfe;
  padding: 0px;
  width: 100%;
  z-index: 1;
`;

export const LogoStyled = styled.img`
  justify-content: start;
  align-items: flex-start;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0px;
`;

export const HeaderTitleStyled = styled.div`
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

export const SpacerStyled = styled.div`
  flex: 1;
`;

export const IconsListStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const IconStyled = styled.img`
  height: 18px;
  margin: 0px 8px;
  cursor: pointer;
`;

export const IconProfileStyled = styled.img`
  height: 16px;
  margin-right: 16px;
  margin-bottom: 3px;
`;

export const ProfileIconStyled = styled.img`
  height: 40px;
  //28px?
  width: 40px;
  border-radius: 50%;
  margin: 0px 32px;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  cursor: pointer;
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 32px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 75%;
  left: 92%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  z-index: 10;
  width: 250px;
  .row {
    padding: 16px;
    font-size: 14px;
    text-align: left;
  }
`;

export const DropdownItem = styled.div`
  padding: 8px 24px;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;
