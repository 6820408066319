import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useAppDispatch } from "../../../../redux/hooks";
import { resetSolarParametersActionCreator } from "../../../../redux/slices/solarParametersSlice/solarParametersSlice";
import ConsumptionChart from "./ConsumptionChart";
import { useConsumptionsService } from "../../../../services/consumptionsService";
import { NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ConsumptionProps {
  selectedLamp: any
}

export const Consumption = ({ selectedLamp }: ConsumptionProps) => {
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [firstRender, setFirstRender] = useState(true);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [errorNoData, setErrorNoData] = useState(false);
  
  const { getSolar, getSolarTwoDates } = useConsumptionsService();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const manageSolarInfo = async () => {
    if (startDate.getTime() !== endDate.getTime()){
      //revisar eso de "solar"
      const result = await getSolarTwoDates(selectedLamp.node_id, startDate, endDate, "solar")
      if (!result) {
        setErrorNoData(true)
      }
    } else if (startDate.getTime() === endDate.getTime()) {
      const result = await getSolar(selectedLamp.node_id, startDate, "solar")
      console.log(result)
      if (!result) {
        setErrorNoData(true)
      }
    }

    setTimeout(() => {
      setErrorNoData(false);
    }, 2000);
  }

  useEffect(() => {
    if (firstRender) {
      dispatch(resetSolarParametersActionCreator());
    }
    setFirstRender(false)
  }, []);

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%",
        //height: "300px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "4px"
        }}
      >
        <div>
          <Text color="#A3AED0">{t("Consumption")}</Text>
        </div>
        <div style={{ display: "flex", /* gap: "80px", */ alignItems: "end" }}>
          {/* <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #16D764", backgroundColor: "rgba(22, 215, 100, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("pannel")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: "rgba(0, 199, 242, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("Battery")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: "rgba(255, 203, 33, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("Luminaire")}</Text>
            </div>
          </div> */}
          <div style={{ display: "flex", gap: "4px" }}>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #16D764", backgroundColor: "rgba(22, 215, 100, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>Battery level</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: "rgba(0, 199, 242, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>Battery voltage</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: "rgba(255, 203, 33, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>Battery current</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: "red", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>Luminaire power</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: "orange", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>Charge power</Text>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px", }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("startDate")}</Text>
              <DatePicker
                className="datePickerSolar"
                placeholderText={t("selectDate")}
                selectsRange={false}
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("endDate")}</Text>
              <DatePicker
                className="datePickerSolar"
                placeholderText={t("selectDate")}
                selectsRange={false}
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
              />
            </div>
            <NextButton style={{ alignSelf: "end", width: "120px", padding: "8px 8px", fontSize: "12px", marginLeft: "6px", opacity: !startDate || !endDate ? 0.5 : 1,
              cursor: !startDate || !endDate ? "not-allowed" : "pointer", boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)" }}
              onClick={() => {manageSolarInfo()}}
              disabled={!startDate || !endDate}
            >
              {t("viewData")}
            </NextButton>
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      {errorNoData ?
          <Text marginBottom="0px" color="red" style={{ textAlign: "center" }}>{t("errorNoData")}</Text>
        :
          <></>
      }
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ConsumptionChart />
      </div>
    </div>
  );
};
