import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { store } from "../../../redux/store";
import { fetchAlarmsFromNode } from "../../../redux/actions/alarmsActions/alarmsActions";
import { setFiltersActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filteredAllDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { resetButtonsActionCreator, resetParametersActionCreator } from "../../../redux/slices/electricParametersSlice/electricParametersSlice";
import { changeBackgroundDashboardActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";
import { resetSolarParametersActionCreator } from "../../../redux/slices/solarParametersSlice/solarParametersSlice";
import { setIsStaticViewActionCreator, setViewsToFalseActionCreator, setHomeVisibleActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { useAlarmsService } from "../../../services/alarmService";
import { useLightingService } from "../../../services/lightingService";
import { useStrategiesService } from "../../../services/strategiesService";
import { checkAll, onMasterCheck, onItemCheck, makeMenusVisible, makeIconsVisible, changeOrder, changeState, checkSelected } from "../ListsLogic";
import { ErrorPopUp, Scroll, Space, IconStyled, SwitchComponent, GroupText, ListPopUp } from "../ListsStyled";
import { ThemeProvider } from "styled-components";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { googleIcons } from "../../../utils/googleFontsIcons/icons";
import { languages } from "../../../utils/languages/languages";
import Arrow_right_full from "../../../assets/new_icons/arrow_right_full.svg";
import AZ from "../../../assets/new_icons/A-Z.svg";
import clearFilters from "../../../assets/new_icons/Cross.svg";
import clearFiltersLight from "../../../assets/new_icons/Cross lightGrey.svg";
import connectedIcon from "../../../assets/new_icons/Connected.svg";
import dimming from "../../../assets/new_icons/Dimming.svg";
import disconnectedIcon from "../../../assets/new_icons/Node disconnected.svg";
import doubleArrow from "../../../assets/new_icons/Filter double arrow.svg";
import ellipseGreen from "../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../assets/new_icons/ellipse_yellow.svg";
import gatewayIcon from "../../../assets/new_icons/Gateway nodePage.svg";
import groupsIcon from "../../../assets/new_icons/Groups.svg";
import luminaireIcon from "../../../assets/new_icons/luminaire detailCard.svg";
import Options from "../../../assets/new_icons/Options.svg";
import refresh from "../../../assets/new_icons/list_refresh.svg";
import switchOff from "../../../assets/new_icons/Switch OFF.svg";
import switchOn from "../../../assets/new_icons/Switch ON.svg";
import turnOn from "../../../assets/new_icons/Switch ON.svg";
import turnOff from "../../../assets/new_icons/Switch OFF.svg";
import ZA from "../../../assets/new_icons/Z-A.svg";

const theme = {
  primaryColor: styledMainTheme?.light?.smartecGreen,
};

export const ListTable = () => {
  const dispatch = useAppDispatch();
  const { sendCommandToLuminaire, getNodeInfo } = useLightingService();
  const { getStrategies } = useStrategiesService();
  const { getNodeAlarms } = useAlarmsService();
  const navigate = useNavigate();
  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  const devices = useAppSelector((state) => state.devices);
  const filteredNodes = useAppSelector((state) => state.filteredDevices.nodes);
  const filteredAreaNodes = useAppSelector((state) => state.filteredDevices.nodesInArea);
  const { nodes } = useAppSelector((state) => state.devices);
  const mqttError = useAppSelector((state) => state.mqtt.error);
  const groupsList = useAppSelector((state) => state.groups.groups);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { close, sadFace } = googleIcons;

  const [list, setList]: any = useState(nodesInArea ? filteredAreaNodes : filteredNodes);
  const [masterChecked, setMasterChecked] = useState<boolean>(false);

  //esto quizás no?
  const [selectedList, setSelectedList] = useState([]);

  // usestates for menus
  const [nameOrder, setNameOrder] = useState(false);
  const [deviceOrder, setDeviceOrder] = useState(false);
  const [connectionOrder, setConnectionOrder] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [alarmOrder, setAlarmOrder] = useState(false);
  const [groupsOrder, setGroupsOrder] = useState(false);

  // usestates for icons
  const [nameAZ, setNameAZ] = useState(false);
  const [nameZA, setNameZA] = useState(false);
  const [luminaire, setLuminaire] = useState(false);
  const [gateway, setGateway] = useState(false);
  const [connected, setConnected] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [on, setOn] = useState(false);
  const [off, setOff] = useState(false);
  const [clear, setClear] = useState(false);
  const [critical, setCritical] = useState(false);
  const [moderate, setModerate] = useState(false);
  const [groupsAZ, setGroupsAZ] = useState(false);
  const [groupsZA, setGroupsZA] = useState(false);
  const [groups, setGroups] = useState(false);
  const { t } = useTranslation();

  const handleClickSingle = async (node: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    try {
      let data: any = sendCommandToLuminaire([node], order, 0, 0);
      /* if (data[0][0].result !== 0) {
      } */
    } catch (error) {
      alert(error);
    }
  };

  const handleClickMultiple = async (nodes: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true) {
        newArr.push(node);
      }
    });

    try {
      if (newArr.length > 0) {
        sendCommandToLuminaire(newArr, order, 0, 0);
      }
    } catch (error) {
      alert(error);
    }
  };

  const changeBackground = (nodes: any) => {
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true) {
        newArr.push(node);
      }
    });

    if (newArr.length > 0) {
      dispatch(changeBackgroundDashboardActionCreator());
    }
  };

  const handleClickViewDetails = async (device: any) => {
    let viewState = "list";
    await getNodeInfo(device.node_id);
    await getStrategies(selectedOrgId, selectedServiceId);
    try {
      const token:any = Cookies.get("token");
      let response = await getNodeAlarms(selectedOrgId, selectedServiceId, device.node_id, token)
      if (response) {
        store.dispatch(fetchAlarmsFromNode(response));
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
    }
    dispatch(resetParametersActionCreator());
    dispatch(resetButtonsActionCreator());
    dispatch(resetSolarParametersActionCreator());
    dispatch(setViewsToFalseActionCreator());
    navigate(`/luminaire/${device.node_id}`, { state: { viewState } });
  };

  const resetFilters = () => {
    dispatch(filteredAllDevicesActionCreator(devices));
    makeIconsVisible(
      "",
      setNameAZ,
      setNameZA,
      setConnected,
      setDisconnected,
      setOn,
      setOff,
      setClear,
      setCritical,
      setModerate,
      setGroupsAZ,
      setGroupsZA,
      setGroups,
      setLuminaire,
      setGateway
    );
    dispatch(
      setFiltersActionCreator({
        all: true,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarmas: false,
      })
    );
  };

  useEffect(() => {
    setList(nodesInArea ? filteredAreaNodes : filteredNodes);
    dispatch(setHomeVisibleActionCreator());
    onMasterCheck(list, setMasterChecked);

    const handleClickOutsideMenu = (event: MouseEvent) => {
      /* if (
        nameOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setNameOrder(!nameOrder);
      }

      if (
        deviceOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setDeviceOrder(!deviceOrder);
      }

      if (
        connectionOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setConnectionOrder(!connectionOrder);
      }

      if (
        statusOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setStatusOrder(!statusOrder);
      }

      if (
        alarmOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setAlarmOrder(!alarmOrder);
      }

      if (
        groupsOrder &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setGroupsOrder(!groupsOrder);
      } */

      const target = event.target as Element;

      if (target && !target.closest("thead")) {
        setNameOrder(false);
        setDeviceOrder(false);
        setConnectionOrder(false);
        setStatusOrder(false);
        setAlarmOrder(false);
        setGroupsOrder(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [nodesInArea, filteredNodes, filteredAreaNodes, devices, list, nameOrder, deviceOrder, connectionOrder, statusOrder, alarmOrder, groupsOrder]);

  /*<tr key={device.node_id} className={user.selected ? "selected" : ""}>*/

  /*
  <td>
    {device.groups.map((group, index) => (
      <GroupText key={index}>{group}</GroupText>
    ))}
  </td>
  */

  return (
    <>
      {/* commented code, we will manage this error in a different way */}
      {/* <div
        style={{
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          marginTop: "200px",
        }}
      >
        {mqttError && (
          <ErrorPopUp>
            <div className="errorpopup__button-container">
              <span
                onClick={() => {
                  dispatch(errorToInitialStateActionCreator());
                }}
                className="material-symbols-rounded errorpopup__button-container__icon"
              >
                {close}
              </span>
            </div>
            <div className="errorpopup__message-container">
              <span
                className="material-symbols-rounded errorpopup__message-container__icon"
                style={{ color: "#CF2A2A", marginRight: "5px" }}
              >
                {sadFace}
              </span>
              <TextSpan color="#CF2A2A">{t(txt.errorConnection)}</TextSpan>
            </div>
          </ErrorPopUp>
        )}
      </div> */}
      <div style={{ display: "flex", alignItems: "center", gap: "15px", zIndex: 2, position: "absolute", top: checkSelected(list) ? "104px" : "106px", left: "10px" }}>
        <input
          type="checkbox"
          className="form-check-input"
          checked={masterChecked}
          id="mastercheck"
          onChange={(e) => checkAll(e, list)}
          style={{ cursor: "pointer", width: "16px", height: "16px" }}
        />
        <IconStyled
          src={refresh}
          style={{ cursor: "pointer", marginRight: "0px", marginTop: "5px", width: "15px", height: "15px" }}
          title={t(txt.refresh)}
        />
        <IconStyled
          src={clearFilters}
          onClick={() => {
            resetFilters();
          }}
          style={{ cursor: "pointer", marginRight: "0px", marginTop: "5px", width: "13px", height: "13px" }}
          title={t(txt.clearAllFilters)}
        />
        {checkSelected(list) ?
          <>
            <Text marginBottom="0" style={{ marginLeft: "-4px", marginRight: "6px" }}>
              |
            </Text>
            <div
              onClick={() => {
                handleClickMultiple(list, 1);
              }}
              style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", marginTop: "4px", marginRight: "12px" }}
              title={t(txt.switchON)}
            >
              <IconStyled
                style={{ marginRight: "1px", width: "14px", height: "14px" }}
                src={turnOn}
              />
              <Text marginBottom="0">{t(txt.switchON)}</Text>
            </div>
            <div
              onClick={() => {
                handleClickMultiple(list, 2);
              }}
              style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", marginTop: "4px", marginRight: "12px" }}
              title={t(txt.switchOFF)}
            >
              <IconStyled
                style={{ marginRight: "1px", width: "16px", height: "16px" }}
                src={turnOff}
              />
              <Text marginBottom="0">{t(txt.switchOFF)}</Text>
            </div>
            <div
              onClick={() => {
                changeBackground(list);
              }}
              style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", marginTop: "4px", marginRight: "12px" }}
              title={t(txt.dimming)}
            >
              <IconStyled
                style={{ marginRight: "1px", width: "16px", height: "16px" }}
                src={dimming}
              />
              <Text marginBottom="0">{t(txt.dimming)}</Text>
            </div>
          </>
        :
          <></>
        }
        <Text marginBottom="0" style={{ marginLeft: "-4px", marginRight: "6px" }}>
          |
        </Text>
        {/* <div style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer", marginTop: "4px" }} title={t(txt.newGroup)}>
          <IconStyled style={{ marginRight: "1px", width: "16px", height: "16px" }} src={groupsIcon} />
          <Text marginBottom="0">{t(txt.newGroup)}</Text>
        </div> */}
      </div>
      <Scroll style={{ position: "relative", overflowX: "hidden", zIndex: 1, borderTop: "1px solid #b6b7ba" }}>
        <div className="row" style={{ paddingRight: 15, paddingLeft: 12 }}>
          {/* <div className="row" style={{ paddingRight: 22, paddingLeft: 12 }}> */}
          <table
            className="table table-borderless"
            style={{ fontSize: "12px" }}
          >
            <thead style={{ boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)", position: "sticky", top: 0, zIndex: 1, background: "white" }}>
              <tr style={{ position: "relative" }}>
                <th scope="col" style={{ width: "50px" }}></th>
                {/* <th scope="col" style={{ cursor: "pointer", width: "130px" }}>
                  <TextSpan fontWeight="700">{t("ID")}</TextSpan>
                </th> */}
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setNameOrder(!nameOrder);
                      makeMenusVisible(
                        "name",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t("Name")}</TextSpan>
                    {nameAZ ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={AZ}
                        className="icon-class"
                      />
                    ) : nameZA ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={ZA}
                        className="icon-class"
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} className="icon-class" />
                  </div>
                  {/* EN PRINCIPIO YA FUNCIONA. APLICAR AL RESTO DE MENU POPUPS Y REVISAR SI LOS USESTATE DE FALSE QUE SE TIRAN HACIA LOS MENUS QUE NO SON ELEGIDOS,
                  SON REALMENTE NECESARIOS */}
                  {nameOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "nameAZ",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={AZ}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromAToZ)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            changeOrder(list, 2, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "nameZA",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setNameOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={ZA}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromZToA)}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setDeviceOrder(!deviceOrder);
                      makeMenusVisible(
                        "device",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.device)}</TextSpan>
                    {luminaire ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={luminaireIcon}
                      />
                    ) : gateway ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={gatewayIcon}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {deviceOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setDeviceOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "luminaire",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setDeviceOrder(!deviceOrder);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={luminaireIcon}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.luminaire)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            changeOrder(list, 2, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "gateway",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setDeviceOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={gatewayIcon}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.gateway)}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setConnectionOrder(!connectionOrder);
                      makeMenusVisible(
                        "connection",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.connection)}</TextSpan>
                    {connected ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={connectedIcon}
                      />
                    ) : disconnected ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={disconnectedIcon}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {connectionOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setConnectionOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeState(
                              {
                                connection: 1,
                                status: !on && !off ? null : on && !off ? 1 : 0,
                              },
                              nodes
                            );
                            setConnectionOrder(false);
                            setDisconnected(false);
                            setConnected(true);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={connectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Connected")}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            changeState(
                              {
                                connection: 0,
                                status: !on && !off ? null : on && !off ? 1 : 0,
                              },
                              nodes
                            );
                            setConnectionOrder(false);
                            setDisconnected(true);
                            setConnected(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={disconnectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Disconnected")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setStatusOrder(!statusOrder);
                      makeMenusVisible(
                        "status",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t(txt.status)}</TextSpan>
                    {on ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={switchOn}
                      />
                    ) : off ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={switchOff}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {statusOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeState(
                              {
                                connection:
                                  !connected && !disconnected
                                    ? null
                                    : connected && !disconnected
                                    ? 1
                                    : 0,
                                status: 1,
                              },
                              nodes
                            );
                            setStatusOrder(false);
                            setOn(true);
                            setOff(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={switchOn}
                          />
                          <span className="listpopup__block__text">
                            {t("ON")}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            changeState(
                              {
                                connection:
                                  !connected && !disconnected
                                    ? null
                                    : connected && !disconnected
                                    ? 1
                                    : 0,
                                status: 0,
                              },
                              nodes
                            );
                            setStatusOrder(false);
                            setOn(false);
                            setOff(true);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={switchOff}
                          />
                          <span className="listpopup__block__text">
                            {t("OFF")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ width: 160, position: "relative" }}>
                  <TextSpan fontWeight="700"> {t(txt.gateway)}</TextSpan>
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setAlarmOrder(!alarmOrder);
                      makeMenusVisible(
                        "alarm",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">
                      {t(txt.alarmsCapitalLetter)}
                    </TextSpan>
                    {clear ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseGreen}
                      />
                    ) : moderate ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseYellow}
                      />
                    ) : critical ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseRed}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {alarmOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            //to develop
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseGreen}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.clear)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            //to develop
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseYellow}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.moderate)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            //to develop
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseRed}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.critical)}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ cursor: "pointer", width: 160, position: "relative" }}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    className="icon-class"
                    onClick={() => {
                      setGroupsOrder(!groupsOrder);
                      makeMenusVisible(
                        "groups",
                        setNameOrder,
                        setConnectionOrder,
                        setStatusOrder,
                        setAlarmOrder,
                        setGroupsOrder,
                        setDeviceOrder
                      );
                    }}
                  >
                    <TextSpan fontWeight="700">{t("Groups")}</TextSpan>
                    {groupsAZ ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={AZ}
                      />
                    ) : groupsZA ? (
                      <IconStyled
                        style={{ marginRight: -11, width: "16px", height: "16px" }}
                        src={ZA}
                      />
                    ) : groups ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseYellow}
                      />
                    ) : (
                      <></>
                    )}
                    <IconStyled src={doubleArrow} />
                  </div>
                  {groupsOrder && (
                    <article>
                      <ListPopUp style={{ right: 46, top: 30, position: "absolute" }}>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            resetFilters();
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, width: "13px", height: "13px" }}
                            src={clearFiltersLight}
                          />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t(txt.clearFilter)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groupsAZ",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={AZ}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromAToZ)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            changeOrder(list, 2, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groupsZA",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px" }}
                            src={ZA}
                          />
                          <span className="listpopup__block__text">
                            {t(txt.fromZToA)}
                          </span>
                        </div>
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            changeOrder(list, 1, nameOrder, groupsOrder);
                            makeIconsVisible(
                              "groups",
                              setNameAZ,
                              setNameZA,
                              setConnected,
                              setDisconnected,
                              setOn,
                              setOff,
                              setClear,
                              setCritical,
                              setModerate,
                              setGroupsAZ,
                              setGroupsZA,
                              setGroups,
                              setLuminaire,
                              setGateway
                            );
                            setGroupsOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10 }}
                            src={ellipseYellow}
                          />
                          <span className="listpopup__block__text">
                            {t("Groups")}
                          </span>
                        </div>
                      </ListPopUp>
                    </article>
                  )}
                </th>
                <th scope="col" style={{ textAlign: "center", width: "34px" }}>
                  <img
                    src={Options}
                    height={12}
                    style={{ cursor: "pointer" }}
                    alt={"options"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((device: any) => (
                <>
                  <tr>
                    <Space />
                  </tr>
                  <tr key={device.node_id}>
                    <th scope="row">
                      <input
                        type="checkbox"
                        checked={device.selected === true ? true : false}
                        className="form-check-input"
                        id={`rowcheck${device.node_id}`}
                        onChange={(e: any) => onItemCheck(e, device, list)}
                        style={{ cursor: "pointer", width: "16px", height: "16px" }}
                      />
                    </th>
                    <td>
                      <TextSpan>{device.name}</TextSpan>
                    </td>
                    <td>
                      <TextSpan>{t(txt.luminaire)}</TextSpan>
                    </td>
                    <td>
                      {device.online === 0 ? (
                        <>
                          <IconStyled src={disconnectedIcon} />
                          <TextSpan> {t("Disconnected")}</TextSpan>
                        </>
                      ) : (
                        <>
                          <IconStyled src={connectedIcon} />
                          <TextSpan> {t("Connected")}</TextSpan>
                        </>
                      )}
                    </td>
                    <td>
                      {device.online === 0 ? (    
                        <SwitchComponent
                          checkedChildren="On"
                          unCheckedChildren="Off"
                          checked={false}
                          disabled
                          onClick={() => handleClickSingle(device, 1)}
                        />
                      ) : (device.on === 0 ? (
                          <SwitchComponent
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={false}
                            style={{background: "#475569", opacity: 0.9 }}
                            onClick={() => handleClickSingle(device, 1)}
                          />
                        ) : (
                          <ThemeProvider theme={theme}>
                            <SwitchComponent
                              checked
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              onClick={() => handleClickSingle(device, 2)}
                            />
                          </ThemeProvider>
                        )
                      )}
                    </td>
                    <td>
                      <TextSpan>{t(txt.none)}</TextSpan>
                    </td>
                    <td>
                      <>
                        {device.alarm_status === 0 ? (
                          <>
                            <IconStyled src={ellipseGreen} />
                            <TextSpan>{t(txt.clear)}</TextSpan>
                          </>
                        ) : device.alarm_status === 1 ? (
                          <>
                            <IconStyled src={ellipseRed} />
                            <TextSpan> {t(txt.critical)}</TextSpan>
                          </>
                        ) : device.alarm_status === 2 ? (
                          <>
                            <IconStyled src={ellipseYellow} />
                            <TextSpan>{t(txt.moderate)}</TextSpan>
                          </>
                        ) : (
                          <>
                            <IconStyled src={ellipseGreen} />
                            <TextSpan>{t(txt.clear)}</TextSpan>
                          </>
                        )}
                      </>
                    </td>
                    {/* <td>
                      <TextSpan>
                        {device.groups.map((id:any) => {
                          let device = groupsList.find((item:any) => {
                            return item.groupId.toString() === id
                        });
                          return device ? device.groupName : '';
                        }).join(', ')}
                      </TextSpan>
                    </td> */}
                    <td>
                      <TextSpan>
                        {
                          (() => {
                            let deviceGroupNames = device.groups
                              .map((id: any) => {
                                let device = groupsList.find((item: any) => {
                                  return item.groupId.toString() === id;
                                });
                                return device ? device.groupName : "";
                              });

                            if (deviceGroupNames.length > 0) {
                              const itemsToShow = deviceGroupNames.slice(0, 2);
                              const hasMoreItems = deviceGroupNames.length > 2;
                              return (
                                <>
                                  {itemsToShow.map((groupName: string) =>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        gap: "6px",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        padding: "4px 12px",
                                        borderRadius: "2px",
                                        background: "#E8F3FC",
                                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                                        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                      }}
                                    >
                                      <TextSpan>{groupName}</TextSpan>
                                    </div>
                                  )}
                                  {hasMoreItems && 
                                    <div
                                      title={deviceGroupNames.slice(2).join(', ')}
                                      style={{
                                        display: "inline-block",
                                        gap: "6px",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        padding: "4px 12px",
                                        borderRadius: "2px",
                                        background: "#E8F3FC",
                                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                                        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                      }}
                                    >
                                      <TextSpan>...</TextSpan>
                                    </div>
                                  }
                                </>
                              );
                            } else {
                              return (
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                  <Text>{t("noGroupAssigned")}</Text>
                                </div>
                              )
                            }
                          })()
                        }
                      </TextSpan>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={Arrow_right_full}
                        height={12}
                        alt={"arrow right"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickViewDetails(device);
                        }}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Scroll>
    </>
  );
};
