import styled from "styled-components";

export const FilterByGroupStyled = styled.section`
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.contentFontColor};
  justify-content: space-between;
  align-items: center;
  min-width: 260px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.3);
  z-index: 1;

  .filter {
    &__title-container {
      padding: 6.4px 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: inherit;
      cursor: pointer;
    }
  }

  .title-container {
    &__title {
      margin: 0;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #475569;
    }

    &__icon {
      color: inherit;
      font-weight: 400;
    }
  }
`;
