import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { RootState, store } from "../../redux/store";
import { fetchAlarms } from "../../redux/actions/alarmsActions/alarmsActions";
import {
  changeSideMenuActionCreator,
  setAlarmsVisibleActionCreator,
  setGroupsVisibleActionCreator,
  setEventsVisibleActionCreator,
  setIsFullViewActionCreator,
  setFirstRenderMapToTrueActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  turnRgbFalseActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { useAlarmsService } from "../../services/alarmService";
import { useStrategiesService } from "../../services/strategiesService";
import { Header } from "../Header/Header";
import { languages } from "../../utils/languages/languages";
import {
  ContainerMain,
  Sidemenu,
  SidemenuLogo,
  SidemenuTopIcon,
  SidemenuIcon,
  SidemenuDownIcon,
  Headertest,
  ContainerChildren,
  ChildrenComponent,
  HeadertestFullView,
  ContainerChildrenFullView,
} from "./LayoutStyled";
import alarm from "../../assets/new_icons/Alarm layout.svg";
import strategies from "../../assets/new_icons/big-point layout.svg";
import chevronLeft from "../../assets/chevron-left.svg";
import chevronRight from "../../assets/arrow_forward_outline.svg";
import defaultProfile from "../../assets/default-profile.png";
import events from "../../assets/new_icons/clock layout.svg";
import grid from "../../assets/new_icons/grid.svg";
import groups from "../../assets/new_icons/Groups layout.svg";
import moon from "../../assets/moon.svg";
import SmartecSmallLogo from "../../assets/S-Smartec.svg";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  style?: object;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  const alerts = false;
  const publicTaskMgmtUrl: string = process.env
    .REACT_APP_TASKMGMT_URL as string;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getStrategies } = useStrategiesService();
  const { getAlarms } = useAlarmsService();
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const isSideMenuVisible = useAppSelector(
    (state) => state.ui.isSideMenuVisible
  );
  const isHomeVisible = useAppSelector((state) => state.ui.isHomeVisible);
  const isAlarmsVisible = useAppSelector((state) => state.ui.isAlarmsVisible);
  const isGroupsVisible = useAppSelector((state) => state.ui.isGroupsVisible);
  const isStrategiesVisible = useAppSelector(
    (state) => state.ui.isStrategiesVisible
  );
  const isEventsVisible = useAppSelector((state) => state.ui.isEventsVisible);
  const user = useAppSelector((state: RootState) => state.user);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const [dashboardOpacity, setDashboardOpacity]: any = useState(
    isHomeVisible ? 1 : 0.2
  );
  const [alarmsOpacity, setAlarmsOpacity] = useState(isAlarmsVisible ? 1 : 0.2);
  const [groupsOpacity, setGroupsOpacity] = useState(isGroupsVisible ? 1 : 0.2);
  const [strategiesOpacity, setStrategiesOpacity] = useState(
    isStrategiesVisible ? 1 : 0.2
  );
  const [eventsOpacity, setEventsOpacity] = useState(isEventsVisible ? 1 : 0.2);
  const [moonOpacity, setMoonOpacity] = useState(0.2);
  const [loading, setLoading] = useState(false);

  const closePopUps = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
  };

  const goToAlarms = async () => {
    try {
      const token: any = Cookies.get("token");
      setLoading(true);
      let response = await getAlarms(
        selectedOrgId,
        selectedServiceId,
        token,
        setLoading
      );
      if (response) {
        store.dispatch(fetchAlarms(response));
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDashboardOpacity(isHomeVisible ? 1 : 0.2);
    setAlarmsOpacity(isAlarmsVisible ? 1 : 0.2);
    setGroupsOpacity(isGroupsVisible ? 1 : 0.2);
    setStrategiesOpacity(isStrategiesVisible ? 1 : 0.2);
    setEventsOpacity(isEventsVisible ? 1 : 0.2);
  }, [
    isHomeVisible,
    isAlarmsVisible,
    isGroupsVisible,
    isStrategiesVisible,
    isEventsVisible,
  ]);

  useEffect(() => {
    const isLoggedIn = Cookies.get("isLoggedIn");
    console.log(isLoggedIn, "asdfasdfasdf");

    if (!isLoggedIn) {
      navigate("/");
    }
  }, []);
  //-----------------------------

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n]);

  return (
    <ContainerMain>
      {isSideMenuVisible ? (
        <>
          <Sidemenu>
            <SidemenuLogo
              title={t(txt.dashboard)}
              onClick={async () => {
                closePopUps();
                dispatch(setIsFullViewActionCreator());
                dispatch(setFirstRenderMapToTrueActionCreator());
                dispatch(turnRgbFalseActionCreator());
                navigate("/dashboard");
              }}
              style={{ cursor: "pointer" }}
              src={SmartecSmallLogo}
            />
            <SidemenuTopIcon>
              <SidemenuIcon
                title={t(txt.dashboard)}
                onClick={() => {
                  dispatch(turnRgbFalseActionCreator());
                  dispatch(setIsFullViewActionCreator());
                  dispatch(setFirstRenderMapToTrueActionCreator());
                  navigate("/dashboard");
                }}
                style={{ cursor: "pointer", opacity: dashboardOpacity }}
                src={grid}
                onMouseEnter={() =>
                  !isHomeVisible ? setDashboardOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isHomeVisible ? setDashboardOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t(txt.alarmsCapitalLetter)}
                onClick={async () => {
                  closePopUps();
                  /* const token:any = Cookies.get("token");
                  await getAlarms(selectedOrgId, selectedServiceId, token, setLoading); */
                  await goToAlarms();
                  dispatch(setAlarmsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/alarms");
                }}
                style={{ cursor: "pointer", opacity: alarmsOpacity }}
                src={alarm}
                onMouseEnter={() =>
                  !isAlarmsVisible ? setAlarmsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isAlarmsVisible ? setAlarmsOpacity(0.2) : <></>
                }
              />
              {/* <SidemenuIcon
                title={t("Groups")}
                onClick={() => {
                  closePopUps();
                  dispatch(setGroupsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/disabled-demo-mode");
                }}
                style={{ cursor: "pointer", opacity: groupsOpacity }}
                src={groups}
                onMouseEnter={() =>
                  !isGroupsVisible ? setGroupsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isGroupsVisible ? setGroupsOpacity(0.2) : <></>
                }
              />*/}
              <SidemenuIcon
                title={t("Groups")}
                onClick={() => {
                  closePopUps();
                  dispatch(setGroupsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  window.location.href = `${publicTaskMgmtUrl}`;
                }}
                style={{ cursor: "pointer", opacity: groupsOpacity }}
                src={groups}
                onMouseEnter={() =>
                  !isGroupsVisible ? setGroupsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isGroupsVisible ? setGroupsOpacity(0.2) : <></>
                }
              />
              <SidemenuIcon
                title={t(txt.strategies)}
                onClick={async () => {
                  closePopUps();
                  await getStrategies(selectedOrgId, selectedServiceId);
                  dispatch(setIsFullViewActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/strategies");
                }}
                style={{ cursor: "pointer", opacity: strategiesOpacity }}
                src={strategies}
                onMouseEnter={() =>
                  !isStrategiesVisible ? setStrategiesOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isStrategiesVisible ? setStrategiesOpacity(0.2) : <></>
                }
              />
              {/* <SidemenuIcon
                title={t(txt.events)}
                onClick={() => {
                  closePopUps();
                  dispatch(setEventsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/events");
                }}
                style={{ cursor: "pointer", opacity: eventsOpacity }}
                src={events}
                onMouseEnter={() =>
                  !isEventsVisible ? setEventsOpacity(1) : <></>
                }
                onMouseLeave={() =>
                  !isEventsVisible ? setEventsOpacity(0.2) : <></>
                }
              /> */}
            </SidemenuTopIcon>
            <SidemenuDownIcon>
              {/* <SidemenuIcon title={txt.darkLight} src={moon} style={{ cursor: "pointer", opacity: moonOpacity }}
                onMouseEnter={() => moonOpacity === 0.2 ? setMoonOpacity(1) : <></>} onMouseLeave={() => moonOpacity === 1 ? setMoonOpacity(0.2) : <></>}
              /> */}
              <SidemenuIcon
                title={t(txt.expandDecrease)}
                src={chevronLeft}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  closePopUps();
                  dispatch(changeSideMenuActionCreator());
                }}
              />
            </SidemenuDownIcon>
          </Sidemenu>
          <Headertest>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
            ></Header>
          </Headertest>
          <ContainerChildren>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildren>
        </>
      ) : (
        <>
          <SidemenuDownIcon
            style={{ height: "108px", left: "-18px", zIndex: 3 }}
          >
            <SidemenuIcon
              src={chevronRight}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                background: "white",
                cursor: "pointer",
                padding: "17px",
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}
              onClick={() => {
                closePopUps();
                dispatch(changeSideMenuActionCreator());
              }}
            />
          </SidemenuDownIcon>
          <HeadertestFullView>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
            ></Header>
          </HeadertestFullView>
          <ContainerChildrenFullView style={{ paddingLeft: "30px" }}>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildrenFullView>
        </>
      )}
    </ContainerMain>
  );
};
