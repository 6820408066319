import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import InputForm from "../Input/Input";
import { languages } from "../../utils/languages/languages";
import {
  ButtonLogin,
  Container,
  ImageLogo,
  LoginForm,
  LoginOverlay,
  LoginOverlayWhite,
} from "../../pages/Login/LoginStyled";
import { Text } from "../../styles/shared-styles/text-sizes";
import chevronLeft from "../../assets/chevron-left.svg";
import envelope_icon from "../../assets/mail.svg";
import logo from "../../assets/smartec-logo.svg";
import { useTranslation } from "react-i18next";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            <LoginOverlay className="overlay" />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              <LoginForm padding="large" className="card" borderRadius="large">
                <div
                  className="col-12"
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <img
                    src={chevronLeft}
                    style={{
                      position: "absolute",
                      top: "-29vh",
                      bottom: "10vh",
                      left: "-21vh",
                      width: "50px",
                      height: "50px",
                      cursor: "pointer",
                      borderRadius: "50%",
                      background: "white",
                      padding: "17px",
                      boxShadow:
                        "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  <ImageLogo src={logo} />
                </div>
                <div className="col-12 text-left">
                  <Text textType="title" fontWeight="700" marginBottom="10px">
                    {t(txt.forgotPassword)}
                  </Text>
                  <Text fontWeight="400" marginBottom="28px" color="#475569">
                    {t(txt.forgotPasswordText)}
                  </Text>
                  <InputForm
                    preffix_icon={envelope_icon}
                    inputType="email"
                    placeholder={t(txt.email)}
                  />
                </div>
                <div className="col-12 text-left">
                  <ButtonLogin buttonType="primary">
                    {t(txt.resetInstructions)}
                  </ButtonLogin>
                </div>
              </LoginForm>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
