import { useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { saveUserPersonalInfoFromUsersListActionCreator } from "../../../redux/slices/managedUsers/managedUserSlice";
import { UserAvatar } from "../../../utils/reusableComponents";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import personOutlined from "../../../assets/person_outline.svg";

interface UsersListProps {
  users: any;
}

export const UsersList = ({ users }: UsersListProps) => {
  const dispatch = useAppDispatch();
  const [selectedUserId, setSelectedUserId] = useState("");
  
  const UserItem = ({ item }:any) => {
    const [isHovered, setIsHovered] = useState(false);
    
    let divStyle = { 
      display: "flex",
      justifyContent: "space-between",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #E7E0EC",
      cursor: "pointer",
      backgroundColor: isHovered ? "#f1f1f1" : item.id === selectedUserId ? "#f1f1f1" : ""
    };
  
    return (
      <div 
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={() => {dispatch(saveUserPersonalInfoFromUsersListActionCreator(item)); setSelectedUserId(item.id)}}
        style={divStyle}
      >
       <div style={{ display: "flex", gap: "14px", paddingLeft: "6px" }}>
          <UserAvatar firstName={item.name} profileColor={item?.color} type={"list"} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <TextSpan color="#475569" fontWeight="700">
              {item.name} {item.surname}
            </TextSpan>
            <TextSpan color="#a3aed0" textType="caption">
              {item.email}
            </TextSpan>
          </div>
        </div>
        <TextSpan color="#a3aed0" textType="caption" style={{ alignSelf: "flex-end", paddingRight: "10px" }}>
          {item.role} <img src={personOutlined} height={12} />
        </TextSpan> 
      </div>
    );
  };

  return (
    <div>
      {users.map((item:any, index:number) => (
        <UserItem item={item} key={item.id} />
      ))}
    </div>
  );
};
