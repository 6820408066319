import { useRef, useEffect } from 'react';
import { useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { languages } from "../../../utils/languages/languages";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);

function ElectricalParametersChart() {
  const current = useAppSelector((state) => state.electricParameters.current);
  const currentHours = useAppSelector((state) => state.electricParameters.currentTimestamps);
  const voltage = useAppSelector((state) => state.electricParameters.voltage);
  const voltageHours = useAppSelector((state) => state.electricParameters.voltageTimestamps);
  const temperature = useAppSelector((state) => state.electricParameters.temperature);
  const temperatureHours = useAppSelector((state) => state.electricParameters.temperatureTimestamps);
  const apparentPower = useAppSelector((state) => state.electricParameters.apparentPower);
  const apparentPowerHours = useAppSelector((state) => state.electricParameters.apparentPowerTimestamps);
  const activePower = useAppSelector((state) => state.electricParameters.activePower);
  const activePowerHours = useAppSelector((state) => state.electricParameters.activePowerTimestamps);
  const frequency = useAppSelector((state) => state.electricParameters.frequency);
  const frequencyHours = useAppSelector((state) => state.electricParameters.frequencyTimestamps);
  const apparentEnergy = useAppSelector((state) => state.electricParameters.apparentEnergy);
  const apparentEnergyHours = useAppSelector((state) => state.electricParameters.apparentEnergyTimestamps);
  const activeEnergy = useAppSelector((state) => state.electricParameters.activeEnergy);
  const activeEnergyHours = useAppSelector((state) => state.electricParameters.activeEnergyTimestamps);
  const powerGridVoltage = useAppSelector((state) => state.electricParameters.powerGridVoltage);
  const powerGridVoltageHours = useAppSelector((state) => state.electricParameters.powerGridVoltageTimestamps);
  const chartRef:any = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false, // Desactiva el salto automático de etiquetas
        }
      }
    }
  };
  
  const data = {
    /* labels: [
      "12:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "16:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "20:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "00:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "04:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "08:00", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "", "", "", "", "", "", "", "", "", "", 
      "12:00"
    ], */
    /* labels: [
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "16:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "20:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00",
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "16:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "20:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "20:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", 
      "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00", "12:00" ], */
    labels: currentHours || voltageHours || temperatureHours || apparentPowerHours || activePowerHours || frequencyHours || apparentEnergyHours || activeEnergyHours || powerGridVoltageHours,
    datasets: [{
      fill: true,
      data: current || voltage || temperature || apparentPower || activePower || frequency || apparentEnergy || activeEnergy || powerGridVoltage,
      lineTension: 0.4,
      pointRadius: 0,
      backgroundColor: (context:any) => {
        const chart = context.chart;
        const ctx = chart.canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        if (currentHours) {
          gradient.addColorStop(0, "#00C7F2");
          gradient.addColorStop(0.2, "#00C7F280");
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (voltageHours) {
          gradient.addColorStop(0, "#CE2A96");
          gradient.addColorStop(0.2, "#CE2A9680")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (temperatureHours) {
          gradient.addColorStop(0, "#F67878");
          gradient.addColorStop(0.2, "#F6787880")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (apparentPowerHours) {
          gradient.addColorStop(0, "#6C60FF");
          gradient.addColorStop(0.2, "#6C60FF80")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (activePowerHours) {
          gradient.addColorStop(0, "#B3261E");
          gradient.addColorStop(0.2, "#B3261E80")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (frequencyHours) {
          gradient.addColorStop(0, "#FB9B2B");
          gradient.addColorStop(0.2, "#FB9B2B80")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (apparentEnergyHours) {
          gradient.addColorStop(0, "#0FCA7A");
          gradient.addColorStop(0.2, "#0FCA7A80")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (activeEnergyHours) {
          gradient.addColorStop(0, "#475569");
          gradient.addColorStop(0.2, "#47556980")
          gradient.addColorStop(1, "#FFFFFF80");
        } else if (powerGridVoltageHours) {
          gradient.addColorStop(0, "#B3A77D");
          gradient.addColorStop(0.2, "#B3A77D80")
          gradient.addColorStop(1, "#FFFFFF80");
        }
        return gradient;
      },
      /* backgroundColor: : : activeEnergyHours ? "#47556980" : "#B3A77D80" */
    }]
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;

      chartInstance.data = data;
      chartInstance.options = options;

      chartInstance.update();
    }
  }, [data, options]);

  return (//294     252      206
    <Line width={900} height={206} options={options} data={data} />
  )
}

export default ElectricalParametersChart;
