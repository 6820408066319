import styled from "styled-components";

export const ButtonSolvedSytled = styled.button`
  padding: 8px 32px;
  background-color: #fbbc05;
  border: 1px solid #fbbc05;
  border-radius: 4px;
  color: #1b2559;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px #0000004d;
`;
