import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import { fetchAlarmsFromNode } from "../../redux/actions/alarmsActions/alarmsActions";
import { resetButtonsActionCreator, resetParametersActionCreator } from "../../redux/slices/electricParametersSlice/electricParametersSlice";
import { changeBackgroundDashboardActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../redux/slices/mqttSlice/mqttSlice";
import { setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setIsStaticViewActionCreator, setSelectedRGBNodeActionCreator, setViewsToFalseActionCreator
  } from "../../redux/slices/uiSlice/uiSlice";
  import { resetSolarParametersActionCreator } from "../../redux/slices/solarParametersSlice/solarParametersSlice";
import { Lamp as NodeDevice } from "../../types/interfaces";
import { useAlarmsService } from "../../services/alarmService";
import { useLightingService } from "../../services/lightingService";
import { useStrategiesService } from "../../services/strategiesService";
import { initialLamp, initialLampsGroup } from "../../utils/data";
import { googleIcons } from "../../utils/googleFontsIcons/icons";
import { languages } from "../../utils/languages/languages";
import { DetailPopupStyled, BatteryLevel, DetailPopupHybridViewStyled } from "./DetailPopUpStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";

interface DetailPopupProps {
  node: any;
  setSelectedMarker: React.Dispatch<React.SetStateAction<string>>;
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
}

export const DetailPopup = ({ node, setSelectedMarker, setNodesGroup }: DetailPopupProps) => {
  const fatherHeight = document
    .getElementById("map-container")
    ?.getBoundingClientRect().height!;
  const fatherWidth = document
    .getElementById("map-container")
    ?.getBoundingClientRect().width!;

  const dispatch = useAppDispatch();
  const { sendCommandToLuminaire, getNodeInfo, getRgbNodeInfo } = useLightingService();
  const { getStrategies } = useStrategiesService();
  const { getNodeAlarms } = useAlarmsService();
  const navigate = useNavigate();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const mqttError = useAppSelector((state) => state.mqtt.error);
  const isRgbVisible = useAppSelector((state) => state.ui.rgb);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const {
    close,
    move,
    error,
    lightBulb,
    connection,
    disconnection,
    arrowForward,
    location,
    sadFace,
  } = googleIcons;

  const customWidth = fullView ? 420 : 340;
  const customHeight = fullView ? 228 : 50;

  const initialPosition = {
    x: fullView
      ? fatherWidth! - customWidth - 45 ?? 50
      : fatherWidth! - customWidth - 35 ?? 50,
    y: fullView
      ? fatherHeight! / 2 - customHeight / 2 ?? 50
      : fatherHeight! / 16.8 - customHeight / 10 ?? 100,
  };

  const position = ` ${node?.latitude}, 
    ${node?.longitude}`;

  /*const initialPosition = {
    x: fatherWidth! - customWidth - 45 ?? 50,
    y: fatherHeight! / 2 - customHeight / 2 ?? 50
  };*/

  const [newPosition, setNewPosition] = useState(initialPosition);
  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  const [color, setColor] = useState("#56c568");
  const [bulkActions, setBulkActions] = useState(false);

  const handleDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.setProperty("display", "none");
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const startX = event.clientX - newPosition.x;
    const startY = event.clientY - newPosition.y;
    setOrigin({ x: startX, y: startY });
  };

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    let x = event.clientX - origin.x;
    const y = event.clientY - origin.y;
    event.currentTarget.style.setProperty("display", "block");
    if (
      x > fatherWidth - customWidth ||
      y > fatherHeight - customHeight ||
      x < 0 ||
      y < 0
    ) {
      alert("Out of the map");

      return;
    }

    setNewPosition({ x, y });
  };

  const handleClick = async (order: number) => {
    //0 to off 1 to on
    try {
      let result: any = sendCommandToLuminaire([node], order, 0, 0);
      //      console.log("e")
      //      console.log(result[0][0])
      //      setStatus(result[0][0].result);
    } catch (error) {
      alert(error);
    }
  };

  const handleClose = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(setIsStaticViewActionCreator(true));
    dispatch(errorToInitialStateActionCreator());
    setSelectedMarker(initialLamp.node_id);
    setNodesGroup(initialLampsGroup.group);
  };

  const handleClickViewDetails = async () => {
    handleClose();
    let viewState = "";
    if (fullView) {
      viewState = "full";
    } else if (hybridView) {
      viewState = "hybrid";
    }
    if (node.rgb === "Y") {
      await getRgbNodeInfo(node.node_id);
    } else {
      await getNodeInfo(node.node_id);
      await getStrategies(selectedOrgId, selectedServiceId);
      try {
        const token:any = Cookies.get("token");
        let response = await getNodeAlarms(selectedOrgId, selectedServiceId, node.node_id, token)
        if (response) {
          store.dispatch(fetchAlarmsFromNode(response));
        }
      } catch (error) {
        console.error("Error fetching alarm data:", error);
      }
      dispatch(resetParametersActionCreator());
      dispatch(resetButtonsActionCreator());
      dispatch(resetSolarParametersActionCreator());
    }
    dispatch(setViewsToFalseActionCreator());
    navigate(`/luminaire/${node.node_id}`, { state: { viewState } });
  };

  const { t } = useTranslation();

  const rgbToHex = (r: number, g: number, b: number) => {
    return "#" + [r, g, b].map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }).join('');
  }

  useEffect(() => {
    if (isRgbVisible) {
      if (node.online === 0) {
        setColor("rgb(229, 229, 229)")
      } else if (node.on === 1) {
        if (node.R.toString() && node.G.toString() && node.B.toString()) {
          const hexColor = rgbToHex(node.R, node.G, node.B)
          setColor(hexColor)
        }
      } else {
        setColor("transparent")
      }
    }
  }, [color, node]);

  return fullView ? (
    <DetailPopupStyled
      id="detail-popup"
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      draggable={true}
      style={{
        position: "absolute",
        height: mqttError
          ? node.solar_id !== null
            ? "312px"
            : "238px"
          : node.solar_id !== null
          ? "301px"
          : customHeight,
        //height: mqttError ? "238px" : node.bri !== null ? "301px" : customHeight,
        width: customWidth,
        left: `${newPosition.x}px`,
        top: `${newPosition.y}px`,
      }}
    >
      <div className="popup__button-container">
        <span className="material-symbols-rounded popup__button-container__icon move">
          {move}
        </span>
        <span
          onClick={handleClose}
          className="material-symbols-rounded popup__button-container__icon"
        >
          {close}
        </span>
      </div>
      <h1 className="popup__title">{node.name}</h1>
      <div className="popup__details-container">
        <div className="details-container__details">
          <span
            className={`material-symbols-rounded details-container__icon ${
              node.online ? "details-container__icon--on" : ""
            }`}
          >
            {node.online ? connection : disconnection}
          </span>{" "}
          <span>{node.online ? t("Connected") : t("Disconnected")}</span>
        </div>
        <div className="details-container__details">
          <span className="material-symbols-rounded details-container__icon">
            {location}
          </span>{" "}
          <span>{position}</span>
        </div>
        <div className="details-container__details">
          <span className="material-symbols-rounded details-container__icon details-container__icon--alarm">
            {error}
          </span>{" "}
          <span>{node.alarm_status != 0 ? 1 : 0}</span>
        </div>
      </div>
      {node.solar_id !== null ? (
        <BatteryLevel>
          <TextBox>
            {t("Battery")} {node.batt_level}%
          </TextBox>
          <input
            type="range"   
            min="1"
            max="100"
            value={node.batt_level}
            className="battery__progress-bar"
            style={{ background: `linear-gradient(to right, #56C568 ${node.batt_level}%, #d2d4d9 ${node.batt_level}%)` }}
          />
          <div className="battery__percentages">
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming1}
            </TextBox>
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming50}
            </TextBox>
            <TextBox textType="caption" color="#A3AED0">
              {txt.dimming100}
            </TextBox>
          </div>
        </BatteryLevel>
      ) : (
        <></>
      )}
      <div className="popup__status-container">
        <span className="material-symbols-rounded status-container__icon">
          {lightBulb}
        </span>
        <span className="status-container__content">{t("Status")}</span>
      </div>
      <div style={{ paddingRight: "15px", paddingLeft: "18px" }}>
        <div className="popup__onoff-container">
          {isRgbVisible ?
            <>
              <button
                onClick={() => handleClick(41)}
                disabled={node.online === 0 ? true : false}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--off-disabled"
                    : node.on === 0
                    ? "onoff-container__button onoff-container__button--off"
                    : "onoff-container__button"
                }
              >
                {t("OFF")}
              </button>
              <button
                onClick={() => { dispatch(setSelectedRGBNodeActionCreator(node)); dispatch(changeBackgroundDashboardActionCreator()) }}
                disabled={node.online === 0 ? true : false}
                style={{ background: isRgbVisible && color }}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--on-disabled"
                    : node.on === 1
                    ? "onoff-container__button onoff-container__button--on"
                    : "onoff-container__button"
                }
              >
                {t("ON")}
              </button>
            </>
          :
            <>
              <button
                onClick={() => handleClick(2)}
                disabled={node.online === 0 ? true : false}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--off-disabled"
                    : node.on === 0
                    ? "onoff-container__button onoff-container__button--off"
                    : "onoff-container__button"
                }
              >
                {t("OFF")}
              </button>
              <button
                onClick={() => handleClick(1)}
                disabled={node.online === 0 ? true : false}
                style={{ background: isRgbVisible && color }}
                className={
                  node.online === 0
                    ? "onoff-container__button onoff-container__button--on-disabled"
                    : node.on === 1
                    ? "onoff-container__button onoff-container__button--on"
                    : "onoff-container__button"
                }
              >
                {t("ON")}
              </button>
            </>
          }
        </div>
        {/* commented code, we will manage this error in a different way */}
        {/* {mqttError && (
          <div className="popup__message-container">
            <span
              className="material-symbols-rounded details-container__icon"
              style={{ color: "#CF2A2A", marginRight: "5px" }}
            >
              {sadFace}
            </span>
            <TextSpan color="#CF2A2A">{t(txt.errorConnection)}</TextSpan>
          </div>
        )} */}
      </div>
      <div
        className="popup__link-container"
        onClick={() => {
          handleClickViewDetails();
        }}
      >
        <span className="link-container__link">{t("View Details")}</span>
        <span className="material-symbols-rounded">{arrowForward}</span>
      </div>
    </DetailPopupStyled>
  ) : (
    <DetailPopupHybridViewStyled
      style={{
        position: "absolute",
        height: mqttError ? "238px" : customHeight,
        width: customWidth,
        left: `${newPosition.x}px`,
        top: `${newPosition.y}px`,
      }}
    >
      <div className="hybridpopup__block">
        <h1 className="hybridpopup__block__title">{node.name}</h1>
        <div className="hybridpopup__block__position">
          <span className="material-symbols-rounded hybridpopup__block__position--icon">
            {location}
          </span>
          <span className="hybridpopup__block__position--text">{position}</span>
        </div>
        <span
          onClick={handleClose}
          className="material-symbols-rounded hybridpopup__block__close"
        >
          {close}
        </span>
      </div>
      <div
        className="hybridpopup__bulk"
        onClick={() => setBulkActions(!bulkActions)}
      >
        <span className="material-symbols-rounded hybridpopup__bulk__icon">
          {googleIcons.add}
        </span>
      </div>
      {bulkActions && (
        <article className="hybridpopup__bulk__container">
          <span
            onClick={() => (node.online === 1 ? handleClick(1) : <></>)}
            id="on"
            className={
              node.online === 0
                ? "hybridpopup__bulk__container--action-disabled"
                : "hybridpopup__bulk__container--action"
            }
          >
            {t("ON")}
          </span>
          <span
            onClick={() => (node.online === 1 ? handleClick(2) : <></>)}
            id="off"
            className={
              node.online === 0
                ? "hybridpopup__bulk__container--action-disabled"
                : "hybridpopup__bulk__container--action"
            }
          >
            {t("OFF")}
          </span>
        </article>
      )}
    </DetailPopupHybridViewStyled>
  );
};
