import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import { LatLng } from "leaflet";
import { CustomMapStyled } from "./CustomMapStyled";

interface CustomMapProps {
  center: LatLng;
  mapSize: React.CSSProperties;
}

export const SillyMap = ({
  center,
  mapSize,
}: CustomMapProps) => {
  return (
    <CustomMapStyled id="map-container" style={mapSize}>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={5}
        scrollWheelZoom={true}
        maxZoom={18}
        minZoom={2}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer
          key={"map layer"}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
      </MapContainer>
    </CustomMapStyled>
  );
};
