import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import Roles from "../Step2Roles/Roles";
import { changeStepActionCreator, registerUserActionCreator, setServicePermitsActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import {
  InputField,
  CardContainer,
  NextButton,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import back from "../../../../assets/new_icons/Back_2.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import Language from "../../../../assets/new_icons/Language.svg";

interface NameProps {
  showDevices?: any;
  goToDevices?: any;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

const ServicePermit = ({
  showDevices,
  goToDevices,
  goToReview,
  errorMessage,
  setErrorMessage,
}: NameProps) => {
  const dispatch = useAppDispatch();
  const groupInfo = useAppSelector((state) => state.groups);
  const { servicePermits, user } = useAppSelector((state) => state.userRegistration)
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const { organizations } = useAppSelector((state) => state.devices);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { t } = useTranslation();

  const [name, setName] = useState(groupInfo.name);

  useEffect(() => {
    if (name) {
      setErrorMessage(false);
    }

    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  const submitService = async () => {
    dispatch(changeStepActionCreator(5))
    dispatch(registerUserActionCreator({service_id: '1'}))
  }

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "55px",
          paddingLeft: "40px",
          marginLeft: "10px",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "-31px",
            marginTop: "-40px",
            marginBottom: "30px",
            fontSize: "12px",
            fontWeight: "400",
            color: "#BDBDBD",
            cursor: "pointer",
          }}
          onClick={() => dispatch(changeStepActionCreator(3))}
        >
          <img src={back} alt="back" style={{ marginRight: "10px" }} />
          <span>Back to Organization Permits</span>
        </div>
        <Text
          fontWeight="500"
          style={{ marginBottom: "4px", fontSize: "14px" }}
        >
          {t("Step 4")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("servicePermit")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("servicePermitDescription")}
        </Text>
      </div>

      <div style={{ width: "80%", margin: "auto" }}>
        <div className="row">

          {organizations
            .filter((permit: any) => user.organization_id === permit.id)
            .map((permit: any) => (
              <div key={permit.id} className="col-6">
                <div
                  className="card px-3 py-3 "
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0 3px 6px #d6d5d1",
                    border: "0px",
                    marginBottom: "33px",
                  }}
                >
                  <div className="d-flex pb-2 mb-2 justify-content-between flex-row align-items-center border-bottom">
                    {/* Display organization name */}
                    <div className="flex-grow-1 mx-4">
                      <h6
                        className="mb-0"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {permit.organization_name}
                      </h6>
                    </div>
                    {/* Display checkbox for all services */}
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id={`checkboxAll-${permit.id}`}
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                        }}
                        checked={true}
                        readOnly
                      />
                      <label
                        className="form-check-label text-base"
                        htmlFor={`checkboxAll-${permit.id}`}
                        style={{ fontSize: "14px", fontWeight: "500" }}
                      >
                        All services
                      </label>
                    </div>
                  </div>
                  {/* Display checkboxes for individual services */}
                  <div className="row">
                    {permit.services.map((service: any) => (
                      <div key={service.id} className="col-16">
                        <div className="form-check form-check-inline mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          <input
                            className="form-check-input org-permit-checkbox"
                            type="checkbox"
                            id={`checkbox-${permit.id}-${service.id}`}
                            value=""
                            style={{
                              height: "18px",
                              width: "18px",
                              border: "2px solid",
                              borderRadius: "3px",
                              marginLeft: "0",
                            }}
                            checked={true}
                            readOnly
                          />
                          <label
                            className="form-check-label ms-2"
                            htmlFor={`checkbox-${permit.orgId}-${service.id}`}
                            style={{ fontSize: "14px", fontWeight: "400" }}
                          >
                            {service.service_name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}

        </div>
      </div>

      <NextButton
        style={{
          justifySelf: "end",
          alignSelf: "center",
          width: "374px",
          marginTop: "100px",
        }}
        onClick={submitService}
      >
        {t("continue")}
      </NextButton>
    </CardContainer>
  );
};

export default ServicePermit;
