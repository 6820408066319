import { store } from "../redux/store";
import { addIdsActionCreator } from "../redux/slices/mqttSlice/mqttSlice";
import {
  saveNodeActionCreator,
  updateSolarConsumsActionCreator,
} from "../redux/slices/nodeSlice/manageNodeSlice";
import {
  loadDevicesActionCreator,
  updateNodeDeviceStatusGlobalActionCreator,
} from "../redux/slices/devices/devicesSlice";
import {
  filteredAllDevicesActionCreator,
  updateNodeDeviceStatusActionCreator,
} from "../redux/slices/devices/filteredDevicesSlice";
import { Devices, NodeDevice, NodeLumimairePage } from "../types/interfaces";

export const useLightingService = () => {
  const webAddressPrefixGetAllNodes: string = process.env
    .REACT_APP_API_GET_ALL_NODES_LOCAL_BASE_URL as string;
  const webAddressPrefixPutCommand: string = process.env
    .REACT_APP_API_PUT_COMMAND_LOCAL_BASE_URL as string;
  const webAddressPrefixGetNodeInfo: string = process.env
    .REACT_APP_API_GET_NODE_INFO_LOCAL_BASE_URL as string;
  const webAddressPrefixGetRgbNodeInfo: string = process.env
    .REACT_APP_API_GET_RGB_NODE_INFO_LOCAL_BASE_URL as string;
  const webAddressPrefixUpdateNode: string = process.env
    .REACT_APP_API_UPDATE_NODE_LOCAL_BASE_URL as string;
  const webAddressPrefixGetSolarConsums: string = process.env
    .REACT_APP_API_GET_SOLAR_NODE_CONSUMPTIONS_LOCAL_BASE_URL as string;

  const getAllNodes = async (
    orgId: number,
    serviceId: number,
    organizations: Array<[]>,
    services: Array<[]>
  ): Promise<boolean> => {
    try {
      const response = await fetch(
        `${webAddressPrefixGetAllNodes}${orgId}&serviceId=${serviceId}`,
        { credentials: "include" }
      );
      const nodes = await response.json();
      /* console.log("los nodos de back", nodes) */

      //este es un approach para intentar hacer gets de lso nodos de forma paginada
      /* let nodeList: any = [];
        let unpositionedNodeList: any = [];
        nodes?.map((node: any, index: any) => { //Le he puesto any porque el modelo no está definido y cambiarlo implica muchas cosas ahora mismo. Hay que hacerlo, pero en otro momento

          console.log('Nodo recibido: ', node);
          let newDevice: NodeDevice = {
            alarm_status: node.alarm_status,
            batt_level: node.batt_level || null,
            bri: Number(node.dimming),
            on: Number(node.is_on), // on-off
            latitude: node.latitude,
            longitude: node.longitude,
            active: node.node_active, // is node active? yes-no
            node_id: node.node_id,
            name: node.node_name,
            orgName: node.organization,
            organizationId: node.organization_id,
            selected: node.selected,
            service: node.service,
            solar_id: node.solar_id, // not solar: no id, is null. Solar: has id
            online: Number(node.wireless_status),
            //SHUNCOM
            addr: node.addr, 
            alarm_id: '',
            gateway_addr: node.gateway_addr,   
            pEnergy: node.active_power, 
            prjName: '',
            projectId: '',
            uid: node.node_id
          };

          if (newDevice.latitude === null && newDevice.longitude === null) {
            unpositionedNodeList.push(newDevice)
          } else {
            nodeList.push(newDevice)
          }

          if (nodes.length > 2499) {
            if (index === 2499) {
              let devices: Devices = {
                gateways: [],
                nodes: nodeList,
                unpositionedNodes: unpositionedNodeList,
                organizations: organizations,
                selectedOrganizationId: orgId,
                services: services,
                selectedServiceId: serviceId
              };
              dispatch(loadDevicesActionCreator(devices));
              dispatch(filteredAllDevicesActionCreator(devices));

              nodeList = [];
              unpositionedNodeList = [];
            } else if ((index + 1) % 2500 === 0) {
              dispatch(saveNodesActionCreator({ nodes: nodeList }));
              dispatch(saveUnpositionedNodesActionCreator({ unpositionedNodes: unpositionedNodeList }));
              dispatch(filteredSaveNodesActionCreator({ nodes: nodeList }));
              dispatch(filteredSaveUnpositionedNodesActionCreator({ unpositionedNodes: unpositionedNodeList }));
              nodeList = [];
              unpositionedNodeList = [];
            } else if (index === nodes.length - 1) {
              dispatch(saveNodesActionCreator({ nodes: nodeList }));
              dispatch(saveUnpositionedNodesActionCreator({ unpositionedNodes: unpositionedNodeList }));
              dispatch(filteredSaveNodesActionCreator({ nodes: nodeList }));
              dispatch(filteredSaveUnpositionedNodesActionCreator({ unpositionedNodes: unpositionedNodeList }));
            }
          } else if (nodes.length < 2499 && index === nodes.length - 1) {
            let devices: Devices = {
              gateways: [],
              nodes: nodeList,
              unpositionedNodes: unpositionedNodeList,
              organizations: organizations,
              selectedOrganizationId: orgId,
              services: services,
              selectedServiceId: serviceId
            };
            dispatch(loadDevicesActionCreator(devices));
            dispatch(filteredAllDevicesActionCreator(devices));
          }
        }); */

      const nodeList: any = [];
      const rgbNodeList: any = [];
      nodes?.map((node: any) => {
        //Le he puesto any porque el modelo no está definido y cambiarlo implica muchas cosas ahora mismo. Hay que hacerlo, pero en otro momento
        /* console.log('Nodo recibido: ', node); */
        console.log("el nodo", node);
        const newDevice: NodeDevice = {
          alarm_status: node.alarm_status,
          bri: Number(node.dimming),
          on: Number(node.is_on), // on-off
          latitude: node.latitude,
          longitude: node.longitude,
          active: node.node_active, // is node active? yes-no
          node_id: node.node_id,
          name: node.node_name,
          orgName: node.organization,
          organizationId: node.organization_id,
          selected: node.selected, //quizás no es necesario, lo inicializo como false en mi lado y ya está
          service: node.service,
          solar_id: node.solar_id, // not solar: no id, is null. Solar: has id
          online: Number(node.wireless_status),
          rgb: node.rgb,
          groups: node.groups || null,
        };

        if (newDevice.rgb === "Y") {
          newDevice.R = node.R;
          newDevice.G = node.G;
          newDevice.B = node.B;
          rgbNodeList.push(newDevice);
        } else {
          if (newDevice.solar_id !== null) {
            newDevice.batt_level = node.batt_level;
          }
          nodeList.push(newDevice);
        }
      });

      const devices: Devices = {
        gateways: [],
        nodes: nodeList,
        rgbNodes: rgbNodeList,
        organizations: organizations,
        selectedOrganizationId: orgId,
        services: services,
        selectedServiceId: serviceId,
      };
      console.log("Devices", devices);

      store.dispatch(loadDevicesActionCreator(devices));
      store.dispatch(filteredAllDevicesActionCreator(devices));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const sendCommandToLuminaire = async (
    luminaires: NodeDevice[],
    commandNumber: number,
    dimming: number,
    color: any
  ) => {
    let luminaireId: String[] = [];
    luminaires.forEach((luminaire) => {
      luminaireId.push(luminaire.node_id);
    });
    store.dispatch(addIdsActionCreator(luminaireId));

    const body =
      commandNumber === 3
        ? {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
              value: dimming,
            },
          }
        : commandNumber === 40
        ? {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
              R: color.r,
              G: color.g,
              B: color.b,
            },
          }
        : commandNumber === 41
        ? {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
            },
          }
        : {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
            },
          };

    try {
      fetch(webAddressPrefixPutCommand, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const getNodeInfo = async (nodeId: string): Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefixGetNodeInfo}${nodeId}`, {
        credentials: "include",
      });
      const node = await response.json();

      const nodeInfo: NodeLumimairePage = {
        case_name: node[0].case_name,
        creation_date: node[0].creation_date,
        strategy_id: node[0].default_strategy_id || null,
        device_type: node[0].device_name,
        bri: node[0].dimming,
        gateway_id: node[0].gateway_id,
        hops: node[0].hops,
        on: node[0].is_on,
        latitude: node[0].latitude,
        longitude: node[0].longitude,
        manufacturer_name: node[0].manufacturer_name,
        active: node[0].node_active,
        node_id: node[0].node_id,
        node_name: node[0].node_name,
        num_neighbours: node[0].num_neighbours,
        protocol_name: node[0].protocol_name,
        rssi: node[0].rssi,
        solar_id: node[0].solar_id,
        traveltime: node[0].traveltime,
        update_date: node[0].update_date,
        online: node[0].wireless_status,
        lifetime: node[0].lifetime,
        humanTime: node[0].humanTime,
        energy_lifetime: node[0].energy_lifetime,
      };

      //Pendiente de parsearlo con shuncom
      if (nodeInfo.bri === undefined || nodeInfo.bri === null) {
        nodeInfo.bri = node[0].bri;
      }

      if (nodeInfo.solar_id !== null) {
        nodeInfo.batt_level = node[0].batt_level;
        nodeInfo.batt_status = node[0].batt_status;
        nodeInfo.sp_power = node[0].sp_power;
        nodeInfo.batt_voltage = node[0].batt_voltage;
        nodeInfo.dc_load_power = node[0].dc_load_power;
        nodeInfo.date = node[0].date;
      }

      store.dispatch(saveNodeActionCreator(nodeInfo));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const getRgbNodeInfo = async (nodeId: string): Promise<boolean> => {
    try {
      const response = await fetch(
        `${webAddressPrefixGetRgbNodeInfo}${nodeId}`,
        { credentials: "include" }
      );
      const node = await response.json();

      const nodeInfo: NodeLumimairePage = {
        case_name: node[0].case_name,
        creation_date: node[0].creation_date,
        device_type: node[0].device_name,
        gateway_id: node[0].gateway_id,
        hops: node[0].hops,
        on: node[0].is_on,
        latitude: node[0].latitude,
        longitude: node[0].longitude,
        manufacturer_name: node[0].manufacturer_name,
        active: node[0].node_active,
        node_id: node[0].node_id,
        node_name: node[0].node_name,
        num_neighbours: node[0].num_neighbours,
        protocol_name: node[0].protocol_name,
        rgb: node[0].rgb,
        R: node[0].R,
        G: node[0].G,
        B: node[0].B,
        rssi: node[0].rssi,
        traveltime: node[0].traveltime,
        update_date: node[0].update_date,
        online: node[0].wireless_status,
      };

      store.dispatch(saveNodeActionCreator(nodeInfo));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const sendCommandToRgbLuminaire = async (
    luminaires: NodeDevice[],
    commandNumber: number,
    color: any
  ) => {
    let luminaireId: String[] = [];
    luminaires.forEach((luminaire) => {
      luminaireId.push(luminaire.node_id);
    });
    store.dispatch(addIdsActionCreator(luminaireId));

    const body =
      commandNumber === 40
        ? {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
              R: color.r,
              G: color.g,
              B: color.b,
            },
          }
        : {
            uid: luminaireId,
            cmd: {
              msg_type: commandNumber,
            },
          };

    try {
      fetch(webAddressPrefixPutCommand, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const updateLatLong = async (
    nodeId: string,
    organizationId: number,
    serviceId: number,
    lat: number,
    long: number
  ) => {
    const body = {
      longitude: long,
      latitude: lat,
    };

    try {
      let response = await fetch(
        `${webAddressPrefixUpdateNode}${nodeId}?orgId=${organizationId}&servId=${serviceId}`,
        {
          method: "PATCH",
          credentials: "include",
          body: JSON.stringify(body),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const getSolarConsums = async (nodeId: string): Promise<boolean> => {
    try {
      const response = await fetch(
        `${webAddressPrefixGetSolarConsums}${nodeId}`,
        { credentials: "include" }
      );
      const node = await response.json();

      const nodeInfo: NodeLumimairePage = {
        batt_level: node.batt_level,
        batt_status: node.batt_status,
        sp_power: node.sp_power,
        batt_voltage: node.batt_voltage,
        dc_load_power: node.dc_load_power,
        date: node.date,
      };

      store.dispatch(updateSolarConsumsActionCreator(nodeInfo));

      return true;
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  return {
    getAllNodes,
    sendCommandToLuminaire,
    getNodeInfo,
    getRgbNodeInfo,
    sendCommandToRgbLuminaire,
    updateLatLong,
    getSolarConsums,
  };
};
