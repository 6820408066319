import { store } from '../../../redux/store';
import { getConsumptionActionCreator, getVoltageActionCreator, getTemperatureActionCreator, getApparentPowerActionCreator, getActivePowerActionCreator,
  getFrequencyActionCreator, getApparentEnergyActionCreator, getActiveEnergyActionCreator, getExternalVoltActionCreator 
} from '../../../redux/slices/electricParametersSlice/electricParametersSlice';
import { getSolarConsumptionActionCreator } from '../../../redux/slices/solarParametersSlice/solarParametersSlice';
import fs from 'fs';

let totalNumChartPoints = 240;
let secondsPerDay = 86400;
//this variable is the parsed seconds taking into account the point generation rate (in the node, a single point is generated every 15 minutes, so 900 seconds)
// cambiaremos esta variable y será dinámica, hacer cuando esté hecho en back
let resultData: { strategy: number; x: number; y: number; }[] = [];

export const calculateParameters = async (obj: any, parameterType: string, numDays: number, graphicDateCursorInSeconds: number) => {
  //console.log("lo que llega de back", obj)

  if (obj.length === 0) {
    return;
  };

  let totalChartNumSeconds = secondsPerDay * numDays;
  let pointInterval = totalChartNumSeconds / totalNumChartPoints; //Distance in seconds between points

  let finishStrategyTime: any = [];
  let distanceBetweenPointsInSeconds: any = [];
  let numPointsArr:any = [];
  obj.map((item: any, index: any) => {
    const numPoints = item.numPointsT || item.numPointscgFreq || item.numPointscgExtV;
    numPointsArr.push(numPoints);
    
    distanceBetweenPointsInSeconds.push(item.freqGenerPoint);
    
    let itemDate = new Date(item.date);
    finishStrategyTime.push(itemDate.getTime() / 1000)
  });
  
  let strategyIndex = 0;
  let consumptions: any = [];
  let consumptionsSoc: any = [];
  let consumptionsSolarBattVolt: any = [];
  let consumptionsSolarBattCurrent: any = [];
  let consumptionsLumPowerConsum: any = [];
  let consumptionsPanelChargePower: any = [];
  let humanFormatHours: any = [];
  let totalSeconds = distanceBetweenPointsInSeconds[strategyIndex];

  let secondsWhenStrategyStarts = finishStrategyTime[strategyIndex] - numPointsArr[strategyIndex] * totalSeconds;
  
  for (let i = 0; i < totalNumChartPoints; i++) {
    let coeff4: any = 0;
    let coeff3: any = 0;
    let coeff2: any = 0;
    let coeff1: any = 0;
    let coeff0: any = 0;

    let coeff4Soc: any = 0;
    let coeff3Soc: any = 0;
    let coeff2Soc: any = 0;
    let coeff1Soc: any = 0;
    let coeff0Soc: any = 0;
    let coeff4SolarBattVolt: any = 0;
    let coeff3SolarBattVolt: any = 0;
    let coeff2SolarBattVolt: any = 0;
    let coeff1SolarBattVolt: any = 0;
    let coeff0SolarBattVolt: any = 0;
    let coeff4SolarBattCurrent: any = 0;
    let coeff3SolarBattCurrent: any = 0;
    let coeff2SolarBattCurrent: any = 0;
    let coeff1SolarBattCurrent: any = 0;
    let coeff0SolarBattCurrent: any = 0;
    let coeff4LumPowerConsum: any = 0;
    let coeff3LumPowerConsum: any = 0;
    let coeff2LumPowerConsum: any = 0;
    let coeff1LumPowerConsum: any = 0;
    let coeff0LumPowerConsum: any = 0;
    let coeff4PanelChargePower: any = 0;
    let coeff3PanelChargePower: any = 0;
    let coeff2PanelChargePower: any = 0;
    let coeff1PanelChargePower: any = 0;
    let coeff0PanelChargePower: any = 0;

    let pointValue:any;
    let pointValueSoc:any;
    let pointValueSolarBattVolt;
    let pointValueSolarBattCurrent;
    let pointValueLumPowerConsum;
    let pointValuePanelChargePower;

    //NOTA PARA MI: se ha cambiado ligeramente la forma de calcular para captar cuando, de golpe (lo más probable), haya un apagado. En el anterior while se hacían los cálculos y
    // entonces se sumaba un punto en el strategyIndex, pero si lo sumamos antes de empezar los cálculos, trabajamos con el bloque de estrategia en curso, y así podemos calcular
    // cuando empieza dicho bloque de estrategia, ya que el graphicDateCursorInSeconds no siempre va a ir a la par que el secondsWhenStrategyStarts
    while (graphicDateCursorInSeconds > finishStrategyTime[strategyIndex] && strategyIndex < finishStrategyTime.length-1) {
      strategyIndex++;
      secondsWhenStrategyStarts = finishStrategyTime[strategyIndex] - numPointsArr[strategyIndex] * totalSeconds;
    }

    if (secondsWhenStrategyStarts - distanceBetweenPointsInSeconds[strategyIndex] > graphicDateCursorInSeconds || (graphicDateCursorInSeconds > finishStrategyTime[strategyIndex] && strategyIndex <= finishStrategyTime.length-1)) {
      if (parameterType !== "solar") {
        consumptions.push('');
      } else {
        consumptionsSoc.push('');
        consumptionsSolarBattVolt.push('');
        consumptionsSolarBattCurrent.push('');
        consumptionsLumPowerConsum.push('');
        consumptionsPanelChargePower.push('');
      }
      const day = new Date(graphicDateCursorInSeconds * 1000);
      const dayOptions:any = { month: '2-digit', day: 'numeric' };
      const hourOptions:any = { hour: '2-digit', minute: '2-digit' };
      const formattedDay = day.toLocaleDateString('es-ES', dayOptions);
      const formattedHour = day.toLocaleTimeString('es-ES', hourOptions);
      humanFormatHours.push(formattedDay + " - " + formattedHour);
    } else {
      switch (parameterType) {
        case "current":
          coeff4 = obj[strategyIndex].c4C;
          coeff3 = obj[strategyIndex].c3C;
          coeff2 = obj[strategyIndex].c2C;
          coeff1 = obj[strategyIndex].c1C;
          coeff0 = obj[strategyIndex].c0C;
          
          break;    
        case "voltage":
          coeff4 = obj[strategyIndex].c4V;
          coeff3 = obj[strategyIndex].c3V;
          coeff2 = obj[strategyIndex].c2V;
          coeff1 = obj[strategyIndex].c1V;
          coeff0 = obj[strategyIndex].c0V;
  
          break;
        case "temperature":
          coeff2 = obj[strategyIndex].c2T;
          coeff1 = obj[strategyIndex].c1T;
          coeff0 = obj[strategyIndex].c0T;
          
          break;
        case "apparentPower":
          coeff4 = obj[strategyIndex].c4appP;
          coeff3 = obj[strategyIndex].c3appP;
          coeff2 = obj[strategyIndex].c2appP;
          coeff1 = obj[strategyIndex].c1appP;
          coeff0 = obj[strategyIndex].c0appP;
          
          break;
        case "activePower":
          coeff4 = obj[strategyIndex].c4actP;
          coeff3 = obj[strategyIndex].c3actP;
          coeff2 = obj[strategyIndex].c2actP;
          coeff1 = obj[strategyIndex].c1actP;
          coeff0 = obj[strategyIndex].c0actP;
          
          break;
        case "frequency":
          coeff3 = obj[strategyIndex].c3cgFreq;
          coeff2 = obj[strategyIndex].c2cgFreq;
          coeff1 = obj[strategyIndex].c1cgFreq;
          coeff0 = obj[strategyIndex].c0cgFreq;
          
          break;
        case "apparentEnergy":
          coeff4 = obj[strategyIndex].c4appE;
          coeff3 = obj[strategyIndex].c3appE;
          coeff2 = obj[strategyIndex].c2appE;
          coeff1 = obj[strategyIndex].c1appE;
          coeff0 = obj[strategyIndex].c0appE;
          
          break;
        case "activeEnergy":
          coeff4 = obj[strategyIndex].c4actE;
          coeff3 = obj[strategyIndex].c3actE;
          coeff2 = obj[strategyIndex].c2actE;
          coeff1 = obj[strategyIndex].c1actE;
          coeff0 = obj[strategyIndex].c0actE;
          
          break;
        case "powerGridVoltage":
          coeff3 = obj[strategyIndex].c3cgExtV;
          coeff2 = obj[strategyIndex].c2cgExtV;
          coeff1 = obj[strategyIndex].c1cgExtV;
          coeff0 = obj[strategyIndex].c0cgExtV;
    
          break;
        case "solar":
          coeff4Soc = obj[strategyIndex].c4SOC;
          coeff3Soc = obj[strategyIndex].c3SOC;
          coeff2Soc = obj[strategyIndex].c2SOC;
          coeff1Soc = obj[strategyIndex].c1SOC;
          coeff0Soc = obj[strategyIndex].c0SOC;

          coeff4SolarBattVolt = obj[strategyIndex].c4solarBattVolt;
          coeff3SolarBattVolt = obj[strategyIndex].c3solarBattVolt;
          coeff2SolarBattVolt = obj[strategyIndex].c2solarBattVolt;
          coeff1SolarBattVolt = obj[strategyIndex].c1solarBattVolt;
          coeff0SolarBattVolt = obj[strategyIndex].c0solarBattVolt;

          coeff4SolarBattCurrent = obj[strategyIndex].c4solarBattCurrent;
          coeff3SolarBattCurrent = obj[strategyIndex].c3solarBattCurrent;
          coeff2SolarBattCurrent = obj[strategyIndex].c2solarBattCurrent;
          coeff1SolarBattCurrent = obj[strategyIndex].c1solarBattCurrent;
          coeff0SolarBattCurrent = obj[strategyIndex].c0solarBattCurrent;

          coeff4LumPowerConsum = obj[strategyIndex].c4LuminairePowerConsumption;
          coeff3LumPowerConsum = obj[strategyIndex].c3LuminairePowerConsumption;
          coeff2LumPowerConsum = obj[strategyIndex].c2LuminairePowerConsumption;
          coeff1LumPowerConsum = obj[strategyIndex].c1LuminairePowerConsumption;
          coeff0LumPowerConsum = obj[strategyIndex].c0LuminairePowerConsumption;

          coeff4PanelChargePower = obj[strategyIndex].c4PanelChargePower;
          coeff3PanelChargePower = obj[strategyIndex].c3PanelChargePower;
          coeff2PanelChargePower = obj[strategyIndex].c2PanelChargePower;
          coeff1PanelChargePower = obj[strategyIndex].c1PanelChargePower;
          coeff0PanelChargePower = obj[strategyIndex].c0PanelChargePower;
    
          break;
        default:
      };

      if (parameterType !== "solar") {
        if (coeff0 === null) coeff0 = 0;
        if (coeff1 === null) coeff1 = 0;
        if (coeff2 === null) coeff2 = 0;
        if (coeff3 === null) coeff3 = 0;
        if (coeff4 === null) coeff4 = 0;
      } else {
        if (coeff0Soc === null) coeff0Soc = 0;
        if (coeff1Soc === null) coeff1Soc = 0;
        if (coeff2Soc === null) coeff2Soc = 0;
        if (coeff3Soc === null) coeff3Soc = 0;
        if (coeff4Soc === null) coeff4Soc = 0;
        
        if (coeff0SolarBattVolt === null) coeff0SolarBattVolt = 0;
        if (coeff1SolarBattVolt === null) coeff1SolarBattVolt = 0;
        if (coeff2SolarBattVolt === null) coeff2SolarBattVolt = 0;
        if (coeff3SolarBattVolt === null) coeff3SolarBattVolt = 0;
        if (coeff4SolarBattVolt === null) coeff4SolarBattVolt = 0;

        if (coeff0SolarBattCurrent === null) coeff0SolarBattCurrent = 0;
        if (coeff1SolarBattCurrent === null) coeff1SolarBattCurrent = 0;
        if (coeff2SolarBattCurrent === null) coeff2SolarBattCurrent = 0;
        if (coeff3SolarBattCurrent === null) coeff3SolarBattCurrent = 0;
        if (coeff4SolarBattCurrent === null) coeff4SolarBattCurrent = 0;

        if (coeff0LumPowerConsum === null) coeff0LumPowerConsum = 0;
        if (coeff1LumPowerConsum === null) coeff1LumPowerConsum = 0;
        if (coeff2LumPowerConsum === null) coeff2LumPowerConsum = 0;
        if (coeff3LumPowerConsum === null) coeff3LumPowerConsum = 0;
        if (coeff4LumPowerConsum === null) coeff4LumPowerConsum = 0;

        if (coeff0PanelChargePower === null) coeff0PanelChargePower = 0;
        if (coeff1PanelChargePower === null) coeff1PanelChargePower = 0;
        if (coeff2PanelChargePower === null) coeff2PanelChargePower = 0;
        if (coeff3PanelChargePower === null) coeff3PanelChargePower = 0;
        if (coeff4PanelChargePower === null) coeff4PanelChargePower = 0;
      }
  
      let x = 1 + (graphicDateCursorInSeconds - secondsWhenStrategyStarts) / totalSeconds;
      //Mirar si tiene sentido. Es una chapuza que hemos hecho pero tal vez hay que dejarla por si acaso pasa algo raro.
      //if (x > numPointsArr[strategyIndex]) x = numPointsArr[strategyIndex];

      if (parameterType !== "solar") {
        pointValue = parseFloat(coeff4) * Math.pow(x,  4) + parseFloat(coeff3) * Math.pow(x, 3) + parseFloat(coeff2) * Math.pow(x, 2) + parseFloat(coeff1) * Math.pow(x, 1) + parseFloat(coeff0);
        consumptions.push(pointValue);
      } else {
        pointValueSoc = parseFloat(coeff4Soc) * Math.pow(x,  4) + parseFloat(coeff3Soc) * Math.pow(x, 3) + parseFloat(coeff2Soc) * Math.pow(x, 2) + parseFloat(coeff1Soc) * Math.pow(x, 1) + parseFloat(coeff0Soc);
        consumptionsSoc.push(pointValueSoc);
        pointValueSolarBattVolt = parseFloat(coeff4SolarBattVolt) * Math.pow(x,  4) + parseFloat(coeff3SolarBattVolt) * Math.pow(x, 3) + parseFloat(coeff2SolarBattVolt) * Math.pow(x, 2) + parseFloat(coeff1SolarBattVolt) * Math.pow(x, 1) + parseFloat(coeff0SolarBattVolt);
        consumptionsSolarBattVolt.push(pointValueSolarBattVolt);
        pointValueSolarBattCurrent = parseFloat(coeff4SolarBattCurrent) * Math.pow(x,  4) + parseFloat(coeff3SolarBattCurrent) * Math.pow(x, 3) + parseFloat(coeff2SolarBattCurrent) * Math.pow(x, 2) + parseFloat(coeff1SolarBattCurrent) * Math.pow(x, 1) + parseFloat(coeff0SolarBattCurrent);
        consumptionsSolarBattCurrent.push(pointValueSolarBattCurrent);
        pointValueLumPowerConsum = parseFloat(coeff4LumPowerConsum) * Math.pow(x,  4) + parseFloat(coeff3LumPowerConsum) * Math.pow(x, 3) + parseFloat(coeff2LumPowerConsum) * Math.pow(x, 2) + parseFloat(coeff1LumPowerConsum) * Math.pow(x, 1) + parseFloat(coeff0LumPowerConsum);
        consumptionsLumPowerConsum.push(pointValueLumPowerConsum);
        pointValuePanelChargePower = parseFloat(coeff4PanelChargePower) * Math.pow(x,  4) + parseFloat(coeff3PanelChargePower) * Math.pow(x, 3) + parseFloat(coeff2PanelChargePower) * Math.pow(x, 2) + parseFloat(coeff1PanelChargePower) * Math.pow(x, 1) + parseFloat(coeff0PanelChargePower);
        consumptionsPanelChargePower.push(pointValuePanelChargePower);
      }

      const day = new Date(graphicDateCursorInSeconds * 1000);
      const dayOptions:any = { month: '2-digit', day: 'numeric' };
      const hourOptions:any = { hour: '2-digit', minute: '2-digit' };
      const formattedDay = day.toLocaleDateString('es-ES', dayOptions);
      const formattedHour = day.toLocaleTimeString('es-ES', hourOptions);
      humanFormatHours.push(formattedDay + " - " + formattedHour);

      if (parameterType !== "solar") {
        resultData.push({strategy: strategyIndex, x: x, y: pointValue})
      } else {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueSoc})
      }
    }
    graphicDateCursorInSeconds += pointInterval;
  }
  if (parameterType !== "solar") {
    createParameterData(consumptions, humanFormatHours, parameterType)
  } else {
    createSolarParameterData(consumptionsSoc, consumptionsSolarBattVolt, consumptionsSolarBattCurrent, consumptionsLumPowerConsum, consumptionsPanelChargePower, humanFormatHours)
  }

}

export const createParameterData = async (parameters: any, hours: any, parameterType: string) => {
  let labels: any = [];

  for (let i = 0; i < hours.length; i++) {
    if (i % 12 === 0 && i !== 0) {
      labels.push(hours[i]);
    } else if (i === 0) {
      labels.push(hours[i]);
    } else if (i === hours.length - 1) {
      labels.push(hours[i]);
    } else {
      labels.push("");
    }
  }

  /* Los números que dividen a 240 de manera exacta, es decir, los divisores de 240, son:

        1
        2
        3
        4
        5
        6
        8
        10
        12
        15
        16
        20
        24
        30
        40
        48
        60
        80
        120
        240
            */

  switch (parameterType) {
    case "current":
      store.dispatch(getConsumptionActionCreator({ current: parameters, currentTimestamps: labels }))
      break;
    case "voltage":
      store.dispatch(getVoltageActionCreator({ voltage: parameters, voltageTimestamps: labels }))
      break;
    case "temperature":
      store.dispatch(getTemperatureActionCreator({ temperature: parameters, temperatureTimestamps: labels }))
      break;
    case "apparentPower":
      store.dispatch(getApparentPowerActionCreator({ apparentPower: parameters, apparentPowerTimestamps: labels }))
      break;
    case "activePower":
      store.dispatch(getActivePowerActionCreator({ activePower: parameters, activePowerTimestamps: labels }))
      break;
    case "frequency":
      store.dispatch(getFrequencyActionCreator({ frequency: parameters, frequencyTimestamps: labels }))
      break;
    case "apparentEnergy":
      store.dispatch(getApparentEnergyActionCreator({ apparentEnergy: parameters, apparentEnergyTimestamps: labels }))
      break;
    case "activeEnergy":
      store.dispatch(getActiveEnergyActionCreator({ activeEnergy: parameters, activeEnergyTimestamps: labels }))
      break;
    case "powerGridVoltage":
      store.dispatch(getExternalVoltActionCreator({ powerGridVoltage: parameters, powerGridVoltageTimestamps: labels }))
      break;
    default:
  };
};

export const createSolarParameterData = async (parametersSoc: any, parametersSolarBattVolt: any, parametersSolarBattCurrent: any, parametersLumPowerConsum: any,
  parametersPanelChargePower: any, hours: any) => {
  let labels: any = [];

  for (let i = 0; i < hours.length; i++) {
    if (i % 12 === 0 && i !== 0) {
      labels.push(hours[i]);
    } else if (i === 0) {
      labels.push(hours[i]);
    } else if (i === hours.length - 1) {
      labels.push(hours[i]);
    } else {
      labels.push("");
    }
  }

  store.dispatch(getSolarConsumptionActionCreator({ soc: parametersSoc, solarBattVolt: parametersSolarBattVolt, solarBattCurrent: parametersSolarBattCurrent,
    lumPowerConsum: parametersLumPowerConsum, panelChargePower: parametersPanelChargePower, timestamps: labels }))
}
