import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { saveSelectedStrategyActionCreator, deleteSelectedStrategyActionCreator } from "../../../redux/slices/strategiesSlice/strategiesSlice";
import CurrentStrategyChart from "./CurrentStrategyChart";
import { StrategiesListModal } from "./StrategiesListModal";
import { Text, TextBox, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface CurrentStrategyProps {
  selectedLamp: any;
  selectedLampId: string | undefined;
  selectedLampStrategyId: string,
  viewState: any;
}

export const CurrentStrategy = ({ selectedLamp, selectedLampId, selectedLampStrategyId, viewState }: CurrentStrategyProps) => {
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const selectedStrategy = useAppSelector((state) => state.strategies.selectedStrategy);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  
  strategies.map((strategy:any) => {
    if (selectedLampStrategyId === null) {
      return dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: false }))
    }

    if (strategy.id === selectedLampStrategyId) {
      return dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: strategy }))
    }
  })

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%",
        flexGrow: 1
      }}
    >
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text color="#A3AED0">{t("currentStrategy")}</Text>
          </div>
          {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={plusCircle} height={16} onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}} style={{ opacity: iconOpacity, cursor: "pointer" }}
              onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)}
            />
          </div> */}
          <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={plusCircle} height={16} onClick={() => {selectedLamp.online === "1" && setShowActions(!showActions)}} style={{ cursor: selectedLamp.online === "1" ? "pointer" : "not-allowed",
              opacity: iconOpacity }}
              onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)}
            />
            {showActions && (
              <article
                style={{
                  position: "absolute",
                  top: "42px",
                  right: "24px",
                  width: "240px",
                  maxHeight: "224px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                  boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                  borderRadius: "4px",
                  zIndex: 6
                }}
              >  
                <>
                  <style>
                    {`
                      .org-span:hover {
                        background-color: #1c1b1f11;
                      }
                    `}
                  </style>
                  <span
                    className="org-span"
                    onClick={async () => {setShowActions(!showActions); setShowModal(true)}}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      isolation: "isolate",
                      paddingLeft: "15px",
                      paddingRight: "6px",
                      width: "100%",
                      minHeight: "56px",
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                  >
                    {t("changeDefaultStrategy")}
                  </span>
                  {/* {selectedStrategy && (
                    <span
                      className="org-span"
                      onClick={async () => {setShowActions(!showActions); dispatch(deleteSelectedStrategyActionCreator())}}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        isolation: "isolate",
                        paddingLeft: "15px",
                        paddingRight: "6px",
                        width: "100%",
                        minHeight: "56px",
                        cursor: "pointer",
                        textAlign: "left",
                      }}
                    >
                      {t("removeDefaultStrategy")}
                    </span>
                    )
                  } */}
                </>
        




                {/* {strategies && strategies?.map((strategy: any) => (
                  <>
                    <style>
                      {`
                        .org-span:hover {
                          background-color: #1c1b1f11;
                        }
                      `}
                    </style>
                    <span
                      className="org-span"
                      key={strategy.id}
                      onClick={async () => {setShowStrategies(!showStrategies); dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: strategy }))}}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        isolation: "isolate",
                        paddingLeft: "15px",
                        paddingRight: "6px",
                        width: "100%",
                        minHeight: "56px",
                        cursor: "pointer",
                        textAlign: "left",
                      }}
                    >
                      {strategy?.strgy_name}
                    </span>
                  </>
                ))} */}
              </article>
            )}
            {showModal && (
              <StrategiesListModal showModal={showModal} setShowModal={setShowModal} selectedLampId={selectedLampId} />     
            )}
          </div>
        </div>
        <div style={{ marginTop: "-8px" }}>
          <Text fontWeight="700" marginBottom="4px" style={{ marginTop: !selectedStrategy?.description ? "16px" : "0px" }}>
            {selectedStrategy?.strgy_name}
          </Text>
          <Text color="#A3AED0" style={{ fontSize: "12px" }}>
            {selectedStrategy?.description}
          </Text>
        </div>
      </div>
      {strategies ? 
        selectedStrategy ?
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CurrentStrategyChart />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "8px",
                height: "20px",
              }}
            >
              <EnergyIcon height={14} style={{ color: selectedStrategy?.color || "rgb(135, 203, 232)" }} />
              <TextSpan color={selectedStrategy?.color || "rgb(135, 203, 232)"} fontWeight="500">
                {selectedStrategy?.saving || 0}{t("percentageEnergySaved")}
              </TextSpan>
            </div>
          </>
        :
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: !selectedLamp.solar_id ? "0px" : "60px"}}>
            <Text>{t("noStrategyAssigned")}</Text>
          </div>
      :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
          <Text>{t("noStrategyCreated")}</Text>
          <button
            style={{
              cursor: "pointer",
              display: "flex",
              marginTop: "10px",
              borderRadius: "8px",
              alignItems: "center",
              padding: "8px",
              justifyContent: "center",
              height: "26px",
              background: "#ffc107",
              border: "none",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}       
              onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}}
            >
              <TextBox fontWeight="500" color="#000000" style={{ fontSize: "10px" }}>
                {t("createYourOwnOne")}
              </TextBox>
          </button>
        </div>
      }





      {/* {strategies && selectedStrategy ? 
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CurrentStrategyChart />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8px",
              height: "20px",
            }}
          >
            <EnergyIcon height={14} style={{ color: selectedStrategy?.color || "rgb(135, 203, 232)" }} />
            <TextSpan color={selectedStrategy?.color || "rgb(135, 203, 232)"} fontWeight="500">
              {selectedStrategy?.saving || 0}{txt.percentageEnergySaved}
            </TextSpan>
          </div>
        </>
      :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
          <Text>Don't have any strategy yet?</Text>
          <button
            style={{
              cursor: "pointer",
              display: "flex",
              marginTop: "10px",
              borderRadius: "8px",
              alignItems: "center",
              padding: "8px",
              justifyContent: "center",
              height: "26px",
              background: "#ffc107",
              border: "none",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}
              
              onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}}
            >
              <TextBox fontWeight="500" color="#000000" style={{ fontSize: "10px" }}>
                Create your own one
              </TextBox>
          </button>
        </div>
      } */}
    </div>
  );
};
