import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import { registerUserActionCreator, changeStepActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import Roles from "../Step2Roles/Roles";
import { z, object, string } from "zod";
import {
  InputField,
  CardContainer,
  NextButton,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { LogoStyled } from "../../../Header/HeaderStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import map from "../../../../assets/new_icons/Map.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import { userRegistrationSchema } from "../../../../schema/userRegistrationSchema";

interface NameProps {
  showDevices?: any;
  goToDevices?: any;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Details = ({
  showDevices,
  goToDevices,
  goToReview,
  // errorMessage,
  // setErrorMessage,
}: NameProps) => {
  const dispatch = useAppDispatch();
  const groupInfo = useAppSelector((state) => state.groups);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { user } = useAppSelector((state) => state.userRegistration);
  const { t } = useTranslation();

  const [name, setName] = useState(groupInfo.name);

  useEffect(() => {
    if (name) {
      // setErrorMessage(false);
    }

    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  const [formData, setFormData] = useState({
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    email: user?.email ?? "",
    password: user?.password ?? "",
    password_confirm: user?.password_confirm ?? "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    phoneNumber: "",
    email: "",
    password: "",
    password_confirm: "",
  });
  const [submitError, setSubmitError] = useState("");

  const handleChange = (field: any, value: any) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    try {
      userRegistrationSchema.parse(formData);
      dispatch(registerUserActionCreator(formData))
      dispatch(changeStepActionCreator(2));
      goToReview();
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors = {};
        error.errors.forEach((err) => {
          // @ts-ignore
          errors[err.path[0]] = err.message;
        });
        // @ts-ignore
        setFormErrors(errors);
      } else {
        setSubmitError("An error occurred during form validation.");
      }
    }
  };


  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "55px",
          paddingLeft: "40px",
          marginLeft: "10px",
        }}
      >
        <Text
          fontWeight="500"
          style={{ marginBottom: "4px", fontSize: "14px" }}
        >
          {t("Step 1")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("generalDetails")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("generalDetailsDescription")}
        </Text>
      </div>

      <div style={{ width: "374px", margin: "auto" }}>
        <form onSubmit={handleFormSubmit}>
          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("firstNameUser")}*
            </Text>
            <InputField
              value={formData.first_name}
              onChange={(e) => handleChange("first_name", e.target.value)}
              maxLength={50}
              placeholder={t("firstNameUser")}
            />
            {formErrors?.first_name && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.first_name}
              </Text>
            )}
            {/* {errorMessage ? (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {t("errorBlankInput")}
              </Text>
            ) : (
              <></>
            )} */}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("lastNameUser")}
            </Text>
            <InputField
              value={formData?.last_name}
              onChange={(e) => handleChange("last_name", e.target.value)}
              maxLength={50}
              placeholder={t("lastNameUser")}
            />
            {formErrors?.last_name && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.last_name}
              </Text>
            )}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("phoneNumberUser")}
            </Text>
            <InputField
              value={formData?.phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              maxLength={50}
              placeholder={t("phoneNumberUserPH")}
            />
            {formErrors?.phoneNumber && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.phoneNumber}
              </Text>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("emailUser")}*
            </Text>
            <InputField
              value={formData?.email}
              onChange={(e) => handleChange("email", e.target.value)}
              maxLength={50}
              placeholder={t("emailUser")}
            />
            {formErrors?.email && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.email}
              </Text>
            )}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("passwordUser")}*
            </Text>
            <InputField
              value={formData?.password}
              onChange={(e) => handleChange("password", e.target.value)}
              maxLength={50}
              placeholder={t("passwordUser")}
            />
            {formErrors?.password && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.password}
              </Text>
            )}
          </div>

          <div style={{ marginBottom: "15px" }}>
            <Text
              textType="caption"
              color="#475569"
              marginBottom="3px"
              style={{ fontSize: "14px" }}
            >
              {t("passwordConfirm")}*
            </Text>
            <InputField
              value={formData?.password_confirm}
              onChange={(e) => handleChange("password_confirm", e.target.value)}
              maxLength={50}
              placeholder={t("passwordConfirm")}
            />
            {formErrors?.password_confirm && (
              <Text
                marginBottom="0px"
                style={{ display: "flex", justifyContent: "left", color: "red" }}
              >
                {formErrors?.password_confirm}
              </Text>
            )}
          </div>
          <NextButton
            style={{
              justifySelf: "end",
              alignSelf: "center",
              width: "374px",
              marginTop: "100px",
            }}
            type='submit'
          >
            {t("continue")}
          </NextButton>
        </form>
          <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
            <img src={stepLine} width={22} />
            <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
            <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
            <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
            <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
            <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
          </div>
      </div>
    </CardContainer>
  );
};
