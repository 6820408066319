import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { managedUser, managedUsersList } from "../../../types/interfaces";

const initialState: managedUsersList = {
  users: []
};

const managedUsersListSlice = createSlice({
  name: "managedUsersList",
  initialState: initialState,
  reducers: {
    loadUsers: (previousState, action: PayloadAction<managedUsersList>) => {
      return {
        ...action.payload
      }
    },

    updateUser: (previousState, action: PayloadAction<managedUser> | any) => {
      const updatedUsers = previousState.users.map((user) =>
        user.id === action.payload.id ? { ...user, ...action.payload } : user
      );

      return {
        ...previousState,
        users: updatedUsers,
      };
    },
  },
});

//en el slice que usaré para updatear solo una parte de este slice, tendré que tener en cuenta que nunca envío ni el color ni lar orgs,
// por lo que tengo que hacer un if y poner item.color = previousState.color, o previousState[i].color, y con las orgs también

export const managedUsersListReducer = managedUsersListSlice.reducer;

export const {
  loadUsers: loadUsersActionCreator,
  updateUser: updateUserActionCreator
} = managedUsersListSlice.actions;

