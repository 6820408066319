import { useTranslation } from "react-i18next";
import { store } from '../../redux/store';
import { setLanguage } from "../../redux/slices/languageSlice/languageSlice";

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: any) => {
    store.dispatch(setLanguage(language.key));
    localStorage.setItem("selectedLanguage", (language.key as string) || "");
    i18n.changeLanguage(language.key);
  };

  return {
    changeLanguage
  };
};

export const items = [
  {
    label: "English",
    id: 1,
    key: "en"
  },
  {
    label: "Spanish",
    id: 2,
    key: "es",
  },
  {
    label: "French",
    id: 4,
    key: "fr"
  },
  {
    label: "Kinyarwanda",
    id: 6,
    key: "rw"
  },
  {
    label: "Swahili",
    id: 7,
    key: "sw"
  },
  {
    label: "Arabic",
    id: 8,
    key: "ar"
  },
];
