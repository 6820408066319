import Cookies from "js-cookie";
import { GeneralAlarms, AlarmsFromNode } from "@/types/interfaces";

export const useAlarmsService = () => {
  const webAddressPrefixGetAllAlarms: string = process.env.REACT_APP_API_GET_ALARMS_LOCAL_BASE_URL as string;
  const webAddressPrefixSolveAlarmOrGetNodeAlarms: string = process.env.REACT_APP_API_SOLVE_ALARM_OR_GET_NODE_ALARMS_LOCAL_BASE_URL as string;
  const token = Cookies.get("token");

  const getAlarms = async (orgId: number, serviceId: number, token: string, setLoading: (loading: boolean) => void): Promise<any> => {
    try {
      const response = await fetch(`${webAddressPrefixGetAllAlarms}${orgId}&servId=${serviceId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const alarmsList = await response.json();

        if (alarmsList.length > 0) {
          const alarmsArr: GeneralAlarms[] = alarmsList.map((alarm:any) => ({
            id: alarm.id,
            device_id: alarm.device_id,
            name: alarm.name,
            device_name: alarm.device_name,
            title: alarm.title,
            description: alarm.description,
            type: alarm.type,
            status: alarm.status,
            solved: alarm.solved,
            creation_date: alarm.creation_date
          }));

          setLoading(false);
          return alarmsArr
        } else {
          console.log("No user data found in response.");
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        console.log("Failed to fetch user profile.", response.status);
        return false;
      }
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const solveAlarm = async (alarmId: string) => {
    try {
      await fetch(`${webAddressPrefixSolveAlarmOrGetNodeAlarms}${alarmId}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const getNodeAlarms = async (orgId: number, serviceId: number, nodeId: number, token: string): Promise<any> => {
    try {
      const response = await fetch(`${webAddressPrefixSolveAlarmOrGetNodeAlarms}${nodeId}?orgId=${orgId}&servId=${serviceId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const nodeAlarmsList = await response.json();

        if (nodeAlarmsList.length > 0) {
          const nodeAlarmsArr: AlarmsFromNode[] = nodeAlarmsList.map((alarm:any) => ({
            id: alarm.id,
            title: alarm.title,
            type: alarm.type,
            creation_date: alarm.creation_date

          }));

          return nodeAlarmsArr
        } else {
          const nodeAlarmsArr:any = [];
          console.log("No user data found in response.");
          return nodeAlarmsArr
        }
      } else {
        console.log("Failed to fetch user profile.", response.status);
        return false;
      }
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  return {
    getAlarms,
    solveAlarm,
    getNodeAlarms
  };
}
