import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import favouriteSelected from "../../../assets/new_icons/Favourite filled.svg";
import favourite from "../../../assets/new_icons/Favourite.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import co2 from "../../../assets/new_icons/Co2.svg";
import energyEco from "../../../assets/new_icons/Energy_eco.svg";
import money from "../../../assets/new_icons/Money.svg";
import timestamp from "../../../assets/new_icons/Timestamp.svg";

interface TotalsCardProps {
  selectedLamp: any
}

export const TotalsCard = ({ selectedLamp }: TotalsCardProps) => {
  const nodes = useAppSelector((state) => state.devices.nodes);
  const groupsList = useAppSelector((state) => state.groups.groups);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  console.log(selectedLamp)

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
        gap: "20px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t("totalConsumptionsSavings")}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, /* cursor: "pointer" */ }} onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <div className="card"
          style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end", padding: "12px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "25%" }}
        >
          <Text fontWeight="700" marginBottom="50px" style={{ fontSize: "50px" }}>{selectedLamp.lifetime}</Text>     
          <Text marginBottom="6px" style={{ fontSize: "14px", opacity: 0.6 }}>{t("runningHours")}</Text>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "8px", paddingBottom: "8px",
            background: "#E8F3FC", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)", borderRadius: "6px", width: "100%" }}>
            <img src={timestamp} height={22} />
            <div>
              <Text fontWeight="700" marginBottom="0px" style={{ fontSize: "16px" }}>{t("lifetime")}</Text>
              <Text marginBottom="0px" style={{ fontSize: "9px", opacity: 0.6 }}>{selectedLamp.humanTime.years} {t("years")} {selectedLamp.humanTime.months} {t("months")} {selectedLamp.humanTime.days} {t("days")}</Text>
            </div>
          </div>
        </div>
        <div className="card"
          style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "12px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "25%" }}
        >
          <Text fontWeight="600" marginBottom="-8px" color="#56C568" style={{ fontSize: "16px" }}>{t("saved")}</Text>
          <Text fontWeight="700" marginBottom="4px" color="#56C568" style={{ fontSize: "50px" }}>0</Text>
          <Text marginBottom="-8px" style={{ fontSize: "12px", opacity: 0.6 }}>{t("consumed")}</Text>
          <Text fontWeight="700" marginBottom="6px" style={{ fontSize: "40px" }}>{selectedLamp.energy_lifetime}</Text>
          <Text marginBottom="6px" style={{ fontSize: "14px", opacity: 0.6 }}>{t("kwh")}</Text>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "8px", paddingBottom: "8px",
            background: "#E8F3FC", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)", borderRadius: "6px", width: "100%" }}>
            <img src={energyEco} height={22} />
            <div>
              <Text fontWeight="700" marginBottom="0px" style={{ fontSize: "16px" }}>{t("energy")}</Text>
              <Text marginBottom="0px" style={{ fontSize: "12px", opacity: 0.6 }}>---</Text>
            </div>
          </div>
        </div>
        <div className="card"
          style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "12px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "25%" }}
        >
          <Text fontWeight="600" marginBottom="-8px" color="#56C568" style={{ fontSize: "16px" }}>{t("saved")}</Text>
          <Text fontWeight="700" marginBottom="4px" color="#56C568" style={{ fontSize: "50px" }}>0</Text>
          <Text marginBottom="-8px" style={{ fontSize: "12px", opacity: 0.6 }}>{t("consumed")}</Text>
          <Text fontWeight="700" marginBottom="6px" style={{ fontSize: "40px" }}>0</Text>
          <Text marginBottom="6px" style={{ fontSize: "14px", opacity: 0.6 }}>EUR</Text>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "8px", paddingBottom: "8px",
            background: "#E8F3FC", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)", borderRadius: "6px", width: "100%" }}>
            <img src={money} height={22} />
            <div>
              <Text fontWeight="700" marginBottom="0px" style={{ fontSize: "16px" }}>{t("money")}</Text>
              <Text marginBottom="0px" style={{ fontSize: "12px", opacity: 0.6 }}>0 € / kW</Text>
            </div>
          </div>
        </div>
        <div className="card"
          style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "12px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
          backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "25%" }}
        >
          <Text fontWeight="600" marginBottom="-8px" color="#56C568" style={{ fontSize: "16px" }}>{t("saved")}</Text>
          <Text fontWeight="700" marginBottom="4px" color="#56C568" style={{ fontSize: "50px" }}>0</Text>
          <Text marginBottom="-8px" style={{ fontSize: "12px", opacity: 0.6 }}>{t("consumed")}</Text>
          <Text fontWeight="700" marginBottom="6px" style={{ fontSize: "40px" }}>0</Text>
          <Text marginBottom="6px" style={{ fontSize: "14px", opacity: 0.6 }}>t</Text>
          <div style={{ display: "flex", alignItems: "center", gap: "16px", paddingRight: "12px", paddingLeft: "12px", paddingTop: "8px", paddingBottom: "8px",
            background: "#E8F3FC", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.2)", borderRadius: "6px", width: "100%" }}>
            <img src={co2} height={22} />
            <div>
              <Text fontWeight="700" marginBottom="0px" style={{ fontSize: "16px" }}>{t("co2")}</Text>
              <Text marginBottom="0px" style={{ fontSize: "12px", opacity: 0.6 }}>0 € / KG</Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
