import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useLightingService } from "../../../services/lightingService";
import { languages } from "../../../utils/languages/languages";
import { DimmingInputStyled } from "./DimmingCardStyled";
import { Text, TextBox } from "../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface DimmingCardProps {
  selectedLamp: any;
  dimmingValue: number | undefined;
}

export const DimmingCard = ({
  selectedLamp,
  dimmingValue,
}: DimmingCardProps) => {
  const { sendCommandToLuminaire } = useLightingService();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [value, setValue]: any = useState();
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  //node.dimming
  const [barColor, setBarColor] = useState("#d2d4d9");

  if (selectedLamp.on === "0" || selectedLamp.online === "0") {
    dimmingValue = 0
  }

  const handleChange = async (event: any) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
    setBarColor(
      `linear-gradient(to right, #ffc107 ${newValue}%, #d2d4d9 ${newValue}%)`
    );
  };

  useEffect(() => {}, [value]);

  //cambiar iconos en tooooda la página
  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        paddingTop: "0px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div>
          <Text color="#A3AED0">
            {t(txt.dimming)}: {value || dimmingValue} %
          </Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <DimmingInputStyled>
        <input
          type="range"
          min="5"
          max="100"
          step="5"
          value={value || dimmingValue}
          onChange={handleChange}
          className="dimming__progress-bar"
          style={{
            background: !value
              ? `linear-gradient(to right, #ffc107 ${dimmingValue}%, #d2d4d9 ${dimmingValue}%)`
              : barColor,
          }}
        />
        <div style={{ background: barColor }}></div>
      </DimmingInputStyled>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TextBox textType="caption" color="#A3AED0">
          {txt.dimming5}
        </TextBox>
        <TextBox textType="caption" color="#A3AED0">
          {txt.dimming50}
        </TextBox>
        <TextBox textType="caption" color="#A3AED0">
          {txt.dimming100}
        </TextBox>
      </div>
      <button
        style={{
          cursor: selectedLamp.online === "0" ? "not-allowed" : "pointer",
          display: "flex",
          marginTop: "12px",
          marginBottom: "2px",
          borderRadius: "8px",
          alignItems: "center",
          padding: "8px",
          justifyContent: "center",
          height: "26px",
          background: selectedLamp.online === "0" ? "#1c1b1f11" : "#ffc107",
          border: "none",
          boxShadow:
            "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
        }}
        disabled={selectedLamp.online === "0" ? true : false}
        onClick={async () => {
          await sendCommandToLuminaire([selectedLamp], 3, value, 0);
        }}
      >
        <TextBox fontWeight="500" color="#000000" style={{ fontSize: "10px" }}>
          {t(txt.sendDimming)}
        </TextBox>
      </button>
    </div>
  );
};
