import styled from "styled-components";
import { BoxSizes } from "../../styles/shared-styles/box-sizes";

export const InputContainer = styled(BoxSizes)`
  position: relative;
`;

export const PreffixInputIcon = styled.img`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

export const SuffixInputIcon = styled.img`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const Input = styled.input`
  padding: ${(props) => (props.prefix ? "8px 32px" : "8px 16px")};
  border-radius: 4px;
`;

export const InputPasswordForProfile = styled.input`
  padding: .375rem .75rem;
  border-radius: .375rem;
`;
