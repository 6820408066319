import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { items } from "../../../../utils/languages/changeLanguage";
import languageIcon from "../../../../assets/new_icons/Language.svg";
import { changeStepActionCreator, registerUserActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import {
  InputField,
  CardContainer,
  NextButton,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { LogoStyled } from "../../../Header/HeaderStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import map from "../../../../assets/new_icons/Map.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import back from "../../../../assets/new_icons/Back_2.svg";

import { IconStyled } from "../../../Header/HeaderStyled";
// import languageIcon from "../../../assets/new_icons/Language.svg";
import { Image, Select } from "antd";
import { roleMapping } from "../../../../utils/roleMapping";
const { Option } = Select;

const Roles = () => {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.userRegistration);
  const groupInfo = useAppSelector((state) => state.groups);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const [selectedRole, setSelectedRole] = useState(user?.role_id ?? 1);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { t } = useTranslation();
  const [languageName, setLanguageName] = useState<null | string>(user?.user_language ?? '1');


  const [name, setName] = useState(groupInfo.name);
  const handleRoleClick = (role: string) => {
    const roleId = roleMapping[role];
    setSelectedRole(roleId);
  };

  const languageOnChange = (value: string) => {
    setLanguageName(value);
  };

  const submitRole = () => {
    dispatch(changeStepActionCreator(3))
    dispatch(registerUserActionCreator({
      role_id: selectedRole,
      user_language: languageName
    }))
  }

  useEffect(() => {
    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>

      <CardContainer style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            paddingLeft: "40px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "-31px",
              marginTop: "-40px",
              marginBottom: "30px",
              fontSize: "12px",
              fontWeight: "400",
              color: "#BDBDBD",
              cursor: "pointer",
            }}
            onClick={() => dispatch(changeStepActionCreator(1))}
          >
            <img src={back} alt="back" style={{ marginRight: "10px" }} />
            <span>Back to General Details</span>
          </div>
          <div style={{ marginBottom: "55px" }}>
            <Text
              fontWeight="500"
              style={{ marginBottom: "4px", fontSize: "14px" }}
            >
              {t("Step 2")}
            </Text>
            <Text
              fontWeight="600"
              marginBottom="0px"
              style={{ fontSize: "22px" }}
            >
              {t("rolesLanguage")}
            </Text>
            <Text
              textType="caption"
              fontWeight="400"
              style={{ fontSize: "12px" }}
            >
              {t("rolesLanguageUserDescription")}
            </Text>
          </div>

          <div className="mb-5">
            <span
              className="d-block mb-1"
              style={{ fontSize: "14px", fontWeight: "500", color: "#475569" }}
            >
              New user role
            </span>
            <span
              className="d-block"
              style={{ fontSize: "12px", fontWeight: "400", color: "#475569" }}
            >
              Choose the role for the user you are creating. You can only select
              one role.
            </span>
          </div>

          <div
            className="mb-5"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",

            }}
          >
            {Object.keys(roleMapping).map((role) => (
              <div
                key={role}
                className={`card px-3 py-2 `}
                style={{
                  border: selectedRole === roleMapping[role] ? "1px solid #1B2559" : 'none',
                  borderRadius: "10px",
                  cursor: "pointer",
                  boxShadow: "0 3px 6px #d6d5d1",
                }}
                onClick={() => handleRoleClick(role)}
              >
                {role}
              </div>
            ))}
          </div>

          <div>
            <span
              className="d-block mb-1"
              style={{ fontSize: "14px", fontWeight: "500", color: "#475569" }}
            >
              User language
            </span>
            <span
              className="d-block mb-5"
              style={{ fontSize: "12px", fontWeight: "400", color: "#475569" }}
            >
              Choose the language for the user. You can only select one.
            </span>

            <div style={{ width: "50%", paddingRight: "12px", position: "relative" }}>
              <IconStyled
                src={languageIcon}
                style={{
                  position: "absolute",
                  left: "3px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  opacity: 0.7,
                }}
              />

              <Select
                style={{ width: "100%" }} // Adjust paddingLeft to accommodate the icon
                optionFilterProp="children"
                defaultValue={user?.user_language ?? '1'}
                filterOption={filterOption}
                onChange={languageOnChange}
                value={languageName}
                className="my-select"
                placeholder='Please Select the Language'
              >
                {items.map((item, index) => (
                  <Option key={index} value={item.label}>
                    {t(item.label)}
                  </Option>
                ))}
              </Select>
            </div>

          </div>
        </div>
        <NextButton
          style={{
            justifySelf: "end",
            alignSelf: "center",
            width: "374px",
            marginTop: "100px",
          }}
          onClick={submitRole}
        >
          {t("continue")}
        </NextButton>
      </CardContainer>
    </>
  );
};

export default Roles;
