import redBadge from "../../assets/red_badge.svg";
import yellowBadge from "../../assets/yellow_badge.svg";
import greyBadge from "../../assets/grey_badge.svg";

type AlarmStatus = "Critical" | "Moderate" | "Low";
interface IProps {
  status: AlarmStatus;
}

export const GetBadge = ({ status }: IProps) => {
  const badgeMap = {
    Critical: redBadge,
    Moderate: yellowBadge,
    Low: greyBadge,
  };
  return (
    <img src={badgeMap[status]} style={{ marginRight: "4px" }} alt="status" />
  );
};
