import { store } from '../redux/store';

export const useGroupsService = () => {
  const webAddressPrefixGet: string = process.env.REACT_APP_API_GET_GROUPS_LOCAL_BASE_URL as string;
  const webAddressPrefixPost: string = process.env.REACT_APP_API_POST_GROUPS_LOCAL_BASE_URL as string; 
  const webAddressPrefixDelete: string = process.env.REACT_APP_API_DELETE_GROUPS_LOCAL_BASE_URL as string;

  const webAddressPrefixEdit: string = process.env.REACT_APP_API_EDIT_STRATEGIES_LOCAL_BASE_URL as string;

  const getGroups = async (orgId: number, serviceId: number, setShowLoader?: (loading: boolean) => void) => {
    try {
      const response = await fetch(`${webAddressPrefixGet}${orgId}&servid=${serviceId}`, {credentials: 'include'});
      const groups = await response.json();

      const ids:any = []
      groups.map((group:any) => {
        ids.push(group.groupId)
      });

      const values = store.getState();
      let groupsWithInfo:any = [];

      ids.forEach((id:any, i:any) => {
        let nodes = values.devices.nodes.filter((node:any) => node.groups?.includes(id.toString()));
        groupsWithInfo.push({ groupId: id, groupName: groups[i].groupName, star: groups[i].star, nodes: nodes });
      });
      
      //store.dispatch(getGroupsActionCreator({ groups: groupsWithInfo }));

      if (setShowLoader) {
        setShowLoader(false);
      }
      return groupsWithInfo;
    } catch (error) {
      if (setShowLoader) {
        setShowLoader(false);
      }
      console.log('El error: ', error);
      return false;
    }
  };
  
  const postGroup = async (orgId: number, serviceId: number, setShowLoader: (loading: boolean) => void) => {
    const values = store.getState();
    let devicesIds:any = [];
    values.filteredDevices.nodesInArea.map((device:any) => {
      devicesIds.push(device.node_id)
    });

    const body = {
      group_name: values.groups.name,
      devices: devicesIds
    }
  
    try {
      let res = await fetch(`${webAddressPrefixPost}${orgId}&servid=${serviceId}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });

      let groupId:any = await res.json()

      if (res.ok) {
        let tempDevices = JSON.parse(JSON.stringify(values.devices));
        
        tempDevices.nodes.forEach((item:any) => {
          devicesIds.forEach((element:any) => {
            if (item.node_id === element) {
              item.groups.push(groupId[0]);
            }
          });
        });

        return tempDevices

        //store.dispatch(loadDevicesActionCreator(tempDevices));
        //console.log("llega aquí?", tempDevices)
      } 
      //return true
    } catch (error) {
      setShowLoader(false);
      console.log('El error: ', error);
      return false;
    }
  };

  const deleteGroup = async (groupId: any) => {
    try {
      let res = await fetch(`${webAddressPrefixDelete}${groupId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getGroups,
    postGroup,
    deleteGroup
  };
};


