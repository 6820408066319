import { useAppDispatch } from "../../redux/hooks";
import { setIsFullViewActionCreator, setFirstRenderMapToTrueActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { CardContainerResponsePage } from "./ResponsePageStyled";
import commingSoon from "../../assets/new_icons/coming_soon.png";
import { Text } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";

export const ComingSoon = () => {
  const dispatch = useAppDispatch();

  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={commingSoon} />
          <Text
            textType="title"
            color="#1B2559"
            fontWeight="700"
            marginBottom="0px"
          >
            This page is coming soon!
          </Text>
          <Text textType="subTitle" color="#1B2559" fontWeight="400">
            Our team are currently working hard building this page.
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary" onClick={() => {dispatch(setIsFullViewActionCreator()); dispatch(setFirstRenderMapToTrueActionCreator())}}>Go back to Dashboard</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
