import { z, object, string } from "zod";

export const userRegistrationSchema = object({
  first_name: string({
    required_error: ("First Name is required"),
  }).min(1, {
    message: ("First Name is required"),
  })
    .refine(value => !/\d/.test(value), {
      message: "First Name must not contain numbers",
    }),

  last_name: string().nullable() // Allow null as well as undefined
    .optional() // Mark as optional (not required)
    .refine(value => {
      if (value) {
        return !/\d/.test(value);
      }
      return true; // if value is null or undefined, it passes
    }, {
      message: "Last Name must not contain numbers",
    }),
  phoneNumber: z.string().nullable().optional().refine(value => {
    // Check if value is null, undefined, or an empty string
    if (!value) return true;
    // Check if value is a string containing exactly 9 digits
    return /^\d{9}$/.test(value);
  }, {
    message: "Phone number must contain exactly 9 digit number.",
  }),

  email: string({
    required_error: ("Email is required"),
  }).min(1, {
    message: ("Email is required"),
  }).email("Please enter a valid email"),
  password: z.string({
    required_error: "Password is required",
  }).min(8, {
    message: "Password must be minimum 8 characters",
  }).refine(value => /[A-Z]/.test(value), {
    message: "Password must contain at least one uppercase letter",
  }).refine(value => /\d/.test(value), {
    message: "Password must contain at least one number",
  }),
  password_confirm: z.string({
    required_error: "Confirm Password is required",
  }).min(1, {
    message: "Confirm Password is required",
  }).min(8, {
    message: "Confirm Password must be minimum 8 characters",
  })
}).refine(data => data.password === data.password_confirm, {
  message: "Passwords don't match",
  path: ["password_confirm"],
});