import { store } from '../redux/store';
import { getStrategiesActionCreator } from '../redux/slices/strategiesSlice/strategiesSlice';

export const useStrategiesService = () => {
  const webAddressPrefixGet: string = process.env.REACT_APP_API_GET_STRATEGIES_LOCAL_BASE_URL as string;
  const webAddressPrefixPost: string = process.env.REACT_APP_API_POST_STRATEGIES_LOCAL_BASE_URL as string;
  const webAddressPrefixDelete: string = process.env.REACT_APP_API_DELETE_STRATEGIES_LOCAL_BASE_URL as string;
  const webAddressPrefixEdit: string = process.env.REACT_APP_API_EDIT_STRATEGIES_LOCAL_BASE_URL as string;
  const webAddressPrefixAssign: string = process.env.REACT_APP_API_ASSIGN_STRATEGIES_LOCAL_BASE_URL as string;

  const getStrategies = async (orgId: number, serviceId: number) => {
    try {
      const response = await fetch(`${webAddressPrefixGet}${orgId}&servid=${serviceId}`, {credentials: 'include'});
      const strategies = await response.json();
      store.dispatch(getStrategiesActionCreator(strategies));

      return true;
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  /* const postStrategy = async (name:any, description:any, color:any, labels: Array<string>, data: Array<any>, orgId: number) => { */
  const postStrategy = async (labels: Array<string>, data: Array<any>) => {
    const values = store.getState();

    const recursiveFunction: any = (labels: Array<string>, data: Array<any>, index: number, result: Array<any>) => {
      if (index === labels.length+1) {
        return result;
      }

      if ((data[index] != undefined) && labels[index].indexOf(":") === -1) {
        let newLabel = labels[index] + ":00";
        result.push(newLabel, data[index]);
      }
      if ((data[index] != undefined) && labels[index].indexOf(":") !== -1) {
        result.push(labels[index], data[index]);
      }

      return recursiveFunction(labels, data, index + 1, result);
    }

    let res = recursiveFunction(labels, data, 0, []);
    if (values.strategies.astroDim) {
      res[0] = "98:98";
      res[res.length-2] = "99:99";
    }
    let results = res.map((el: any) => String(el));

    const body = {
      strgy_name: values.strategies.name,
      description: values.strategies.description,
      color: values.strategies.color.value,
      saving: values.strategies.saving,
      sunset_off: values.strategies.offsetSunset !== "" ? values.strategies.offsetSunset : 0,
      sunrise_off: values.strategies.offsetSunrise !== "" ? values.strategies.offsetSunrise : 0,
      dim1: results[1],
      dim2: results[3],
      dim3: results[5] || "254:254",
      dim4: results[7] || "254:254",
      dim5: results[9] || "254:254",
      dim6: results[11] || "254:254",
      dim7: results[13] || "254:254",
      time1: results[0],
      time2: results[2],  
      time3: results[4] || "254:254",
      time4: results[6] || "254:254",
      time5: results[8] || "254:254",
      time6: results[10] || "254:254",
      time7: results[12] || "254:254",
      organization_id: values.devices.selectedOrganizationId
    }
  
    try {
      let res = await fetch(webAddressPrefixPost, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const deleteStrategy = async (strategyId: any) => {
    try {
      let res = await fetch(`${webAddressPrefixDelete}${strategyId}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //{"strategy_id":"24","result":0}


  /*  {
    "strategy_id": "10",
    "result": 0
  } */

  const editStrategyChart = async (labels: Array<string>, data: Array<any>, strategyId: number) => {
    const values = store.getState();

    const recursiveFunction: any = (labels: Array<string>, data: Array<any>, index: number, result: Array<any>) => {
      if (index === labels.length+1) {
        return result;
      }
      
      if (data[index] != undefined) {
        result.push(labels[index], data[index]);
      }

      return recursiveFunction(labels, data, index + 1, result);
    }
      
    let res = recursiveFunction(labels, data, 0, []);
    if (values.strategies.astroDim) {
      res[0] = "98:98";
      res[res.length-2] = "99:99";
    }
    let results = res.map((el: any) => String(el));

    const body = {
      saving: values.strategies.saving,
      sunset_off: values.strategies.offsetSunset !== "" ? values.strategies.offsetSunset : 0,
      sunrise_off: values.strategies.offsetSunrise !== "" ? values.strategies.offsetSunrise : 0,
      dim1: results[1],
      dim2: results[3],
      dim3: results[5] || "254:254",
      dim4: results[7] || "254:254",
      dim5: results[9] || "254:254",
      dim6: results[11] || "254:254",
      dim7: results[13] || "254:254",
      time1: results[0],
      time2: results[2],  
      time3: results[4] || "254:254",
      time4: results[6] || "254:254",
      time5: results[8] || "254:254",
      time6: results[10] || "254:254",
      time7: results[12] || "254:254",
      organization_id: values.devices.selectedOrganizationId
    }
  
    try {
      let res = await fetch(`${webAddressPrefixEdit}${strategyId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const editStrategyText = async (name: string, color: string, description: string, strategyId: number) => {
    const values = store.getState();
    const body = {
      strgy_name: name,
      description: description,
      color: color,
      organization_id: values.devices.selectedOrganizationId
    }
  
    try {
      let res = await fetch(`${webAddressPrefixEdit}${strategyId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const assignStrategy = async (selectedLampId: Array<string> | any, selectedLampStrategyId: number, orgId: number, serviceId: number) => {
    const body = {
      node_id: selectedLampId,
      strategy_id: selectedLampStrategyId,
      org_id: orgId,
      serv_id: serviceId
    }

    console.log("dentro serv body", body)
  
    try {
      let res = await fetch(`${webAddressPrefixAssign}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
      console.log("dentro serv body", res, res.json())

      //código para que funcione cuando el nodo shuncom está desconectado. Revisar porque no acaba de ir
      /* let timeout = new Promise((resolve, reject) => {
        let id = setTimeout(() => {
          clearTimeout(id);
          reject('La petición se ha excedido de tiempo');
        }, 3000) // 6 segundos
      })
    
      let fetchCall = fetch(`${webAddressPrefixAssign}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });
    
      let res:any = await Promise.race([fetchCall, timeout]);
      console.log("dentro serv body", res, res.json()) */
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getStrategies,
    postStrategy,
    deleteStrategy,
    editStrategyChart,
    editStrategyText,
    assignStrategy
  };
};


