import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useAppSelector } from "../../../redux/hooks";
import { languages } from "../../../utils/languages/languages";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function CurrentStrategyChart() {
  const selectedStrategy = useAppSelector((state) => state.strategies.selectedStrategy);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  
  const times = [selectedStrategy.time1, selectedStrategy.time2, selectedStrategy.time3, selectedStrategy.time4, selectedStrategy.time5, selectedStrategy.time6, selectedStrategy.time7];
  let labels:any = [];

  for (let i = 0; i < times.length; i++) {
    if (times[i] === '254:254' || times[i] === null || times[i] === undefined) {
      if (times[i-1] === "99:99") {
        labels[labels.length - 1] = txt.sunrise;
        break;
      }
      break;
    }
    
    if (times[i] === "98:98") {
      labels.push(txt.sunset);
    } else {
      labels.push(times[i]);
    }
  }

  let dimmings = [selectedStrategy.dim1, selectedStrategy.dim2, selectedStrategy.dim3, selectedStrategy.dim4, selectedStrategy.dim5, selectedStrategy.dim6, selectedStrategy.dim7];
  let newData = dimmings.slice(0, labels.length);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Standard",
        data: newData,
        borderColor: selectedStrategy.color,
        backgroundColor: selectedStrategy.color+"80",
        fill: true,
        stepped: true,
        pointRadius: 3,
        pointBackgroundColor: "white",
        showLine: true,
        spanGaps: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false
      },
    },
    scales: {    
      y: {
        display: true,
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25
        },
      }, 
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3
        },
      },
    },
    dragData: false
  };

  return (
    <Line width={330} height={100} options={options} data={data} />
  )
}

export default CurrentStrategyChart;
