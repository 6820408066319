import { calculateParameters } from '../components/LuminairePage/ElectricalParametersCard/ElectricalParametersLogic';

export const useConsumptionsService = () => {
  const webAddressPrefixGetLight: string = process.env.REACT_APP_API_GET_LIGHT_LOCAL_BASE_URL as string;
  const webAddressPrefixGetLightTwoDates: string = process.env.REACT_APP_API_GET_LIGHT_TWO_DATES_LOCAL_BASE_URL as string;
  const webAddressPrefixGetPower: string = process.env.REACT_APP_API_GET_POWER_LOCAL_BASE_URL as string;
  const webAddressPrefixGetPowerTwoDates: string = process.env.REACT_APP_API_GET_POWER_TWO_DATES_LOCAL_BASE_URL as string;
  const webAddressPrefixGetEnergy: string = process.env.REACT_APP_API_GET_ENERGY_LOCAL_BASE_URL as string;
  const webAddressPrefixGetEnergyTwoDates: string = process.env.REACT_APP_API_GET_ENERGY_TWO_DATES_LOCAL_BASE_URL as string;
  const webAddressPrefixGetSolar: string = process.env.REACT_APP_API_GET_SOLAR_LOCAL_BASE_URL as string;
  const webAddressPrefixGetSolarTwoDates: string = process.env.REACT_APP_API_GET_SOLAR_TWO_DATES_LOCAL_BASE_URL as string;

  //to get consumption, voltage and temperature parameters
  const getLight = async (nodeId: number, selectedDate: any, parameterType: string) => {
    let startUnixTime = selectedDate.getTime() / 1000;

    let date = new Date(selectedDate);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
    let day:any = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    let formattedDate = `${year}-${month}-${day}`;

    try {
      const response = await fetch(`${webAddressPrefixGetLight}${nodeId}&date=${formattedDate}`, {credentials: 'include'});
      const lightInfo = await response.json();
      if (lightInfo.length > 0) {
        await calculateParameters(lightInfo, parameterType, 1, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getLightTwoDates = async (nodeId: number, start: any, end: any, parameterType: string) => {
    let startUnixTime = start.getTime() / 1000;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${webAddressPrefixGetLightTwoDates}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const lightInfo = await response.json();
      if (lightInfo.length > 0) {
        await calculateParameters(lightInfo, parameterType, numDays, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get apparent power, active power and frequency
  const getPower = async (nodeId: number, selectedDate: any, parameterType: string) => {
    let startUnixTime = selectedDate.getTime() / 1000;

    let date = new Date(selectedDate);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
    let day:any = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    let formattedDate = `${year}-${month}-${day}`;

    try {
      const response = await fetch(`${webAddressPrefixGetPower}${nodeId}&date=${formattedDate}`, {credentials: 'include'});
      const powerInfo = await response.json();
      if (powerInfo.length > 0) {
        await calculateParameters(powerInfo, parameterType, 1, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getPowerTwoDates = async (nodeId: number, start: any, end: any, parameterType: string) => {
    let startUnixTime = start.getTime() / 1000;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${webAddressPrefixGetPowerTwoDates}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const powerInfo = await response.json();
      if (powerInfo.length > 0) {
        await calculateParameters(powerInfo, parameterType, numDays, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get apparent energy, active energy and external volt
  const getEnergy = async (nodeId: number, selectedDate: any, parameterType: string) => {
    let startUnixTime = selectedDate.getTime() / 1000;

    let date = new Date(selectedDate);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
    let day:any = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    let formattedDate = `${year}-${month}-${day}`;

    try {
      const response = await fetch(`${webAddressPrefixGetEnergy}${nodeId}&date=${formattedDate}`, {credentials: 'include'});
      const energyInfo = await response.json();
      if (energyInfo.length > 0) {
        await calculateParameters(energyInfo, parameterType, 1, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getEnergyTwoDates = async (nodeId: number, start: any, end: any, parameterType: string) => {
    let startUnixTime = start.getTime() / 1000;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${webAddressPrefixGetEnergyTwoDates}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const energyInfo = await response.json();
      if (energyInfo.length > 0) {
        await calculateParameters(energyInfo, parameterType, numDays, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get solar parameters
  const getSolar = async (nodeId: number, selectedDate: any, parameterType: string) => {
    let startUnixTime = selectedDate.getTime() / 1000;

    let date = new Date(selectedDate);
    let year = date.getFullYear();
    let month:any = date.getMonth() + 1;
    let day:any = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;
    let formattedDate = `${year}-${month}-${day}`;

    try {
      const response = await fetch(`${webAddressPrefixGetSolar}${nodeId}&date=${formattedDate}`, {credentials: 'include'});
      const solarInfo = await response.json();
      if (solarInfo.length > 0) {
        await calculateParameters(solarInfo, parameterType, 1, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getSolarTwoDates = async (nodeId: number, start: any, end: any, parameterType: string) => {
    let startUnixTime = start.getTime() / 1000;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${webAddressPrefixGetSolarTwoDates}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const solarInfo = await response.json();
      if (solarInfo.length > 0) {
        await calculateParameters(solarInfo, parameterType, numDays, startUnixTime);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getLight,
    getLightTwoDates,
    getPower,
    getPowerTwoDates,
    getEnergy,
    getEnergyTwoDates,
    getSolar,
    getSolarTwoDates
  };
};

