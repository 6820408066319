import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolarParameters } from "../../../types/interfaces";

const initialState: SolarParameters = {
  soc: "",
  solarBattVolt: "",
  solarBattCurrent: "",
  lumPowerConsum: "",
  panelChargePower: "",
  timestamps: ""
};

const solarParametersSlice = createSlice({
  name: "solarParameters",
  initialState: initialState,
  reducers: {
    getSolarConsumption: (previousData, action: PayloadAction<SolarParameters>) => ({
      ...previousData,
      soc: action.payload.soc,
      solarBattVolt: action.payload.solarBattVolt,
      solarBattCurrent: action.payload.solarBattCurrent,
      lumPowerConsum: action.payload.lumPowerConsum,
      panelChargePower: action.payload.panelChargePower,
      timestamps: action.payload.timestamps
    }),

    resetSolarParameters: (previousData) => ({
      ...previousData,
      soc: "",
      solarBattVolt: "",
      solarBattCurrent: "",
      lumPowerConsum: "",
      panelChargePower: "",
      timestamps: ""
    }),
  },
});

export const solarParametersReducer = solarParametersSlice.reducer;

export const {
  getSolarConsumption: getSolarConsumptionActionCreator,
  resetSolarParameters: resetSolarParametersActionCreator,
} = solarParametersSlice.actions;
