import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useAppSelector } from "../../../../redux/hooks";
import { languages } from "../../../../utils/languages/languages";
import { CardSimple } from "../../LuminairePageStyled";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import energyBlue from "../../../../assets/new_icons/Energy-blue.svg";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";

interface BatteryProps {
  selectedLamp: any
}

export const Battery = ({ selectedLamp }: BatteryProps) => {
  const [batteryLevel, setBatteryLevel] = useState(selectedLamp.batt_level);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  const batteryStyle:any = {
    width: '70px',
    height: '120px',
    border: '1.5px solid rgba(82, 82, 82, 0.6)', // Color #525252 con 60% de opacidad
    borderRadius: "10px",
    position: 'relative',
    overflow: 'hidden',
  };
  
  const levelStyle:any = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(#00C7F2, #0CB2D6)",
    width: '100%',
    position: 'absolute',
    bottom: '0',
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    height: `${batteryLevel}%`,
  };

  const Shadow = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 4px;
    /* background: linear-gradient(transparent, rgba(0, 0, 255, 0.2), transparent); */
    background: linear-gradient(to right, transparent, #80E0F6, transparent);
  `;

  useEffect(() => {
    setBatteryLevel(selectedLamp.batt_level);
  }, [selectedLamp.batt_level]);

  return (
    <CardSimple style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <div>
          <Text color="#A3AED0">{t("Battery")}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", /* height: 0, zIndex: 6,  */justifyContent: "center", gap: "0.5px", top: "48px", /* left: 2, position: "relative" */}}>
        <div style={{width: '30px', height: '6px', border: '1.5px solid #525252', opacity: "0.6", borderTopLeftRadius: "12px", borderTopRightRadius: "12px", position: 'relative'}} />
        <div style={batteryStyle}>
          <div style={levelStyle}>
          {/*  tocar el position del text y el textspan. Zindex? */}
            <Text color="white" fontWeight="500" marginBottom="0" style={{ position: "absolute", bottom: 50, color: batteryLevel < 57 ? "#475569" : "white" }}>{!batteryLevel && batteryLevel !== 0 ? '\"\"': batteryLevel}%</Text>
            <TextSpan color="white" marginBottom="0" style={{ textAlign: "center", fontSize: "10px", position: "absolute", color: batteryLevel < 10 ? "#475569" : "white", bottom: 1 }}>{!selectedLamp.batt_voltage && selectedLamp.batt_voltage !== 0 ? '\"\"' : selectedLamp.batt_voltage % 1 !== 0 ? Math.round(selectedLamp.batt_voltage).toFixed(2) : selectedLamp.batt_voltage}W</TextSpan>
          </div>
          {/* <Shadow /> */}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px", gap: "3px" }}>
        <img src={energyBlue} width={14} />
        <Text marginBottom="0">{!selectedLamp.batt_status ? '\"\"' : selectedLamp.batt_status}</Text>
      </div>      
    </CardSimple>
  );


  /* const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();

  return (
    <CardSimple>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color="#A3AED0">{t(txt.battery)}</Text>
        </div>
        <div
          style={{ textAlign: "right", marginTop: "-2px" }}
        >
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <div style={{ display: "flex", height: 0, zIndex: 6, justifyContent: "center", top: "54px", left: 2, position: "relative" }}>
        <Text fontWeight="500">{selectedLamp.batt_level}%</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <BatteryChart />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        <Text fontWeight="500" style={{ height: "10px" }}>
          {t(txt.charging)}
        </Text>
        <TextSpan color="#A3AED0" textType="caption" style={{ height: "10px" }}>
          15.3W {t(txt.power)}
        </TextSpan>
      </div>
    </CardSimple>
  ); */
};
