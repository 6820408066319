import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { store } from "../../redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getGroupsAction } from "../../redux/actions/groupsActions/groupsActions";
import { fetchUserProfileAction } from "../../redux/actions/profileActions/profileActions";
import { changeOrgsPopUpActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { resetGroupsActionCreator } from "../../redux/slices/groupsSlice/groupsSlice";
import { logout } from "../../redux/slices/logoutSlice/logoutSlice";
import { resetManagedUserActionCreator } from "../../redux/slices/managedUsers/managedUserSlice";
import {
  setFirstRenderMapToTrueActionCreator,
  setIsOpenedDetailActionCreator,
  setIsOpenedGroupActionCreator,
  setLayoutFalseActionCreator,
  setNodesInAreaActionCreator,
  turnRgbFalseActionCreator,
} from "../../redux/slices/uiSlice/uiSlice";
import { useGroupsService } from "../../services/groupsService";
import { useLightingService } from "../../services/lightingService";
import { useProfileService } from "../../services/profileService";
import { useOrganizationsService } from "../../services/selectedOrganizationService";
import { useUsersService } from "../../services/usersService";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { UserAvatar } from "../../utils/reusableComponents";
import {
  HeaderStyled,
  HeaderTitleStyled,
  IconsListStyled,
  IconStyled,
  LogoStyled,
  NavStyled,
  SpacerStyled,
  DropdownContainer,
  ProfilePicture,
  DropdownItem,
  IconProfileStyled,
} from "./HeaderStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import company from "../../assets/new_icons/Company.svg";
import help from "../../assets/new_icons/Help.svg";
import logoSalvi from "../../assets/new_icons/logoSalvi.svg";
import logoutIcon from "../../assets/new_icons/Log out.svg";
import manageUsers from "../../assets/new_icons/Manage users.svg";
import organizationsIcon from "../../assets/new_icons/Organizations - Filled.svg";
import question from "../../assets/question.svg";
import userPicture from "../../assets/new_icons/Profile.svg";

interface UserProps {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  role_name: string;
  color: string;
}

interface HeaderProps {
  profileImg: string;
  alerts: boolean;
  user: UserProps;
}

export const Header = ({ profileImg, alerts, user }: HeaderProps) => {
  const token: any = Cookies.get("token");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const popUpChangeOrg = useAppSelector(
    (state) => state.graphics.organizationsPopUp
  );
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const selectedOrgId = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedNodeId = useAppSelector((state) => state.manageNode.node_id);
  const userName = useAppSelector((state) => state.user.first_name);
  const { t } = useTranslation();

  const { getAllNodes }: any = useLightingService();
  const { getUserProfile } = useProfileService();
  const { getGroups } = useGroupsService();
  const { getUsers } = useUsersService();
  const { getSelectedOrganizationInfo }: any = useOrganizationsService();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [openRefresh, setOpenRefresh] = useState(false);
  const [opacityOrgs, setOpacityOrgs] = useState(0.5);
  const [showLoader, setShowLoader] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;
  const isDashboardPage = pathname === "/dashboard";
  const isStrategiesPage = pathname === "/strategies";
  const isProfilePage = pathname === "/profile";
  const isUsersPage = pathname === "/users";
  const isLuminairePage = pathname === `/luminaire/${selectedNodeId}`;
  const handleLogout = () => {
    dispatch(logout());
    Cookies.remove("isLoggedIn");
    localStorage.removeItem("selectedLanguage");
    window.location.href = "/";
  };

  const refresh = async () => {
    try {
      let res = await fetch(
        `${process.env.REACT_APP_API_LOCAL_BASE_URL}/gateway/update_software?orgId=1&servId=1`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const handleOrganizationSelection = async (org: any) => {
    dispatch(resetGroupsActionCreator());
    dispatch(setNodesInAreaActionCreator(false));
    setShowLoader(true);
    try {
      await getAllNodes(org.id, selectedServiceId, organizations, services);
      let getGroupsResponse = await getGroups(org.id, selectedServiceId);
      if (getGroupsResponse) {
        store.dispatch(getGroupsAction({ groups: getGroupsResponse }));
      } else {
        console.log("error in getGroups api");
        return false;
      }
      await getSelectedOrganizationInfo(org.id, selectedServiceId);
    } catch (error) {
      // Handle errors if any
      console.error("Error fetching organization data:", error);
      setShowLoader(false);
    } finally {
      setShowLoader(false); // Set loading to false after API calls are completed
    }
    dispatch(changeOrgsPopUpActionCreator());
    dispatch(turnRgbFalseActionCreator());
    dispatch(setFirstRenderMapToTrueActionCreator());
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = await getUserProfile(token);
      if (user) {
        store.dispatch(fetchUserProfileAction(user));
      }
    };

    fetchUserProfile();
  }, [token]);

  useEffect(() => {
    organizations?.map((org: any) => {
      if (selectedOrgId === org.id) {
        setName(org.organization_name);
      }
    });

    const handleClickOutsideOrgs = (event: MouseEvent) => {
      if (
        popUpChangeOrg &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        dispatch(changeOrgsPopUpActionCreator());
      }
    };

    const handleClickOutsideProfile = (event: MouseEvent) => {
      if (
        isOpen &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("picture-class")
      ) {
        setIsOpen(!isOpen);
      }
    };

    const handleClickOutsideRefresh = (event: MouseEvent) => {
      if (
        openRefresh &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setOpenRefresh(!openRefresh);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideOrgs);
    document.addEventListener("mousedown", handleClickOutsideProfile);
    /* document.addEventListener("mousedown", handleClickOutsideRefresh); */
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOrgs);
      document.removeEventListener("mousedown", handleClickOutsideProfile);
      /* document.removeEventListener("mousedown", handleClickOutsideRefresh); */
    };
  }, [selectedOrgId, dispatch, popUpChangeOrg, isOpen, openRefresh]);

  return (
    <HeaderStyled className="header">
      <LoaderModal showLoader={showLoader} />
      <NavStyled>
        {!isProfilePage && !isUsersPage ? (
          <LogoStyled src={company} alt="smartec logo" />
        ) : (
          <></>
        )}
        <HeaderTitleStyled>
          {isProfilePage ? (
            <TextBox
              textType="subTitle"
              fontWeight="600"
              style={{ marginLeft: "-20px" }}
              className="icon-class"
            >
              {t("welcomeBack", { userName })}
            </TextBox>
          ) : isDashboardPage ? (
            <TextBox
              textType="subTitle"
              fontWeight="600"
              className="icon-class"
              /* onClick={() => {
                  setOpenRefresh(!openRefresh);
                }} */
            >
              {t("welcomeTo", { name })}
            </TextBox>
          ) : isUsersPage ? (
            <TextBox
              textType="subTitle"
              fontWeight="600"
              style={{ marginLeft: "-20px" }}
              className="icon-class"
            >
              {t("manageYourUsers")}
            </TextBox>
          ) : (
            <TextBox
              textType="subTitle"
              fontWeight="600"
              className="icon-class"
            >
              {name}
            </TextBox>
          )}
        </HeaderTitleStyled>
        {openRefresh && (
          <article
            style={{
              position: "absolute",
              top: "18px",
              left: "410px",
              width: "190px",
              maxHeight: "336px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
              boxShadow:
                "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
              zIndex: 3,
            }}
          >
            <style>
              {`
                .refresh:hover {
                  background-color: #1c1b1f11;
                }
              `}
            </style>
            <span
              className="refresh"
              onClick={async () => {
                refresh();
              }}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                isolation: "isolate",
                paddingLeft: "15px",
                paddingRight: "6px",
                width: "100%",
                minHeight: "56px",
                cursor: "pointer",
                textAlign: "left",
              }}
            >
              {t("Refresh")}
            </span>
          </article>
        )}
        <SpacerStyled></SpacerStyled>
        <IconsListStyled>
          {/* <LanguageSelector /> */}
          {(isDashboardPage || isStrategiesPage) &&
            organizations.length > 1 && (
              <IconStyled
                className="icon-class"
                src={organizationsIcon}
                style={{ opacity: opacityOrgs }}
                onMouseEnter={() => setOpacityOrgs(1)}
                onMouseLeave={() => setOpacityOrgs(0.5)}
                onClick={() => {
                  dispatch(changeOrgsPopUpActionCreator());
                  dispatch(setIsOpenedDetailActionCreator(false));
                  dispatch(setIsOpenedGroupActionCreator(false));
                }}
              />
            )}
          {popUpChangeOrg && (
            <article
              style={{
                position: "absolute",
                top: "60px",
                right: "98px",
                width: "190px",
                maxHeight: "336px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                boxShadow:
                  "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
                zIndex: 3,
              }}
            >
              {organizations?.map((org: any) => (
                <>
                  <style>
                    {`
                      .org-span:hover {
                        background-color: #1c1b1f11;
                      }
                    `}
                  </style>
                  <span
                    className="org-span"
                    key={org.id}
                    onClick={() => handleOrganizationSelection(org)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      isolation: "isolate",
                      paddingLeft: "15px",
                      paddingRight: "6px",
                      width: "100%",
                      minHeight: "56px",
                      cursor: "pointer",
                      backgroundColor:
                        org.id === selectedOrgId ? "#1c1b1f11" : "",
                      textAlign: "left",
                    }}
                  >
                    {org.organization_name}
                  </span>
                </>
              ))}
            </article>
          )}
          {/* TODO: To be add later */}
          {/* <IconStyled src={globe}></IconStyled> */}
          {/* <IconStyled src={question}></IconStyled> */}
          <DropdownContainer
            style={{ marginLeft: "18px", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <UserAvatar
              firstName={user.first_name}
              profileColor={user.color}
              type={"header"}
            />
          </DropdownContainer>
          {isOpen && (
            <article
              style={{
                position: "absolute",
                top: "75%",
                left: "92.35%",
                transform: "translateX(-50%)",
                backgroundColor: "#fff",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: "4px",
                paddingBottom: "8px",
                zIndex: 10,
                width: "234px",
              }}
            >
              <div
                className="row"
                style={{
                  cursor: "pointer",
                  paddingLeft: "12px",
                  paddingRight: "36px",
                  paddingTop: "24px",
                  paddingBottom: "4px",
                  fontSize: "14px",
                  textAlign: "left",
                }}
                onClick={async () => {
                  setIsOpen(!isOpen);
                  dispatch(setLayoutFalseActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/profile");
                }}
              >
                <div
                  className="col-4"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <UserAvatar
                    firstName={user.first_name}
                    profileColor={user.color}
                    type={"list"}
                  />
                </div>
                <div
                  className="col-8"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginBottom: "1px",
                    marginLeft: "-16px",
                    maxWidth: "160px",
                    wordWrap: "break-word",
                  }}
                >
                  <TextSpan marginBottom="0px">
                    {user.first_name} {user.last_name}{" "}
                  </TextSpan>
                  <TextSpan textType="caption" color="#A3AED0">
                    {user.email}
                  </TextSpan>
                </div>
              </div>
              <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <hr />
              </div>
              <DropdownItem
                onClick={() => {
                  setIsOpen(!isOpen);
                  dispatch(setLayoutFalseActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/profile");
                }}
                style={{ cursor: "pointer" }}
              >
                <IconProfileStyled
                  src={userPicture}
                  style={{ marginRight: "18px" }}
                />
                <TextSpan>{t("Profile Details")}</TextSpan>
              </DropdownItem>
              <DropdownItem
                onClick={async () => {
                  dispatch(resetManagedUserActionCreator());
                  //const token:any = Cookies.get("token");
                  //await getUsers(token);
                  setIsOpen(!isOpen);
                  dispatch(setLayoutFalseActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/users");
                }}
                style={{ cursor: "pointer" }}
              >
                <IconProfileStyled
                  src={manageUsers}
                  style={{ height: "20px", marginRight: "14px" }}
                />
                <TextSpan>{t("manageUsers")}</TextSpan>
              </DropdownItem>
              <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <hr />
              </div>
              {/* <DropdownItem>
                <IconProfileStyled src={help} style={{ marginRight: "18px" }} />
                <TextSpan>{t("Help Center")}</TextSpan>
              </DropdownItem> */}
              <DropdownItem
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <IconProfileStyled
                  src={logoutIcon}
                  style={{ marginRight: "18px" }}
                />
                <TextSpan>{t("Log Out")}</TextSpan>
              </DropdownItem>
            </article>
          )}
        </IconsListStyled>
      </NavStyled>
    </HeaderStyled>
  );
};
