import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { managedUser } from "../../../types/interfaces";

const initialState: managedUser = {
  id: "",
  color: "",
  name: "",
  surname: "",
  number: "" || null,
  email: "",
  language: "",
  role: "",
  active: "",
  orgs: [],
  services: [],
  groups: [],
};

const managedUserSlice = createSlice({
  name: "managedUser",
  initialState: initialState,
  reducers: {
    saveUserPersonalInfoFromUsersList: (previousState, action: PayloadAction<managedUser>) => ({
      id: action.payload.id,
      color: action.payload.color,
      name: action.payload.name,
      surname: action.payload.surname,
      number: action.payload.number,
      email: action.payload.email,
      language: action.payload.language,
      role: action.payload.role,
      active: action.payload.active
    }),

    getUserPersonalInfo: (previousState, action: PayloadAction<managedUser>) => ({
      ...action.payload,
      color: previousState.color,
      orgs: previousState.orgs,
      services: previousState.services,
      groups: previousState.groups
    }),

    getUserOrgs: (previousState, action: PayloadAction<managedUser>) => ({
      ...previousState,
      orgs: action.payload.orgs,
    }),

    getUserServices: (previousState, action: PayloadAction<managedUser>) => ({
      ...previousState,
      services: action.payload.services,
    }),

    getUserGroups: (previousState, action: PayloadAction<managedUser>) => ({
      ...previousState,
      groups: action.payload.groups,
    }),

    resetManagedUser: (previousState) => ({
      ...previousState,
      id: "",
      color: "",
      name: "",
      surname: "",
      number: "" || null,
      email: "",
      language: "",
      role: "",
      active: "",  
      orgs: [],
      services: [],
      groups: []
    }),
  },
});


export const managedUserReducer = managedUserSlice.reducer;;

export const {
  saveUserPersonalInfoFromUsersList: saveUserPersonalInfoFromUsersListActionCreator,
  getUserPersonalInfo: getUserPersonalInfoActionCreator,
  getUserOrgs: getUserOrgsActionCreator,
  getUserServices: getUserServicesActionCreator,
  getUserGroups: getUserGroupsActionCreator,
  resetManagedUser: resetManagedUserActionCreator
} = managedUserSlice.actions;
