import { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import CustomMarker from "../CustomMarker/CustomMarker";
import { MapEventHandler } from "../../MapEventHandler/MapEventHandler";
import { CustomMapStyled } from "./CustomMapStyled";
import "../../../styles/index.css";
import { Lamp } from "../../../types/interfaces";
import { getAllpositions } from "../../../utils/auxiliaryFunctions";
import { initialLamp, initialLampsGroup } from "../../../utils/data";

interface GroupsModalMapProps {}

export const GroupsModalMap = ({}: GroupsModalMapProps) => {
  const devices = useAppSelector((state) => state.filteredDevices);
  const lamps = devices.nodesInArea.filter((lamp: any) => lamp.latitude);

  const [map, setMap] = useState(0);
  const [selectedLamp, setSelectedLamp] = useState<string>(initialLamp.node_id);
  const [nodesGroup, setNodesGroup] = useState<Array<string>>(
    initialLampsGroup.group
  );
  const { t } = useTranslation();

  let filteredLamps: Lamp[] = lamps?.filter((lamp: any) => {
    return lamp;
  });

  const calculateCenter = (devices: any) => {
    let minLat = devices[0].latitude;
    let maxLat = devices[0].latitude;
    let minLng = devices[0].longitude;
    let maxLng = devices[0].longitude;

    devices.forEach((device: any) => {
      minLat = Math.min(minLat, device.latitude);
      maxLat = Math.max(maxLat, device.latitude);
      minLng = Math.min(minLng, device.longitude);
      maxLng = Math.max(maxLng, device.longitude);
    });

    return {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };
  };

  const center = calculateCenter(
    devices.nodesInArea.filter((lamp: any) => lamp.latitude)
  );

  const calculateMaxDistance = (devices: any) => {
    let maxDistance = 0;
    devices.forEach((device1: any, index1: any) => {
      devices.slice(index1 + 1).forEach((device2: any) => {
        const distance = Math.sqrt(
          Math.pow(device1.latitude - device2.latitude, 2) +
            Math.pow(device1.longitude - device2.longitude, 2)
        );
        maxDistance = Math.max(maxDistance, distance);
      });
    });
    return maxDistance;
  };

  const maxDistance = calculateMaxDistance(
    devices.nodesInArea.filter((lamp: any) => lamp.latitude)
  );
  const zoom =
    maxDistance < 0.01
      ? 17
      : maxDistance < 0.05
      ? 13
      : maxDistance < 0.5
      ? 10
      : 8;

  return (
    <CustomMapStyled
      id="map-container"
      style={{ position: "absolute", width: "100%", height: "100%" }}
    >
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={zoom}
        dragging={false}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        boxZoom={false}
        keyboard={false}
        zoomControl={false}
        attributionControl={false}
      >
        <TileLayer
          key={"map layer"}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapEventHandler
          positions={getAllpositions(filteredLamps)}
          setNodesGroup={setNodesGroup}
          lamps={filteredLamps}
          key={"map events handler"}
          setMapBounds={setMap}
        ></MapEventHandler>

        <MarkerClusterGroup
          chunkedLoading
          onClick={() => {}}
          maxClusterRadius={0}
          removeOutsideVisibleBounds
          disableClusteringAtZoom={15}
          spiderfyOnMaxZoom={false}
          zoom={10}
        >
          {filteredLamps?.map((lamp) => (
            <CustomMarker
              interactive={false}
              setNodesGroup={setNodesGroup}
              key={lamp.node_id + Math.random().toString()}
              lamp={lamp}
              setMarker={setSelectedLamp}
              selected={selectedLamp === lamp.node_id}
              editable={false}
              center={
                new LatLng(
                  Number.parseFloat(lamp.latitude),
                  Number.parseFloat(lamp.longitude)
                )
              }
            />
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </CustomMapStyled>
  );
};
