import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {
  getGroupsActionCreator,
  resetGroupInfoActionCreator,
} from "../../../../redux/slices/groupsSlice/groupsSlice";
import {useState} from 'react'
import back from "../../../../assets/new_icons/Back_2.svg";
import { GroupsModalMap } from "../../../CustomMaps/CustomMap/GroupsModalMap";
import { useGroupsService } from "../../../../services/groupsService";
import {
  CardContainer,
  ButtonStrategy,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { IconStyled, LogoStyled } from "../../../Header/HeaderStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import luminaireIcon from "../../../../assets/new_icons/luminaire detailCard.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import { store } from "../../../../redux/store";
/* import {
  getGroupAction,
  postGroupAction,
} from "../../../../redux/actions/groupActions/groupActions"; */
import { Select, notification } from "antd";
import Language from "../../../../assets/new_icons/Language.svg";
import Company from "../../../../assets/new_icons/Company.svg";
import { changeStepActionCreator, resetUserActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { items } from "../../../../utils/languages/changeLanguage";
import React from "react";
import { registerUser } from "../../../../services/userRegistration";
import { roleMapping } from "../../../../utils/roleMapping";
import { LoaderModal } from "../../../LoaderModal/LoaderModal";

interface ReviewProps {
  onHide?: any;
  setShowDetails?: any;
  setShowDevices?: any;
  showDevices?: any;
  setShowReview?: any;
  setShowModal: any;
}

export const Review = ({
  onHide,
  setShowDetails,
  setShowDevices,
  showDevices,
  setShowReview,
  setShowModal
}: ReviewProps) => {
  const { getGroups, postGroup }: any = useGroupsService();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.groups.name);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const services = useAppSelector((state) => state.devices.services);
  const { organizations } = useAppSelector((state) => state.devices);
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const [loading, setLoading] = useState(false);
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { user, servicePermits } = useAppSelector((state) => state.userRegistration)
  const serviceName = services.map((service: any, index: any) => {
    if (service.service_id === selectedServiceId) {
      return service.service_name;
    }
  });
  const selectedLanguage = items.find(item => item.id === user?.user_language);
  const devices = useAppSelector((state) => state.filteredDevices.nodes);
  const { t } = useTranslation();

  const sendInfo = async () => {
    setLoading(true);
    const data = await registerUser(user)
    if (data) {
      dispatch(resetUserActionCreator())
       notification.success({
        message: "User registered successfully",
        description: null
      });
      setShowModal(false);
    } else {
       notification.error({
        message: "User registration failed",
        description: null
      })
    }
   setLoading(false);
  };

  return (
    <CardContainer
      style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}
    >
      <div style={{ marginBottom: "40px" }}>
        {/* <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{txt.step3}</Text> */}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginLeft: "-31px",
            marginTop: "-40px",
            marginBottom: "30px",
            fontSize: "12px",
            fontWeight: "400",
            color: "#BDBDBD",
            cursor: "pointer",
          }}
          onClick={() => dispatch(changeStepActionCreator(4))}
        >
          <img src={back} alt="back" style={{ marginRight: "10px" }} />
          <span>Back to Service Permits</span>
        </div>
        <Text
          fontWeight="500"
          style={{ marginBottom: "4px", fontSize: "14px" }}
        >
          {t("Step 5")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("reviewConfiguration")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("reviewConfigurationDescription")}
        </Text>
      </div>

      <div className="d-flex align-items-start">
        <div className="me-5">
          <div className="mb-3">
            <span
              className="d-block mb-1"
              style={{ fontSize: "14px", fontWeight: "500", color: "#475569" }}
            >
              General Details
            </span>
          </div>
          <div
            className="card px-3 py-3 d-flex justify-content-between flex-row align-items-center"
            style={{
              borderRadius: "10px",
              boxShadow: "0 3px 6px #d6d5d1",
              border: "0px",
              marginBottom: "33px",
            }}
          >
            <div
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "100%",
                overflow: "hidden",
              }}
            >
              <img
                src={Language}
                alt="flag"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="flex-grow-1 mx-4">
              <h6
                className="mb-0"
                style={{ fontSize: "14px", fontWeight: "600" }}
              >
                {user?.Name} {user?.lastName}
              </h6>
              <div className="d-flex align-items-center">
                <p className="mb-0 me-3">
                  <img src={Company} alt="company" />
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "#A3AED0",
                      marginLeft: "3px",
                    }}
                  >
                    {user?.email}
                  </span>
                </p>
                <p className="mb-0 me-2">
                  <img src={Company} alt="maps" />
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "10px",
                      color: "#A3AED0",
                      marginLeft: "3px",
                    }}
                  >
                    {user?.phoneNumber}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="me-5">
            <div className="mb-3">
              <span
                className="d-block mb-1"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#475569",
                }}
              >
                Roles
              </span>
            </div>
            <div
              className="card px-3 py-3 d-flex justify-content-between flex-row align-items-center"
              style={{
                borderRadius: "10px",
                boxShadow: "0 3px 6px #d6d5d1",
                border: "0px",
                marginBottom: "33px",
              }}
            >
              <div className="mx-2">
                <h6
                  className="mb-0"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#A3AED0",
                  }}
                >
                  {Object.keys(roleMapping).find(
                    key => roleMapping[key] === user?.role_id
                  )}
                </h6>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <span
                className="d-block mb-1"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#475569",
                }}
              >
                Language
              </span>
            </div>
            <div
              className="card px-3 py-3 d-flex justify-content-between flex-row align-items-center"
              style={{
                borderRadius: "10px",
                boxShadow: "0 3px 6px #d6d5d1",
                border: "0px",
                marginBottom: "33px",
              }}
            >
              <div className="mx-2 d-flex align-items-center">
                <img
                  src={Company}
                  alt="lanugageIcon"
                  width={20}
                  height={20}
                  style={{ borderRadius: "100%" }}
                />
                <h6
                  className="mb-0 ms-3"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#A3AED0",
                  }}
                >
                  {selectedLanguage ? selectedLanguage?.label : 'N/A'}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div> 
      {<LoaderModal showLoader={loading}/>}

      <div>
        <div className="me-5">
          <div className="mb-3">
            <span
              className="d-block mb-1"
              style={{ fontSize: "14px", fontWeight: "500", color: "#475569" }}
            >
              Permissions
            </span>
          </div>
          <div style={{ height: "250px", overflow: "hidden", overflowY: "auto" }}>
            {organizations
              .filter((permit: any) => user.organization_id === permit.id)
              .map((permit: any, index: any) => (
                <div key={index} className="card px-3 py-3 d-flex justify-content-between flex-row align-items-center" style={{ borderRadius: "10px", boxShadow: "0 3px 6px #d6d5d1", border: "0px", marginBottom: "33px" }}>
                  <div style={{ height: "40px", width: "40px", borderRadius: "100%", overflow: "hidden" }}>
                    <img src={Language} alt="flag" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  </div>
                  <div className="flex-grow-1 mx-4">
                    <h6 className="mb-0" style={{ fontSize: "14px", fontWeight: "600" }}>{permit.organization_name}</h6>
                    <div className="d-flex align-items-center">
                      {permit.services.map((service: any, i: any) => (
                        <React.Fragment key={i}>
                          <p className="mb-0 me-3">
                            <img src={Company} alt="company" />
                            <span style={{ fontWeight: "400", fontSize: "10px", color: "#A3AED0", marginLeft: "3px" }}>{service.service_name}</span>
                          </p>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <ButtonStrategy
        buttonType="primary"
        style={{
          justifySelf: "end",
          alignSelf: "center",
          width: "300px",
          marginTop: "30px",
          marginLeft: "-40px",
          padding: "8px 32px",
          lineHeight: "none",
        }}
        onClick={() => {
          sendInfo();
        }}
        disabled={loading}
      >
        {t("create")}
      </ButtonStrategy>
    </CardContainer>
  );
};
