import { useTranslation } from "react-i18next";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useLightingService } from "../../../services/lightingService";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { useEffect, useState } from "react";

interface RGBCardProps {
  selectedLamp: any
}

export const RGBCard = ({ selectedLamp }: RGBCardProps) => {
  const [defaultColor, setDefaultColor] = useState("");
  const [color, setColor] = useColor(defaultColor ||"#00ff25");

  const { sendCommandToLuminaire } = useLightingService();
  const { t } = useTranslation();

  const handleRgb = async (order: number) => {
    let parseredColor = {
      r: Math.round(color.rgb.r),
      g: Math.round(color.rgb.g),
      b:Math.round(color.rgb.b)
    }

    try {
      let result: any = await sendCommandToLuminaire([selectedLamp], order, 0, parseredColor);
    } catch (error) {
      alert(error);
    }
  }

  const rgbToHex = (r:any, g:any, b:any) => {
    return "#" + [r, g, b].map(x => {
      const hex = x?.toString(16);
      return hex?.length === 1 ? "0" + hex : hex;
    }).join('');
  }

  useEffect(() => {
    const resultColor = rgbToHex(selectedLamp.R, selectedLamp.G, selectedLamp.B);
    setDefaultColor(resultColor)
  }, []);

  return (
    <div className="card" style={{ padding: "16px 24px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "16px", flexGrow: 1 }}>
      <Text color="#A3AED0">{t("RGB")}</Text>
      <ColorPicker height={120} color={color} onChange={setColor} hideAlpha={true} hideInput={["hsv"]} />
      <div style={{ paddingRight: "15px", paddingLeft: "15px", marginTop: "14px" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: "36px" }}>
          <button onClick={() => handleRgb(41)} disabled={selectedLamp.online === "0" ? true : false}
            style={{ padding: "0", backgroundColor: selectedLamp.online === "0" ? "rgb(229, 229, 229)" : selectedLamp.on === "0" ? "#525252" : "transparent", fontWeight: "500",
              borderLeft: "1px solid rgb(171, 171, 171, 0.5)", borderRight: "0.5px solid rgb(171, 171, 171, 0.5)", borderTop: "1px solid #c4c4c4", borderBottom:"1px solid #c4c4c4",
              flex: "0.5", fontSize: "15px", color: selectedLamp.online === "0" ? "rgb(171, 171, 171)" : selectedLamp.on === "0" ? "#ffffff" : "#979797", height: "100%",
              transform: selectedLamp.online !== "0" ? selectedLamp.on === "0" ? "scale(1.03)" : "" : "", cursor: selectedLamp.online === "0" ? "not-allowed" : "pointer",
              borderRadius: "7px 0 0 7px", boxShadow: selectedLamp.online === "0" ? "3px 8px 4px 0px rgb(0 0 0 / 15%)" : "0px 4px 8px 3px rgb(0 0 0 / 15%)"
            }}  
          >
            {t("OFF")}
          </button>
          <button onClick={() => handleRgb(40)} disabled={selectedLamp.online === "0" ? true : false}
            style={{ padding: "0", backgroundColor: selectedLamp.online === "0" ? "rgb(229, 229, 229)" : selectedLamp.on === "0" ? "transparent" : color.hex, fontWeight: "500",
              borderLeft: "0.5px solid rgb(171, 171, 171, 0.5)", borderRight: "1px solid rgb(171, 171, 171, 0.5)", borderTop: "1px solid #c4c4c4", borderBottom: "1px solid #c4c4c4",
              flex: "0.5", fontSize: "15px", color: selectedLamp.online === "0" ? "rgb(171, 171, 171)" : selectedLamp.on === "0" ? "#979797" : "#ffffff", height: "100%",
              transform: selectedLamp.online !== "0" ? selectedLamp.on === "1" ? "scale(1.03)" : "" : "", cursor: selectedLamp.online === "0" ? "not-allowed" : "pointer",
              borderRadius: "0 7px 7px 0", boxShadow: selectedLamp.online === "0" ? "3px 8px 4px 0px rgb(0 0 0 / 15%)" : "0px 4px 8px 3px rgb(0 0 0 / 15%)"
            }} 
          >
            {t("ON")}
          </button>
        </div>
      </div>
    </div>
  );
};
