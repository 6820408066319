import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import { Marker } from "react-leaflet";
import { useAppSelector  } from "../../../redux/hooks";
import { getIcon } from "../../../utils/icons";
import { CustomMapStyled } from "./CustomMapStyled";
import "../../../styles/index.css";

interface MiniMapProps {
  center: LatLng;
  mapSize: React.CSSProperties;
  selectedLamp: any
}

export const MiniMap = ({
  center,
  mapSize,
  selectedLamp
}: MiniMapProps) => {

  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);

  let latitude = Number.parseFloat(selectedLamp.latitude);
  let longitude = Number.parseFloat(selectedLamp.longitude);
  const lampStatus = selectedLamp.online === "1" ? (selectedLamp.on === "1" ? "on" : "off") : "disconnected";
  //const icon = getIcon(lampStatus, node.selected);
  const icon = getIcon(lampStatus, false);
  
  return (
    <CustomMapStyled id="map-container" style={!fullView && !hybridView ? { position: "absolute", top: 0, left: 0, width: "100%", height: "100%" } : { position: "relative" }}>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={17}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        attributionControl={false}
      >
        <TileLayer
          key={"map layer"}
          //url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          key={selectedLamp.node_id}
          position={[latitude, longitude]}
          riseOnHover
          riseOffset={100}
          icon={icon}
        />
      </MapContainer>
    </CustomMapStyled>
  );
};
