import { CardContainerResponsePage } from "./ResponsePageStyled";
import error401 from "../../assets/new_icons/401-unauthorized.png";
import { Text } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";

export const DisabledPage = () => {
  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={error401} />
          <Text
            textType="title"
            color="#1B2559"
            fontWeight="700"
          >
            Disabled in demo mode
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary">Go back to Dashboard</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
