import { CardContainerResponsePage } from "./ResponsePageStyled";
import notFound from "../../assets/404.svg";
import { Text } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";

export const NotFoundPage = () => {
  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={notFound} />
          <Text
            textType="title"
            color="#1B2559"
            fontWeight="700"
            marginBottom="0px"
          >
            Page not found
          </Text>
          <Text textType="subTitle" color="#1B2559" fontWeight="400">
            We can't find the page you're looking for.
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary">Go back to Dashboard</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
