import styled from "styled-components";

export const ErrorPopUpStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px;
  width: 440px;
  height: fit-content;
  border-radius: 14px;
  background-color: #F3F4F6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  .popup {
    &__button-container {
      display: flex;
      align-self: flex-end;
    }

    &__body-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 14px 18px;
      padding-top: 2px;
      padding-right: 20px;

      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 13px;
        width: 310px;
        height: fit-content;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

        &__title {
          display: flex;
          justify-content: space-between;
          width: 286px;
          margin-bottom: 10px;
        }

      }

      &__button {
        cursor: pointer;
        display: flex;        
        margin-top: 20px;
        border-radius: 8px;
        align-items: center;
        padding: 8px 18px 8px 18px;
        gap: 8px;
        height: 40px;
        font-weight: 600;
        background: #ffc107;
        color: #1b2559;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;
