import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Client } from "paho-mqtt";
import { store } from "../redux/store";
import { updateNodeDeviceStatusReduxActionCreator } from "../redux/slices/devices/filteredDevicesSlice";
import { updateNodeDeviceStatusGlobalReduxActionCreator } from "../redux/slices/devices/devicesSlice";
import { turnBackgroundErrorPopUpTrueActionCreator } from "../redux/slices/graphicsSlice/graphicsSlice";
import { deleteIdsActionCreator } from "../redux/slices/mqttSlice/mqttSlice";
import { updateNodeStatusReduxActionCreator, updateSolarConsumsActionCreator } from "../redux/slices/nodeSlice/manageNodeSlice";
import { setIsStaticViewActionCreator } from "../redux/slices/uiSlice/uiSlice";
import { logout } from "../redux/slices/logoutSlice/logoutSlice";

function processResponse(orgId: string, nodeId: string, response: string) {
  console.log("La Organización: ", orgId);
  if (nodeId === undefined) console.log("Te has olvidado de poner el nodeId");
  else console.log("El nodo: ", nodeId);
  try {
    const responseJSON = JSON.parse(response); //responseJSON.r = response responseJSON.o = order responseJSON.n = nodeId
    // Now that we have all the information, it's time to process the response
    // 1. Check if there everything went well
    if (responseJSON.r === 0) {
      // 2. If everything it's ok, update Redux store
    }

    // 3. Find in our calling stack if the nodeId of the response is generated by ours.
  } catch (error) {
    console.log("There is an error in the received information: ", error);
  }
}

export function MyComponent() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleConnectionLost = () => {
      console.log(
        "Hemos entrado aquí porque hemos perdido la conexión con el broker"
      );
      navigate("/");
    };

    // Escuchar el evento personalizado
    window.addEventListener("mqttConnectionLost", handleConnectionLost);

    // Limpiar el oyente de eventos cuando se desmonte el componente
    return () => {
      window.removeEventListener("mqttConnectionLost", handleConnectionLost);
    };
  }, [navigate]);

  // ...
}

export function connectToMqtt(organizationIds: any[], userId: string) {
  // Crear cliente MQTT
  const client = new Client(
    process.env.REACT_APP_MQTT_BASE_URL!,
    parseInt(process.env.REACT_APP_MQTT_PORT!),
    userId
  );
  const messageOk = parseInt(process.env.REACT_APP_MQTT_MSSG_OK as any);
  const messageDisconnected = parseInt(
    process.env.REACT_APP_MQTT_MSSG_DISCONNECTED as any
  );
  
  // Manejar el evento 'onConnectionLost'
  client.onConnectionLost = (responseObject: any) => {
    if (responseObject.errorCode !== 0) {
      console.log("Connection lost:", responseObject.errorMessage);
      window.location.replace("/");
    }
  };

  // Manejar el evento 'onMessageArrived'
  client.onMessageArrived = (message: any) => {
    console.log("el message", message);
    const topicSplitted = message.destinationName.split("/");
    processResponse(topicSplitted[1], topicSplitted[2], message.payloadString);

    const luminaireResponse = JSON.parse(message.payloadString);
    console.log("la res", messageDisconnected, luminaireResponse);
    if (luminaireResponse.result === messageOk) {
      
      console.log("es 0");
      if (luminaireResponse.msg_type === 109) {
        console.log(luminaireResponse.value)
        store.dispatch(updateSolarConsumsActionCreator(luminaireResponse.value))
      } else {
        store.dispatch(
          updateNodeDeviceStatusReduxActionCreator(luminaireResponse)
        );
        store.dispatch(
          updateNodeDeviceStatusGlobalReduxActionCreator(luminaireResponse)
        );
        store.dispatch(updateNodeStatusReduxActionCreator(luminaireResponse));

      }
      
      // if gw ..... if node .... Los mensajes será distintos y debo pensar en como gestionar esto. Quizás fijándome en el topic?
      //Actualizar redux con el nuevo estado de la luminaria
    

      /*   dispatch(loadDevicesActionCreator(devices));
          dispatch(filteredAllDevicesActionCreator(devices)); */

      store.dispatch(setIsStaticViewActionCreator(true));
      const state = store.getState();
      state.mqtt.nodeId.forEach((node: string) => {
        if (node !== "" && node !== undefined) {
          if (node === luminaireResponse.node_id) {
            store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
          }
        }
      });
    } else if (luminaireResponse.result === messageDisconnected) {
      //Siempre va a ser el nodo 100
      console.log("es 207");
      store.dispatch(turnBackgroundErrorPopUpTrueActionCreator());
    } else {
      console.log("no es 0");
      // falta hacer que salte un mensaje de error cuando se borre el nodo de la lista
      store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
      //es 0 por qué? el [] en la línea 105 era intencionado?
    }

    if (luminaireResponse.node_id === 0) {
      //Lo borramos de la lista
      if (luminaireResponse.result !== 0) console.log("");
      //Muestra alerta por pantalla
    }

    /*
      if(luminaireResponse.node_id === luminaires[i].node_id) {
        console.log('Lo hemos encontrado!!!');
        let foundNode: NodeDevice = {...luminaires[i]};            
        if(luminaireResponse.msg_type === 2) foundNode.on = 0;
        else if(luminaireResponse.msg_type === 1) foundNode.on = 1;
        else if(luminaireResponse.msg_type === 3) {foundNode.on = 1; foundNode.bri = luminaireResponse.value;}

        dispatch(updateNodeDeviceStatusActionCreator(foundNode));
        dispatch(updateNodeDeviceStatusGlobalActionCreator(foundNode));
      }*/
  };

  // Conectar al broker MQTT
  client.connect({
    userName: process.env.REACT_APP_MQTT_CLIENT_USERNAME,
    password: process.env.REACT_APP_MQTT_CLIENT_PASSWORD,
    useSSL: true,
    onSuccess: () => {
      console.log("Connected to MQTT broker");
      organizationIds.forEach((orgId) => {
        console.log("la org: ", orgId.id);
        // Suscribirse al topic 'front-msg/orgId'
        client.subscribe(`front-msg/${orgId.id}`);
      });
    },
  });

  return client;
}
