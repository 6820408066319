import { GeneralAlarms, AlarmsFromNode } from "@/types/interfaces";
import { createSlice } from "@reduxjs/toolkit";

interface State {
  allAlarms: GeneralAlarms[];
  alarmsFromNode: AlarmsFromNode[],
}

const initialState: State = {
  allAlarms: [],
  alarmsFromNode: []
};

const alarmsSlice = createSlice({
  name: "alarms",
  initialState: initialState,
  reducers: {
    loadAlarms: (previousState, action) => ({
      ...previousState,
      allAlarms: action.payload
    }),

    loadAlarmsFromNode: (previousState, action) => ({
      ...previousState,
      alarmsFromNode: action.payload
    }),
  },
});

export const alarmsReducer = alarmsSlice.reducer;

export const {
  loadAlarms: loadAlarmsActionCreator,
  loadAlarmsFromNode: loadAlarmsFromNodeActionCreator
} = alarmsSlice.actions;
