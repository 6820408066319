import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedOrganization } from "../../../types/interfaces";

const initialState: SelectedOrganization = {
  id: "",
  orgName: "",
  latitude: "",
  longitude: "",
  city: "",
  country: "",
};

const selectedOrganizationSlice = createSlice({
  name: "selectedOrganization",
  initialState: initialState,
  reducers: {
   saveSelectedOrgInfo: (previousData, action: PayloadAction<SelectedOrganization>) => ({
      ...previousData,
      id: action.payload.id,
      orgName: action.payload.orgName,
      latitude: action.payload.latitude,
      longitude: action.payload.longitude,
      city: action.payload.city,
      country: action.payload.country,
    }),
  }
});

export const selectedOrganizationReducer = selectedOrganizationSlice.reducer;

export const {
  saveSelectedOrgInfo: saveSelectedOrgInfoActionCreator
  
} = selectedOrganizationSlice.actions;
