import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveStrategyInfoActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { Dimming } from "../Step2Dimming/Dimming";
import { InputField, TextAreaField, CardContainer, NextButton } from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import ellipseBlack from "../../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../../assets/new_icons/ellipse_blue.svg";
import ellipseGreen from "../../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../../assets/new_icons/ellipse_yellow.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";

interface NameProps {
  showDimming?: any;
  goToDimming?: any;
  errorMessage?: boolean;
  setErrorMessage?: any
};

export const Details = ({ showDimming, goToDimming, errorMessage, setErrorMessage }: NameProps) => {
  const strategyInfo = useAppSelector((state) => state.strategies);
  const [name, setName] = useState(strategyInfo.name);
  const [selectedOption, setSelectedOption]: any = useState(strategyInfo.color);
  const [description, setDescription] = useState(strategyInfo.description);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // cuando tengamos esto definido, poner traducciones e iconos
  const options = [
    { label: t("selectColor") },
    { value: "#56C568", label: t("Green"), icon: ellipseGreen },
    { value: "#D51A52", label: t("Red"), icon: ellipseRed },
    { value: "#FBBC05", label: t("Yellow"), icon: ellipseYellow },
    { value: "#1C1B1F", label: t("Black"), icon: ellipseBlack },
    { value: "#87CBE8", label: t("Blue"), icon: ellipseBlue },
  ];

  const formatOptionLabel = ({ value, label, icon }: any) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img src={icon} alt="" style={{ marginRight: "8px", marginTop: "1px" }} />
      <Text textType="caption" color="#959595" marginBottom="0px" style={{ fontSize: "14px" }}>{label}</Text>
    </div>
  );

  useEffect(() => {
    if (name && selectedOption) {
      setErrorMessage(false)
    };
    
    dispatch(saveStrategyInfoActionCreator({ name: name, color: selectedOption, description: description }));
  }, [name, selectedOption, description]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "146px", paddingLeft: "40px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step1")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("generalDetails")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("generalDetailsSubtitleStrategies")}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "300px", marginBottom: errorMessage ? "11px" : "32px" }}>
        <Text textType="caption" color="#475569" marginBottom="2px" style={{fontSize: "14px"}}>{t("name")}*</Text>
        <InputField value={name} onChange={(e) => setName(e.target.value)} maxLength={50} placeholder={t("name")} />
        <Text textType="caption" color="#475569" marginBottom="2px" style={{marginTop: "14px", fontSize: "14px"}}>{t("color")}*</Text>
        <Select
          value={selectedOption ? selectedOption : options[0]}
          onChange={setSelectedOption}
          options={options}
          formatOptionLabel={formatOptionLabel}
          placeholder={t("selectColor")}
          styles={{
            control: (baseStyles, state) => { 
              state.theme.colors.primary = 'none';
              return {
                ...baseStyles,
                cursor: "pointer",             
                backgroundColor: "#fff",
                color: "#212529",
                maxHeight: "36px",
                minHeight: "36px",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "0.375rem",
                border: state.isFocused ? "2px solid" : "1px solid #ced4da",
                '&:hover': {
                  borderColor: 'none',
                }
              }
            },
          }}
        />
        <Text textType="caption" color="#475569" marginBottom="2px" style={{marginTop: "14px", fontSize: "14px"}}>{t("description")} {t("optional")}</Text>
        <TextAreaField value={description} onChange={(e) => setDescription(e.target.value)} maxLength={250} placeholder={t("description")} />
        {
          errorMessage ?
            <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center"}}>{t("errorBlankInput")}</Text>  
          :
            <></>
        }
      </div>
      <NextButton style={{justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "165px"}} onClick={() => {goToDimming()}}>{t("continue")}</NextButton>
        {showDimming && <Dimming />}
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <img src={stepLine} width={22} />
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
