import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import Roles from "../Step2Roles/Roles";
import {
  InputField,
  CardContainer,
  NextButton,
} from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import Language from "../../../../assets/new_icons/Language.svg";
import Company from "../../../../assets/new_icons/Company.svg";
import maps from "../../../../assets/new_icons/maps.svg";
import LightBulb from "../../../../assets/new_icons/light-bulb-shine-alternate.svg";
import Vector from "../../../../assets/new_icons/Vector.svg";

interface NameProps {
  showDevices?: any;
  goToDevices?: any;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

const GroupPermit = ({
  showDevices,
  goToDevices,
  goToReview,
  errorMessage,
  setErrorMessage,
}: NameProps) => {
  const dispatch = useAppDispatch();
  const groupInfo = useAppSelector((state) => state.groups);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const selectedServiceId = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { t } = useTranslation();

  const [name, setName] = useState(groupInfo.name);

  useEffect(() => {
    if (name) {
      // setErrorMessage(false);
    }

    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          marginBottom: "55px",
          paddingLeft: "40px",
          marginLeft: "10px",
        }}
      >
        <Text
          fontWeight="500"
          style={{ marginBottom: "4px", fontSize: "14px" }}
        >
          {t("step5")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("groupPermit")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("groupPermitDescription")}
        </Text>
      </div>

      <div style={{ width: "90%", margin: "auto" }}>
        <div className="accordion permit-accordian" id="permitAccordian">
          <div
            className="accordion-item"
            style={{
              borderRadius: "10px",
              boxShadow: "0 3px 6px #d6d5d1",
              border: "0px",
              marginBottom: "33px",
            }}
          >
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div className="d-flex justify-content-between flex-row align-items-center flex-grow-1">
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={Language}
                      alt="flag"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="flex-grow-1 mx-4">
                    <h6
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      Rwanda
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 me-2 d-flex align-items-center">
                        <img src={LightBulb} alt="company" />
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "10px",
                            color: "#A3AED0",
                            marginLeft: "3px",
                          }}
                        >
                          Street Lighting
                        </span>
                      </p>
                      <p className="mb-0 me-2 d-flex align-items-center">
                        <img src={Vector} alt="maps" />
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "10px",
                            color: "#A3AED0",
                            marginLeft: "3px",
                          }}
                        >
                          19
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="form-check form-check-inline d-flex align-items-center gap-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      className="form-check-input org-permit-checkbox"
                      type="checkbox"
                      id="checkboxNoLabel"
                      value=""
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "2px solid",
                        borderRadius: "3px",
                        marginBottom: "4px",
                      }}
                    />
                    <label
                      className="form-check-label text-base"
                      htmlFor="checkboxNoLabel"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      All services
                    </label>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#permitAccordian"
            >
              <div className="accordion-body">
                <div className="row gx-1">
                  <div className="col-2">
                    <div className="form-check form-check-inline mb-3 me-0 ps-0 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Street Lighting
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Tunnel
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Garbage
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Water
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion permit-accordian" id="permitAccordian2">
          <div
            className="accordion-item"
            style={{
              borderRadius: "10px",
              boxShadow: "0 3px 6px #d6d5d1",
              border: "0px",
              marginBottom: "33px",
            }}
          >
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <div className="d-flex justify-content-between flex-row align-items-center flex-grow-1">
                  <div
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={Language}
                      alt="flag"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className="flex-grow-1 mx-4">
                    <h6
                      className="mb-0"
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      Rwanda
                    </h6>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 me-2 d-flex align-items-center">
                        <img src={LightBulb} alt="company" />
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "10px",
                            color: "#A3AED0",
                            marginLeft: "3px",
                          }}
                        >
                          Street Lighting
                        </span>
                      </p>
                      <p className="mb-0 me-2 d-flex align-items-center">
                        <img src={Vector} alt="maps" />
                        <span
                          style={{
                            fontWeight: "400",
                            fontSize: "10px",
                            color: "#A3AED0",
                            marginLeft: "3px",
                          }}
                        >
                          19
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="form-check form-check-inline d-flex align-items-center gap-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      className="form-check-input org-permit-checkbox"
                      type="checkbox"
                      id="checkboxNoLabel"
                      value=""
                      style={{
                        height: "18px",
                        width: "18px",
                        border: "2px solid",
                        borderRadius: "3px",
                        marginBottom: "4px",
                      }}
                    />
                    <label
                      className="form-check-label text-base"
                      htmlFor="checkboxNoLabel"
                      style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                      All services
                    </label>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#permitAccordian2"
            >
              <div className="accordion-body">
                <div className="row gx-1">
                  <div className="col-2">
                    <div className="form-check form-check-inline mb-3 me-0 ps-0 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Street Lighting
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Tunnel
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Garbage
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Water
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="form-check form-check-inline  mb-3 me-0 ps-0">
                      <input
                        className="form-check-input org-permit-checkbox"
                        type="checkbox"
                        id="checkboxNoLabel"
                        value=""
                        style={{
                          height: "18px",
                          width: "18px",
                          border: "2px solid",
                          borderRadius: "3px",
                          marginLeft: "0",
                        }}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="checkboxNoLabel"
                        style={{ fontSize: "14px", fontWeight: "400" }}
                      >
                        Weather
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NextButton
        style={{
          justifySelf: "end",
          alignSelf: "center",
          width: "374px",
          marginTop: "100px",
        }}
        onClick={() => {
          /* goToDevices() */ goToReview();
        }}
      >
        {t("continue")}
      </NextButton>
      {showDevices && <Roles />}
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignSelf: "center",
          marginTop: "20px",
        }}
      >
        <img src={stepLine} width={22} />
        {/* <img src={stepDot} width={8} style={{ opacity: 0.3 }} /> */}
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};

export default GroupPermit;
