import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";
import { useAppSelector } from "../../redux/hooks";
import { LanguageInformation } from "./LanguageInformation";
import { PasswordInformation } from "./PasswordInformation";
import { PersonalInfo } from "./PersonalInfo";
import { UserAvatar } from "../../utils/reusableComponents";
import { ListItem, IconStyled, CardStyled } from "./ProfilePageStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import envelope from "../../assets/mail.svg";
import globeBold from "../../assets/globe-bold.svg";
import userIcon from "../../assets/user.svg";

interface ProfilePageProps {}

export const ProfilePage = ({}: ProfilePageProps) => {
  const user = useAppSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  return (
    <div className="container-fluid" style={{ marginTop: "30px", paddingRight: "0px", paddingLeft: "0px", zIndex: 2 }}>
      <div style={{ display: "flex", gap: "20px", marginBottom: "32px", alignItems: "center" }}>
        <div>
          <UserAvatar firstName={user.first_name} profileColor={user.color} type={"card"} />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Text textType="subTitle" fontWeight="700" marginBottom="0px">
            {user.first_name} {user.last_name}
          </Text>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItem>
              <IconStyled src={userIcon} height={16} />
              <TextSpan>{user.role_name}</TextSpan>
            </ListItem>
            <ListItem>
              <IconStyled src={envelope} height={18} />
              <TextSpan>{user.email}</TextSpan>
            </ListItem>
            <ListItem>
              <IconStyled src={globeBold} height={18} />
              <TextSpan>
                {user.language_name === "English" ? t("English") : user.language_name === "Spanish" ? t("Spanish") : user.language_name === "Catalan" ? t("Catalan") : 
                user.language_name === "French" ? t("French") : user.language_name === "Italian" ? t("Italian") : user.language_name === "Kinyarwanda" ? t("Kinyarwanda") : 
                user.language_name === "Swahili" ? t("Swahili") : ""}
              </TextSpan>
            </ListItem>
          </div>
        </div>
      </div>
      <div className="row">
        <div style={{ width: "50%" }}>
          <div style={{ marginBottom: "32px" }}>
            <PersonalInfo user={user} />
          </div>
          <div style={{ marginBottom: "32px" }}>
            <LanguageInformation user={user} />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div style={{ marginBottom: "32px" }}>
            <PasswordInformation />
          </div>
          <div style={{ marginBottom: "32px" }}>
            <CardStyled>
              <Text textType="subTitle" fontWeight="700" color="#1B2559">
                {t("Account")}
              </Text>
              <div className="row" style={{ marginBottom: "16px" }}>
                <div className="col-6">
                  <TextSpan>
                    {t("accountState")}
                    <b> {user.user_active === "Y" ? t("active").charAt(0).toLowerCase() + t("active").slice(1) : t("notActive").charAt(0).toLowerCase() + t("notActive").slice(1)}</b>
                  </TextSpan>
                </div>
              </div>
            </CardStyled>
          </div>
        </div>
      </div>
    </div>
  );
};
