import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { turnBackgroundStrategiesFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { resetStrategyActionCreator } from "../../redux/slices/strategiesSlice/strategiesSlice";
import { setStrategiesVisibleActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { useStrategiesService } from "../../services/strategiesService";
import { NewStrategyModal } from "./NewStrategyModal/NewStrategyModal";
import { EditStrategyModal } from "./EditStrategyModal/EditStrategyModal";
import StrategiesChart from "./StrategiesChart";
import { languages } from "../../utils/languages/languages";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import back from "../../assets/new_icons/back.svg";
import plusCircle from "../../assets/new_icons/plusCircle.svg";
import { ReactComponent as EnergyIcon } from "../../assets/new_icons/Energy.svg";
import lightBulb from "../../assets/new_icons/luminaire detailCard.svg";
import plusIcon from "../../assets/new_icons/plus.svg";
import sunrise from "../../assets/new_icons/Sunrise - Filled.svg";
import sunset from "../../assets/new_icons/Sunset - Filled.svg";

interface StrategiesPageCard {}

export const StrategiesPage = ({}: StrategiesPageCard) => {
  const navigate = useNavigate();
  const ref:any = useRef();
  const dispatch = useAppDispatch();
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const listView = useAppSelector((state) => state.ui.isListView);
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const newStrategy = useAppSelector((state) => state.strategies);
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const { getStrategies, deleteStrategy } = useStrategiesService();
  const txt = languages[currentLanguage];
  const { t } = useTranslation();
  const location = useLocation();
  const nodeId = location.state?.nodeId;
  const viewState = location.state?.viewState;

  const [showModal, setShowModal] = useState(false);
  const [showActions, setShowActions] = useState({
    index: "",
    state: false,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [editChart, setEditChart] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [saveId, setSaveId]: any = useState();
  const [saveName, setSaveName]: any = useState("");
  const [name, setName]: any = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleShowModal = () => {
    dispatch(resetStrategyActionCreator());
    dispatch(turnBackgroundStrategiesFalseActionCreator());
    setShowModal(!showModal);
  };

  const handleClick = (event:any) => {
    setPosition({ top: event.clientY - 64, left: event.clientX - 270 });
  };

  const handleShowActions = (index: string, event: any) => {
    event.preventDefault();
    //event.nativeEvent.stopImmediatePropagation();
    setShowActions({ index: index, state: !showActions.state });
    setEditDetails(false);
    setEditChart(false);
  };

  const handleShowEditModal = () => {
    dispatch(resetStrategyActionCreator());
    dispatch(turnBackgroundStrategiesFalseActionCreator());
    setShowEditModal(!showEditModal);
    setShowActions({ index: showActions.index, state: false });
  };

  // REVISAR ESTO
  /* window.addEventListener('popstate', () => {
    if (nodeId) {
      navigate(`/luminaire/${nodeId}`, {state: {viewState}})
    } else {
      navigate("/dashboard")
    }
  }); */
  /* if (strategies) {

    dispatch(resetStrategyActionCreator());
  } */

  const deleteAndGetStrategy = async (id: number) => {
    await deleteStrategy(id);
    resetStates();
    await getStrategies(selectedOrgId, selectedServiceId);
  };

  const resetStates = () => {
    setShowDeletePopup(false);
    setShowActions({ index: "", state: false });
    setSaveId();
    setSaveName("");
    setName("");
    setErrorMessage(false);
  };

  const handleStrategyName = (e: any) => {
    const newName = e.target.value;
    setName(newName);
  };

  useEffect(() => {
    getStrategies(selectedOrgId, selectedServiceId);
    dispatch(setStrategiesVisibleActionCreator());

    const handleClickOutsideShowAction = (event: MouseEvent) => {
      if (
        showActions &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setShowActions({ index: "", state: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutsideShowAction);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideShowAction);
    };    
  }, [showModal, showEditModal, dispatch, selectedOrgId, selectedServiceId, showActions]);

  return (
    <>
      <div className="container-fluid" style={{ pointerEvents: showDeletePopup ? "none" : "auto", marginTop: "8px", paddingRight: "0px", paddingLeft: "0px", position: "relative", zIndex: 2 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "18px", marginRight: "8px", marginTop: "20px" }}>
          <div>
            <Text textType="title" fontWeight="700" marginBottom="8px">
              {t(txt.lightStrategies)}
            </Text>
            <Text textType="">{t(txt.shortLongDescription)}</Text>
          </div>
          <div className="col-2" style={{ textAlign: "right" }}>
            <Button buttonType={strategies.length === 20 ? "secondary" : "primary"} onClick={() => {strategies.length !== 20 ? handleShowModal() : <></>}} style={{ padding: "8px", width: "160px" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                <img src={plusIcon} height={30} alt="" style={{ marginRight: "4px", fontWeight: "500px" }} />
                <TextSpan color="black" fontWeight="500" style={{ marginRight: "6px" }}>{txt.newStrategy}</TextSpan>
              </div>
            </Button>
            {strategies.length === 20 ? (
              <Text style={{ marginTop: "6px" }}>{t(txt.strategiesLimit)}</Text>
            ) : (
              <></>
            )}
            {showModal && (
              <NewStrategyModal showModal={showModal} setShowModal={setShowModal} />
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "21px", flexWrap: "wrap", marginBottom: "20px" }}> 
          {strategies && strategies?.map((item: any, index: any) => (
            <div 
            key={item.id}
              className="card"
              style={{ padding: "24px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
                borderRadius: "16px", width: "24%" }}
            >
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "8px", position: "relative" }}>
                {showActions.state && showActions.index === index && (
                  <article
                    style={{
                      position: "absolute",
                      top: 20,
                      right: 0,
                      width: "190px",
                      maxHeight: "336px",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                      boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                      borderRadius: "4px",
                      zIndex: 3
                      }}
                    >
                    <>
                      <style>
                        {`
                        .strategy-span:hover {
                          background-color: #1c1b1f11;
                        }
                      `}
                      </style>
                      <span
                        className="strategy-span"
                        onClick={() => {
                          handleShowEditModal();
                          setEditDetails(true);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t(txt.editInfo)}
                      </span>
                      <span
                        className="strategy-span"
                        onClick={() => {
                          handleShowEditModal();
                          setEditChart(true);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t(txt.editChart)}
                      </span>
                      <span
                        className="strategy-span"
                        onClick={() => {
                          setShowDeletePopup(true);
                          setSaveId(strategies[showActions.index].id);
                          setSaveName(strategies[showActions.index].strgy_name);
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t(txt.delete)}
                      </span>
                    </>
                  </article>
                )}
                <div className="col-6" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "6px",
                  marginTop: "-1px" }}
                >
                  <img
                    src={lightBulb}
                    height={16}
                    style={{ marginLeft: "-4px", marginBottom: "4px", opacity: 0.5 }}
                  />
                  <TextSpan color="#A3AED0" textType="caption">
                    {item.value || 0}
                  </TextSpan>
                </div>
                <div style={{ textAlign: "right", marginTop: "-2px", display: "flex", gap: "10px" }}>
                  <img
                    src={plusCircle}
                    height={20}
                    alt=""
                    className="icon-class"
                    onClick={(event) => { handleClick(event); handleShowActions(index, event); }}
                    style={{ cursor: "pointer", opacity: 0.5 }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px", gap: "10px" }}>
                <div style={{width: "50%"}}>
                  <Text fontWeight="700" marginBottom="2px">{item.strgy_name}</Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "50%" }}>
                  {item.time1 === "98:98" ?
                    <>
                      <div style={{ display: "flex", gap: "5px" }}> 
                        <Text marginBottom="0px" fontWeight="600">{txt.astroDim}: </Text>
                        <Text marginBottom="0px">{txt.yes}</Text>
                      </div>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img src={sunset} alt="" height={16} />
                          <Text marginBottom="0px">{item.sunset_off} {txt.min}</Text>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img src={sunrise} alt="" height={16} />
                          <Text marginBottom="0px">{item.sunrise_off} {txt.min}</Text>
                        </div>
                      </div>
                    </>
                  :
                    <div style={{ display: "flex", gap: "5px" }}> 
                      <Text marginBottom="0px" fontWeight="600">{txt.astroDim}: </Text>
                      <Text marginBottom="0px">{txt.no}</Text>
                    </div>
                  }
                </div>
              </div>
              <Text color="#A3AED0" textType="caption" marginBottom="2px">{item.description}</Text>
              <StrategiesChart
                chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.dim6, item.dim7, item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7]}
                color={item.color}
              />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px", height: "20px" }}>
                <EnergyIcon height={16} style={{ color: item.color }} />
                <TextSpan color={item.color} fontWeight="500">{item.saving}{t("percentageEnergySaved")}</TextSpan>
              </div>
            </div>  
          ))} 
          {showEditModal && (
            <EditStrategyModal
              tempItem={strategies[showActions.index]}
              showEditModal={showEditModal}
              handleShowEditModal={handleShowEditModal}
              editDetails={editDetails}
              setEditDetails={setEditDetails}
              editChart={editChart}
              setEditChart={setEditChart}
            />
          )}     
        </div>
      </div>
      {showDeletePopup && (
        <div style={{ display: "flex", position: "fixed", alignItems: "center", justifyContent: "center", top: "50%", height: "100vh",
          left: "50%", zIndex: 4, transform: "translate(-50%, -50%)" }}
        >
          <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)", borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center" }}
          >
            <Text>{t(txt.deleteStrategyPopupTitle)}</Text>
            <input
              placeholder={"Strategy's name"}
              value={name}
              onChange={handleStrategyName}
            />
            {errorMessage ? (
              <Text
                marginBottom="0px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6px",
                }}
              >
                {t(txt.errorWrongStrategy)}
              </Text>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
              <button
                style={{
                  padding: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "14px",
                }}
                onClick={() => {
                  if (name === saveName) {
                    deleteAndGetStrategy(saveId);
                  } else {
                    setErrorMessage(true);
                  }
                }}
              >
                {txt.accept}
              </button>
              <button
                style={{
                  padding: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "14px",
                }}
                onClick={() => {
                  resetStates();
                }}
              >
                {t(txt.cancel)}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
