import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import Cookies from "js-cookie";
import { Select } from "antd";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState, store } from "../../../../redux/store";
import { getManagedUserPersonalInfoAction, updateUserAction } from "../../../../redux/actions/usersActions/usersActions";
import { useUsersService } from "../../../../services/usersService";
import { items } from "../../../../utils/languages/changeLanguage";
import { alphabeticRegex, phoneNumberRegex } from "../../../../utils/regex";
import { IconStyled } from "../../../Header/HeaderStyled";
import { ButtonOutlined } from "../../../../styles/shared-styles/buttons-sizes";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import languageIcon from "../../../../assets/new_icons/Language.svg";
import profileIcon from "../../../../assets/new_icons/Profile.svg";
import statusIcon from "../../../../assets/new_icons/Switch OFF.svg";

interface PersonalInformationProps {}

export const PersonalInformation = () => {
  const userInfo = useAppSelector((state: RootState) => state.managedUser);
  const user = useAppSelector((state: RootState) => state.user);
  
  const [name, setName] = useState(userInfo?.name);
  const [surname, setSurname] = useState(userInfo?.surname);
  const [number, setNumber] = useState(userInfo?.number);
  const [role, setRole] = useState(userInfo?.role);
  const [language, setLanguage] = useState(userInfo?.language);
  const [active, setActive]:any = useState(userInfo?.active === "Y" ? "yes" : "no");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorName, setErrorName] = useState<boolean>(false);
  const [errorSurname, setErrorSurname] = useState<boolean>(false);
  const [errorNumber, setErrorNumber] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  
  const { getManagedUserPersonalInfo, postManagedUserPersonalInfo } = useUsersService();
  const { Option } = Select;
  const { t } = useTranslation();

  const handleRole = (value: string) => {
    setRole(value);
  };                               
  
  const handleLanguage = (value: string) => {
    setLanguage(value);
  };
  
  const handleActive = (value: string) => {
    setActive(value);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    setErrorNumber(false);
    setErrorUpdate(false);

    const body = {
      name: name,
      surname: surname,
      number: number,
      language: language,
      role: role,
      active: active === "yes" ? "Y" : "N"
    }

    try {
      const token:any = Cookies.get("token");
      const response = await postManagedUserPersonalInfo(token, userInfo?.id, body);
      
      if (response) {
        setSuccess(true);
        //await getManagedUserPersonalInfo(token, userInfo?.id, setIsLoading);
        let { newInfo, user } = await getManagedUserPersonalInfo(token, userInfo?.id, setIsLoading );
        if (newInfo && user) {
          store.dispatch(getManagedUserPersonalInfoAction(user));
          store.dispatch(updateUserAction(newInfo));
        }
      } else {
        setIsLoading(false);
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  }

  useEffect(() => {
    setName(userInfo?.name);
    setSurname(userInfo?.surname);
    setNumber(userInfo?.number);
    setRole(userInfo?.role);
    setLanguage(userInfo?.language);

    const selectedLanguage = localStorage.getItem("selectedLanguage");
  
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }

  }, [userInfo, i18n]);

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingRight: "30px", paddingLeft: "10px" }}>
      <div className="row" style={{ marginBottom: "36px" }}>
        <Text textType="subTitle" fontWeight="700" marginBottom="16px">{t("Personal Information")}</Text>
        <div style={{ display: "flex", gap: "25px" }}>
          <div style={{ width: "100%" }}>
            <Text marginBottom="8px" fontWeight="400">{t("First name")}</Text>
            <input className={`form-control ${!name ? "error_border" : ""}`} value={name} onChange={(e) => {setName(e.target.value); setErrorName(false); setErrorExistingData(false); setErrorUpdate(false)}} />
          </div>
          <div style={{ width: "100%" }}>
            <Text marginBottom="8px" fontWeight="400">{t("Last name")}</Text>
            <input className="form-control" value={surname} onChange={(e) => {setSurname(e.target.value); setErrorSurname(false); setErrorExistingData(false); setErrorUpdate(false)}} />
          </div>
          <div style={{ width: "100%" }}>
            <Text marginBottom="8px" fontWeight="400">{t("phoneNumber")}</Text>
            <input className="form-control" value={number} onChange={(e) => {setNumber(e.target.value); setErrorNumber(false); setErrorExistingData(false); setErrorUpdate(false)}} />
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "36px" }}>
        <Text textType="subTitle" fontWeight="700" marginBottom="16px">{t("roleAndLanguage")}</Text>
        <div style={{ display: "flex", gap: "25px" }}>
          <div style={{ width: "32.1%", textAlign: "left" }}>
            <Text marginBottom="8px" fontWeight="400">{t("Role")}</Text>
            <div style={{ position: "relative" }}>
              <IconStyled src={profileIcon} style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)", zIndex: 1, opacity: 0.7 }} />
              <Select
                style={{ width: "100%" }}
                onChange={handleRole}
                value={role}
                disabled={user?.role_name !== "owner" && user?.role_name !== "admin"}
                className="my-select"
                options={[
                  {
                    value: "owner",
                    label: t("owner")
                  },
                  {
                    value: "admin",
                    label: t("admin"),
                  },
                  {
                    value: "maintenance",
                    label: t("maintenance"),
                  },
                  {
                    value: "observer",
                    label: t("observer"),
                  },
                ].filter(option => user?.role_name === "owner" || option.value !== "owner")}
              />
            </div>
          </div>
          <div style={{ width: "32.1%", textAlign: "left" }}>
            <Text marginBottom="8px" fontWeight="400">{t("defaultLanguage")}</Text>
            <div style={{ position: "relative" }}>
              <IconStyled src={languageIcon} style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)", zIndex: 1, opacity: 0.7 }} />
              <Select
                style={{ width: "100%" }}
                onChange={handleLanguage}
                value={language}
                disabled={user?.role_name !== "owner" && user?.role_name !== "admin"}
                className="my-select"
              >
                {items.map((item, index) => (
                  <Option key={index} value={item.label}>
                    {t(item.label)}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "10px" }}>
        <Text textType="subTitle" fontWeight="700" marginBottom="16px">{t("Account")}</Text>
        <div style={{ width: "33.3%", textAlign: "left" }}>
          <Text marginBottom="8px" fontWeight="400">{t("active")}</Text>
          <div style={{ position: "relative" }}>
            <IconStyled src={statusIcon} style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)", zIndex: 1, opacity: 0.7 }} />
            <Select
              style={{ width: "100%" }}
              value={active}
              onChange={handleActive}
              className="my-select"
              options={[
                {
                  value: "yes",
                  label: t("yes")
                },
                {
                  value: "no",
                  label: t("no"),
                }
              ]}
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "right", marginBottom: "16px", marginTop: "18px" }}>
        {isLoading ? 
          <ButtonOutlined type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
         : 
          <ButtonOutlined onClick={() => {
            if (number && !(/^\s/.test(number))) {
              if (!phoneNumberRegex.test(number)) {   
                setErrorNumber(true)
                return
              }
            } else if (number && (/^\s/.test(number))) {
              setErrorUpdate(true)
              return
            }

            if (!(/^\s/.test(name)) && !(/^\s/.test(surname)) && name !== "") {
              if (alphabeticRegex.test(name) && alphabeticRegex.test(surname)) {
                let activeParsed = active === "yes" ? "Y" : "N";
                if (name === userInfo?.name && surname === userInfo?.surname && number === userInfo?.number && role === userInfo?.role && language === userInfo?.language &&
                    activeParsed === userInfo?.active) {
                  setErrorExistingData(true);
                } else {
                  setErrorExistingData(false);
                  handleUpdate();
                }
              } else if (!alphabeticRegex.test(name)) {
                setErrorName(true);
              } else if (!alphabeticRegex.test(surname)) {
                setErrorSurname(true);
              }
            } else {
              setErrorUpdate(true)
            }
          }}>
            {t("Update")}
          </ButtonOutlined>
        }
      </div>    
      <div style={{ height: '10px', textAlign: "right" }}>
        {(/^\s/.test(name) || /^\s/.test(surname) || name === "") ?
          <Text marginBottom="0px" color="red">{t("errorBlankInput")}</Text>
        :
          errorName ?
            <Text marginBottom="0px" color="red">{t("errorFirstName")}</Text>
          :
            errorSurname ?
              <Text marginBottom="0px" color="red">{t("errorLastName")}</Text>
            :
              errorNumber ?
                <Text marginBottom="0px" color="red">{t("errorPhoneNumber")}</Text>
              :
                errorExistingData ?
                  <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
                :
                  errorUpdate ?
                    <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
                  : 
                    success ?
                      <Text marginBottom="0px" color="green">{t("updated")}</Text>
                    :
                      <></>
          }
        </div>
    </div>
  );
};
