import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardSimple } from "../../LuminairePageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import energyYellow from "../../../../assets/new_icons/Energy-yellow.svg";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";
import solarLuminaire from "../../../../assets/new_icons/Solar-luminaire.svg";

interface LuminaireProps {
  selectedLamp: any
}

export const Luminaire = ({ selectedLamp }: LuminaireProps) => {
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [loadPower, setLoadPower] = useState(selectedLamp.dc_load_power);
  const { t } = useTranslation();

  useEffect(() => {
    setLoadPower(selectedLamp.dc_load_power);
  }, [selectedLamp.dc_load_power]);
  
  return (
    <CardSimple >
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <div>
          <Text color="#A3AED0">{t("Luminaire")}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
          />
        </div>
      </div>
      <img src={solarLuminaire} style={{ opacity: 0.6, width: "160px" }} />
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-5px", marginLeft: "60px", gap: "3px" }}>
        <img src={energyYellow} width={14} />
        <Text marginBottom="0">{!loadPower && loadPower !== 0 ? '\"\"' : loadPower % 1 !== 0 ? Math.round(loadPower).toFixed(2) : loadPower} {t("w/h")}</Text>
      </div>
    </CardSimple>
  );
};
