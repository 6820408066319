import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { turnBackgroundErrorPopUpFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { languages } from "../../utils/languages/languages";
import { ErrorPopUpStyled } from "./ErrorPopUpStyled";
import { Text, TextBox } from "../../styles/shared-styles/text-sizes";
import clearFilters from "../../assets/new_icons/Cross.svg";

export const ErrorPopUp = () => {
  const { actualLanguage } = useAppSelector((state) => state.ui);
  const txt = languages[actualLanguage];
  
  const dispatch = useAppDispatch();

  const changeBackground = () => {
    dispatch(turnBackgroundErrorPopUpFalseActionCreator());
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      changeBackground();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorPopUpStyled>
      <div className="popup__button-container">
        <img
          src={clearFilters}
          height={12}
          onClick={() => {changeBackground()}}
          style={{ cursor: "pointer", marginRight: "0px" }}
        />   
      </div>
      <div className="popup__body-container">
        <Text
          fontWeight="600"
          marginBottom="8px"
          textType="subTitle"
          color="#000000"
          style={{ marginTop: "6px", fontSize: "18px" }}
        >
          {txt.alertWind}
        </Text>
        <div
          className="popup__body-container__button"
          onClick={() => {changeBackground()}}
        >
          <TextBox
            fontWeight="500"
            color="#000000"
            style={{ fontSize: "16px" }}
          >
            {txt.accept}
          </TextBox>
        </div>
      </div>
    </ErrorPopUpStyled>
  );
};
