import { store } from '../redux/store';
import { loadUsersActionCreator, updateUserActionCreator } from "../redux/slices/managedUsers/managedUsersListSlice";
import { managedUser, managedUsers, managedUsersList, managedUserOrgs, managedUserServices, managedUserGroups } from "../types/interfaces";
import { getUserPersonalInfoActionCreator, getUserOrgsActionCreator, getUserServicesActionCreator, getUserGroupsActionCreator } from '../redux/slices/managedUsers/managedUserSlice';

export const useUsersService = () => {
  const webAddressPrefixGetUsers: string = process.env.REACT_APP_API_GET_USERS_LOCAL_BASE_URL as string;
  const webAddressPrefixManagedUserPersonalInfo: string = process.env.REACT_APP_API_MANAGED_USER_PERSONAL_INFO_LOCAL_BASE_URL as string;
  const webAddressPrefixManagedUserOrgs: string = process.env.REACT_APP_API_MANAGED_USER_ORGS_LOCAL_BASE_URL as string;
  const webAddressPrefixManagedUserServices: string = process.env.REACT_APP_API_MANAGED_USER_SERVICES_LOCAL_BASE_URL as string;
  const webAddressPrefixManagedUserGroups: string = process.env.REACT_APP_API_MANAGED_USER_GROUPS_LOCAL_BASE_URL as string;

  const getUsers = async (token: string, setLoading: (loading: boolean) => void): Promise<any> => {
    try {
      const response = await fetch(`${webAddressPrefixGetUsers}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const getManagedUsers = await response.json();
        
        if (getManagedUsers.length > 0) {
          const users: managedUsers[] = getManagedUsers.map((userData:any) => ({
            id: userData.id,
            color: userData.color || "",
            name: userData.name || "",
            surname: userData.surname || "",
            email: userData.email || "",
            number: userData.number || null,
            role: userData.role || "",
            language: userData.language || "",
            active: userData.active || "",
            organizations: userData.organizations || [],
          }));

          const usersList: managedUsersList = {
            users: users
          };

          setLoading(false);
          return usersList;
        } else {
          console.log("No user data found in response.");
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        console.log("Failed to fetch user profile.", response.status);
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user profile:", error);
      setLoading(false);
      return false;
    }
  };

  const getManagedUserPersonalInfo = async (token: string | any, userId: string, setLoading: (loading: boolean) => void): Promise<any> => {
    try {
      const response = await fetch(`${webAddressPrefixManagedUserPersonalInfo}${userId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const [managedUserPersonalInfo] = await response.json();
        
        const user: managedUser = {
          id: managedUserPersonalInfo.id,
          name: managedUserPersonalInfo.first_name,
          surname: managedUserPersonalInfo.last_name,
          number: managedUserPersonalInfo.phone_number,
          email: managedUserPersonalInfo.email,
          language: managedUserPersonalInfo.language_name,
          role: managedUserPersonalInfo.role_name,
          active: managedUserPersonalInfo.user_active
        };

        const newInfo: managedUser | any = {
          id: managedUserPersonalInfo.id,
          name: managedUserPersonalInfo.first_name,
          surname: managedUserPersonalInfo.last_name,
          number: managedUserPersonalInfo.phone_number,
          role: managedUserPersonalInfo.role_name,
          active: managedUserPersonalInfo.user_active
        }
    
        //store.dispatch(getUserPersonalInfoActionCreator(user));
        //store.dispatch(updateUserActionCreator(newInfo));
        setLoading(false);
        return { user, newInfo }
        //return true;
      } else {
        console.log("Failed to fetch user profile.", response.status);
        setLoading(false);
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user profile:", error);
      setLoading(false);
      return false;
    }
  };

  const postManagedUserPersonalInfo = async (token: string | any, userId: string, userInfo: any): Promise<boolean> => {
    const body = {
      first_name: userInfo.name,
      last_name: userInfo.surname || "",
      phone_number: userInfo.number || "",
      language_name: userInfo.language,
      role_name: userInfo.role,
      user_active: userInfo.active
    }
  
    try {
      const response = await fetch(`${webAddressPrefixManagedUserPersonalInfo}${userId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getManagedUserOrgs = async (token: string | any, userId: string, setLoading: (loading: boolean) => void) : Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefixManagedUserOrgs}${userId}`,{
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const managedUserOrgs = await response.json();
        if (Array.isArray(managedUserOrgs) && managedUserOrgs.length > 0) {
          const orgsList: managedUserOrgs | any = managedUserOrgs.map((orgData) => ({
            id: orgData.id,
            orgName: orgData.organization_name || "",
            city: orgData.city || "",
            country: orgData.country || "",
            active: orgData.organization_active || "",
            selected: orgData.selected === true,
          }));

          store.dispatch(getUserOrgsActionCreator({orgs: orgsList}));
          setLoading(false);
          return true;
        } else {
          console.log("No data found or unexpected response format.");
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        console.log("Failed to fetch user by ID.", response.status);
        // Handle other error cases here
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);
      setLoading(false);
      // Handle error cases here
      return false;
    }
  };

  const postManagedUserOrgs = async (token: string | any, userId: string, userGroups: any): Promise<boolean> => {
    const body = userGroups;
  
    try {
      const response = await fetch(`${webAddressPrefixManagedUserOrgs}${userId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getManagedUserServices = async (token: string | any, userId: string, setLoading: (loading: boolean) => void) : Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefixManagedUserServices}${userId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const managedUserServices = await response.json();
        if (Array.isArray(managedUserServices) && managedUserServices.length > 0) {
          const servicesList: managedUserServices | any = managedUserServices.map((servData) => ({
            orgId: servData.orgId,
            orgName: servData.organization_name || "",
            services: servData.services || [],
          }));

          store.dispatch(getUserServicesActionCreator({services: servicesList}));
          setLoading(false);
          return true;
        } else {
          console.log("No data found or unexpected response format.");
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        console.log("Failed to fetch user by ID.", response.status);
        // Handle other error cases here
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);
      setLoading(false);
      // Handle error cases here
      return false;
    }
  };

  const postManagedUserServices = async (token: string | any, userId: string, userServices: any): Promise<boolean> => {
    const body = userServices;
  
    try {
      const response = await fetch(`${webAddressPrefixManagedUserServices}${userId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getManagedUserGroups = async (token: string | any, userId: string, setLoading: (loading: boolean) => void) : Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefixManagedUserGroups}${userId}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const managedUserGroups = await response.json();
        if (Array.isArray(managedUserGroups) && managedUserGroups.length > 0) {
          const groupsList: managedUserGroups | any = managedUserGroups.map((groupData) => ({
            orgId: groupData.orgId,
            orgName: groupData.organization_name || "",
            servId: groupData.servId || "",
            servName: groupData.servName || "",
            groups: groupData.groups || [],
          }));

          store.dispatch(getUserGroupsActionCreator({groups: groupsList}));
          setLoading(false);
          return true;
        } else {
          console.log("No data found or unexpected response format.");
          setLoading(false);
          return false;
        }
      } else {
        setLoading(false);
        console.log("Failed to fetch user by ID.", response.status);
        // Handle other error cases here
        return false;
      }
    } catch (error) {
      console.log("Error while fetching user by ID:", error);
      setLoading(false);
      // Handle error cases here
      return false;
    }
  };

  const postManagedUserGroups = async (token: string | any, userId: string, userGroups: any): Promise<boolean> => {
    const body = userGroups;
  
    try {
      const response = await fetch(`${webAddressPrefixManagedUserGroups}${userId}`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      console.log(response)
      
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getUsers,
    getManagedUserPersonalInfo,
    postManagedUserPersonalInfo,
    getManagedUserOrgs,
    postManagedUserOrgs,
    getManagedUserServices,
    postManagedUserServices,
    getManagedUserGroups,
    postManagedUserGroups
  };
};
