import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Groups } from "../../../types/interfaces";

const initialState: Groups = {
  groups: [],
  name: ""
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    getGroups: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      groups: action.payload.groups
    }),

    resetGroups: (previousData) => ({
      ...previousData,
      groups: [],
    }),

    saveGroupInfo: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      name: action.payload.name
    }),

    resetGroupInfo: (previousData) => ({
      ...previousData,
      name: ""
    }),
  },
});

export const groupsReducer = groupsSlice.reducer;

export const {
  getGroups: getGroupsActionCreator,
  resetGroups: resetGroupsActionCreator,
  saveGroupInfo: saveGroupInfoActionCreator,
  resetGroupInfo: resetGroupInfoActionCreator
} = groupsSlice.actions;
