import { CardContainerResponsePage } from "./ResponsePageStyled";
import errorImg from "../../assets/error.svg";
import { Text } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";

export const ErrorPage = () => {
  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={errorImg} />
          <Text
            textType="title"
            color="#1B2559"
            fontWeight="700"
            marginBottom="0px"
          >
            Something went wrong here...
          </Text>
          <Text textType="subTitle" color="#1B2559" fontWeight="400">
            Please try again later.
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary">Go back to Dashboard</Button>{" "}
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
