import styled from "styled-components";

export const ServiceCard = styled.div`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 5px 0px #00000026;
  background-color: #ffffff;
  padding: 16px;
  margin-bottom: 24px;
`;

export const ServiceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
