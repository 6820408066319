import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { Review } from "../Step3/Review";
import { languages } from "../../../../utils/languages/languages";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";

interface DimmingProps {
  setShowDetails?: any;
  showDetails?: any;
  setShowDevices?: any;
  showDevices?: any;
  setShowReview?: any;
  showReview?: any;
  setNewChartData?: any;
  newChartData?: any;
}

export const Devices = ({
  setShowDetails,
  showDetails,
  setShowDevices,
  showDevices,
  setShowReview,
  showReview,
  setNewChartData,
  newChartData,
}: DimmingProps) => {
  const [errorSunsetOffsetValue, setErrorSunsetOffsetValue] = useState(false);
  const [errorSunriseOffsetValue, setErrorSunriseOffsetValue] = useState(false);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const { t } = useTranslation();

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "16px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{txt.step2}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{txt.dimmingConfigurationTitle}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{txt.dimmingConfigurationSubtitle}</Text>
      </div>
      <NextButton style={{justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "64px", marginLeft: "-40px", background: errorSunsetOffsetValue ||
        errorSunriseOffsetValue ? "#6a7794" : "#1B2559", border: errorSunsetOffsetValue || errorSunriseOffsetValue ? "1px solid #6a7794" : "1px solid #1B2559",
        cursor: errorSunsetOffsetValue || errorSunriseOffsetValue ? "not-allowed" : "pointer"}}
        onClick={() => {
          if (!errorSunsetOffsetValue && !errorSunriseOffsetValue) {
            setShowDetails(false); setShowDevices(false); setShowReview(true)}
          }
        }
      >
        {txt.continue}
      </NextButton>
      {showReview && <Review />}
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
        <img src={stepLine} width={22} />
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
