import { store } from '../redux/store';
import { saveSelectedOrgInfoActionCreator } from "../redux/slices/selectedOrganizationSlice/selectedOrganizationSlice";

export const useOrganizationsService = () => {
  const webAddressPrefix: string = process.env.REACT_APP_API_LOCAL_BASE_URL as string;
    
  const getSelectedOrganizationInfo = async (orgId: number, serviceId: number): Promise<boolean> => {
    try {
      const response = await fetch(`${webAddressPrefix}/orgs/info?orgId=${orgId}&servId=${serviceId}`, {credentials: 'include'});
      const orgInfo = await response.json();

      store.dispatch(saveSelectedOrgInfoActionCreator({ id: orgInfo.id, orgName: orgInfo.organization_name, latitude: orgInfo.latitude, longitude: orgInfo.longitude,
      city: orgInfo.city, country: orgInfo.country }));

      return true; 
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getSelectedOrganizationInfo
  };
};
