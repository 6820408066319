export const languages:any = {
  english: {
    accept: "Accept",
    actions: "Actions",
    activateAstroDIM: "Would you like to activate the Astro DIM?",
    activatedAstroDIMTitle: "Great, Astroclock is activated!",
    activatedAstroDIMSubtitle: "You can give an offset to the strategy",
    activatedAstroDIMSubtitleBold: " in minutes",
    active: "Active",
    activeEnergy: "Active energy (Wh)",
    activePower: "Active power (W)",
    addPoint: "Add point",
    addTime: "Add new time",
    alarm: "Alarm",
    alarms: "alarms",
    alarmsCapitalLetter: "Alarms",
    alertWind: "Alert, strong wind gusts have been detected",
    all: "All",
    apparentEnergy: "Apparent energy (VAh)",
    apparentPower: "Apparent power (VA)",
    astroDim: "Astro DIM",
    back: "Back",
    backToDimmingConfiguration: "Back to Dimming configuration",
    backToGeneralDetails: "Back to General Details",
    backToName: "Back to Name",
    backToOverview: "Back to Overview",
    battery: "Battery",
    bulkActions: "Bulk actions",
    cancel: "Cancel",
    case: "Case",
    charging: "Charging",
    clear: "Clear",
    clearAllFilters: "Clear all filters",
    clearFilter: "Clear filter",
    closeStrategyPopupTitle: "Do you want to close this form? All the information will be lost",
    color: "Color",
    connected: "Connected",
    connectedLuminaires: "Connected luminaires",
    connection: "Connection",
    continue: "Continue",
    create: "Create",
    createStrategy: "Create new strategy",
    creationDate: "Creation date",
    critical: "Critical",
    current: "Current (mA)",
    currentStrategy: "Current strategy",
    darkLight: "Dark mode/Light mode",
    dashboard: "Dashboard",
    delete: "Delete",
    deletePoint: "Delete point",
    deleteStrategyPopupTitle: "Do you want to delete this strategy? Please write the name of the strategy to delete it",
    description: "Description",
    device: "Device",
    devicesSelected: "Devices selected",
    dimming: "Dimming",
    dimming5: "5%",
    dimming50: "50%",
    dimming100: "100%",
    dimmingAll: "Dimming all",
    dimmingAllLuminaires: "Dimming all luminaires",
    dimmingConfigurationTitle: "Dimming configuration",
    dimmingConfigurationSideMenu: "Configure your strategy",
    dimmingConfigurationSubtitle: "Give a strategy the configuration you prefer. The energy saving will be calculated on the next step.",
    dimmingConfigurationBoxes: "You can use the boxes to configure the time and dimming or the graph.",
    dimmingPopUpSubtitle: "Select the dimming you want to apply to the luminaires",
    dimmingPopUpTitle: "Dimming luminaires",
    disabledDemo: "Disabled in demo mode",
    disconnected: "Disconnected",
    editInfo: "Edit information",
    editChart: "Edit graphic",
    editStrategy: "Edit your strategy",
    electricParams: "Electrical parameters",
    email: "Email",
    end: "End",
    energyGeneration: "Energy Generation (kWh)",
    energySaved: "25% energy saving",
    errorBlankInput: "Please provide valid data",
    errorConnection: "Ops! Not possible, please try again later",
    errorExistingPoint: "This dimming point already exists. Please provide different data",
    errorInvalidLimitPoint: "This dimming point is outside the time slot marked between the start and end points. Please provide different data",
    errorLogin: "Incorrect email or password",
    errorSunsetOffsetValue: "Please provide a sunset value between -60 and 60",
    errorSunriseOffsetValue: "Please provide a sunrise value between -60 and 60",
    errorStrategyLimit: "You have reached the dimming points' limit on a strategy. Delete some points if you want to add new ones",
    errorWrongStrategy: "The strategy's name is not correct",
    events: "Events",
    expandDecrease: "Expand/Decrease",
    followSteps: "Follow the steps to create a new strategy",
    forgotPassword: "Forgot password?",
    forgotPasswordQ: "Forgot password?",
    forgotPasswordText: "Enter the email address that you used when you joined and we'll send you instructions to reset your password.",
    frequency: "Frequency (Hz)",
    fromAToZ: "From A to Z",
    fromZToA: "From Z to A",
    gateway: "Gateway",  
    gateways: "gateways",
    generalDetails: "General details",
    generalDetailsTitle: "General details",
    generalDetailsSideMenu: "Provide the general details of the strategy",
    generalDetailsSubtitle: "Give a strategy the name you prefer. This will help you identify it for future events.",
    groups: "Groups",
    hour: "Hour",
    id: "ID",
    lightStrategies: "Your lighting strategies",
    loading: "Loading...",
    logIn: "Log in",
    logInSmartec: "Log in to Smartec",
    luminaire: "Luminaire",
    luminaires: "luminaires",
    manufacturer: "Manufacturer",
    max60: "max 60 min",
    min: "min",
    minOffset: "min offset",
    minute: "Minute",
    moderate: "Moderate",
    modify: "Modify",
    name: "Name",
    newGroup: "New group",
    newStrategy: "New strategy",
    nextDimming: "Next: Dimming",
    nextReviewCreate: "Next: Review & Create",
    no: "No",
    none: "None",
    noOffset: "No offset",
    notPositioned: "Not positioned",
    OFF: "OFF",
    OFFAllLuminaires: "OFF all luminaires",
    ok: "OK",
    ON: "ON",
    OFFluminaires: "OFF luminaires",
    ONAllLuminaires: "ON all luminaires",
    ONluminaires: "ON luminaires",
    optional: "(Optional)",
    password: "Password",
    past: "Past",
    percentageEnergySaved: "% energy saving",
    poleNumber: "Pole number",
    power: "power",
    powerGridVoltage: "Power grid voltage (V-AC)",
    production: "Production",
    protocol: "Protocol",
    refresh: "Refresh",
    resetInstructions: "Send reset instructions",
    review: "Review",
    reviewStrategyTitle: "Review strategy configuration",
    reviewStrategySideMenu: "Verify all the information",
    reviewStrategySubtitle: "Review your strategy configuration before creation.",
    savings: "Savings",
    selectArea: "Select an area",
    selectColor: "Select color...",
    send: "Send",
    sendDimming: "Send dimming",
    setAutomaticSunrise: "Automatic dimming with the local sunrise's hour",
    setAutomaticSunset: "Automatic dimming with the local sunset's hour",
    shortDescription: "Short description of the strategy",
    shortLongDescription: "Short description about what is lighting strategies and how can help with the energy savings",
    solar: "Solar",
    standard: "Standard",
    start: "Start",
    status: "Status",
    step1: "STEP 1",
    step2: "STEP 2",
    step3: "STEP 3",
    step3Title: "Review & Create",
    strategies: "Strategies",
    strategiesLimit: "You have reached the strategies' limit",
    strategyConfiguration: "Strategy configuration",
    strategyDetailsTitle: "General details",
    strategyDetailsSubtitle: "Give a strategy the name you prefer. This will help you identify it for future events.",
    sunrise: "Sunrise",
    sunriseOffset: "Sunrise offset",
    sunset: "Sunset",
    sunsetOffset: "Sunset offset",
    switchOFF: "Switch OFF",
    temporaryEvents: "Temporary events",
    upcoming: "Upcoming",
    updateDate: "Update date",
    switchON: "Switch ON",
    temperature: "Temperature (°C)",
    times: "times",
    time2: "Time 2",
    time3: "Time 3",
    time4: "Time 4",
    time5: "Time 5",
    time6: "Time 6",
    totalLuminaires: "Total luminaires",
    viewAll: "View all",
    viewDetails: "View details",
    viewHistoric: "View historic",
    voltage:"Voltage (V-CC)",
    welcomeTo: "Welcome to ",
    yes: "Yes"
  },

  catala: {
    cardInfo: {
      "Total luminaires": "Totes les lluminàries",
      "ON luminaires": "Lluminàries enceses",
      "Connected luminaires": "Lluminàries conectades",
      Alarms: "Alarmes",
      Consumption: "Consum",
    },
    filters: {
      SelectArea: "Selecciona una àrea",
      All: "Totes",
      Connected: "Conectat",
      Disconnected: "Desconectat",
      Alarms: "Alarmes",
      ON: "ON",
      OFF: "OFF",
    },
  },
};

export type Language = "english";
