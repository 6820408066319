import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NodeLumimairePage } from "../../../types/interfaces";
import { useLightingService } from "../../../services/lightingService";
import { languages } from "../../../utils/languages/languages";
import { IconStyled } from "../LuminairePageStyled";
import { Button } from "../../../styles/shared-styles/buttons-sizes";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import Alarm from "../../../assets/new_icons/Alarm.svg";
import connectedIcon from "../../../assets/new_icons/Connected.svg";
import disconnectedIcon from "../../../assets/new_icons/Node disconnected.svg";
import gateway from "../../../assets/new_icons/Gateway nodePage.svg";
import hops from "../../../assets/new_icons/Hops.svg";
import ID from "../../../assets/new_icons/ID.svg";
import luminaireIcon from "../../../assets/new_icons/Luminaires.svg";
import neighbours from "../../../assets/new_icons/Neighbours.svg";
import nodeType from "../../../assets/new_icons/Type.svg";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";
import plusIcon from "../../../assets/new_icons/plus.svg";
import rssi from "../../../assets/new_icons/rssi.svg";
import travelTime from "../../../assets/new_icons/Traveltime.svg";

interface ViewCameraProps {
  selectedLamp?: NodeLumimairePage | any;
}
  
export const ViewCamera = ({ selectedLamp }: ViewCameraProps) => {
  const [showActions, setShowActions] = useState(false);

  const { sendCommandToLuminaire } = useLightingService();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutsideShowAction = (event: MouseEvent) => {
      if (
        showActions &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setShowActions(!showActions);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideShowAction);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideShowAction);
    };    
  }, [showActions]);

  return (
    <div className="row" style={{ marginBottom: "16px", alignItems: "center" }}>
      <div className="col-1">
        <div
          className="profile-picture-container"
          style={{
            borderRadius: "50%",
            width: "96px",
            height: "96px",
            overflow: "hidden",
          }}
        >
          <img
            src={luminaireIcon}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Profile Picture"
            className="img-fluid"
          />
          {/* <span
            style={{
              position: "absolute",
              top: 200,
              left: 190,
              padding: "8px 16px",
              backgroundColor: "#fff",
              borderRadius: "30px",
              cursor: "pointer",
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            }}
          >
            <img
              src={videoIcon}
              style={{
                height: "16px",
              }}
            />
          </span> */}
        </div>
      </div>
      <div
        className="col-9"
        style={{ paddingLeft: "10px", alignItems: "center" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <TextSpan textType="title" fontWeight="700">
            {selectedLamp?.node_name}
          </TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "3px",
            gap: "20px",
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled
              src={
                selectedLamp?.online === "1" ? connectedIcon : disconnectedIcon
              }
              height={18}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.online === "1"
                ? t("Connected")
                : t("Disconnected")}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled
              src={
                selectedLamp?.on === "1" && selectedLamp?.online === "1"
                  ? ON
                  : OFF
              }
              height={18}
              style={{ marginBottom: "4px" }}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.on === "1" && selectedLamp?.online === "1"
                ? t("ON")
                : t("OFF")}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={nodeType} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.device_type}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={ID} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.node_id}
            </TextSpan>{/*  */}
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={gateway} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.gateway_id}
            </TextSpan>
          </div>
          {/* <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={travelTime} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.traveltime || "-"}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={neighbours} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.num_neighbours || "-"}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={rssi} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.rssi || "-"}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={hops} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.hops || "-"}
            </TextSpan>
          </div> */}
          {/* TODO: Add the alarm detail */}
          {/* <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={Alarm} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.alarm}
            </TextSpan>
          </div> */}
        </div>
      </div>
      <div className="col-2" style={{ textAlign: "right" }}>
        {/* TODO: Add the + Actions when the page will be designed and linkend */}
        {selectedLamp.solar_id ?
          <Button buttonType="primary" style={{ width: "110px" }} onClick={() => {setShowActions(!showActions)}}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
              <img src={plusIcon} height={30} alt="" style={{ fontWeight: "500px" }} />
              <TextSpan
                color="black"
                fontWeight="500"
                style={{ marginRight: "6px" }}
              >
                {t("Actions")}
              </TextSpan>
            </div>
          </Button>
        :
          <></>
        }
        {showActions && (
          <article
            style={{
              position: "absolute",
              top: 175,
              right: 112,
              width: "190px",
              maxHeight: "336px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
              boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
              zIndex: 3
              }}
            >
            <>
              <style>
                {`
                .actions-span:hover {
                  background-color: #1c1b1f11;
                }
              `}
              </style>
              <span
                className="actions-span"
                onClick={() => {
                  sendCommandToLuminaire([selectedLamp], 109, 0, 0);
                  setShowActions(false);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  isolation: "isolate",
                  paddingLeft: "15px",
                  width: "100%",
                  minHeight: "56px",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                {t("updateData")}
              </span>
              
            </>
          </article>
        )}
      </div>
    </div>
  );
};
