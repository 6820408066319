import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { setEventsVisibleActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment)

export const Events = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const myEventsList= [
    {
      title: 'Rencontre',
      resourceId: 'a',
      start: new Date(2023, 3, 2, 5, 30, 0, 0),
      end: new Date(2023, 3, 2, 10, 30, 0, 0),
    },
    {
      title: 'Another Meeting',
      resourceId: 'b',
      start: new Date(2023, 3, 1, 2, 30, 0, 0),
      end: new Date(2023, 3, 1, 4, 30, 0, 0),
    },
    {
      title: 'A',
      resourceId: 'a',
      start: new Date(2023, 6, 4, 5, 30, 0, 0),
      end: new Date(2023, 6, 4, 10, 30, 0, 0),
    },
    {
      title: 'B',
      resourceId: 'b',
      start: new Date(2023, 3, 4, 5, 30, 0, 0),
      end: new Date(2023, 3, 4, 10, 30, 0, 0),
    }
  ];

  useEffect(() => {
    dispatch(setEventsVisibleActionCreator());
  }, [dispatch]);

  return (
    <div onClick={() => {navigate("/disabled-demo-mode")}} style={{marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Calendar
        defaultView="month"
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "600px", width: "80%" }}
      />
    </div>
  )
} 



/* import { useEffect, useState } from "react";
import Calendar, { TZDate } from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { Text } from "../../styles/shared-styles/text-sizes";

export const Events = () => {

 
  const [month, setMonth] = useState(6);


  const calendar = new Calendar('#calendar', {
    defaultView: 'month',
    theme: {
     
        
      //backgroundColor: 'red',
  
    
    },
    usageStatistics: false,
    calendars: [
      {
        id: 'cal1',
        name: 'Personal',
        color: "green",
        backgroundColor: 'yellow'
      },
    ],
    
    month: {
      startDayOfWeek: 1
    },
    isReadOnly: true,
  });

  const patras = () => {
    calendar.prev();
    //y con un dispatch?
    let newmonth = month;
    if (newmonth === 0) {
      newmonth = 11
    } else {
      newmonth = newmonth - 1
    }
    setMonth(newmonth)
    calendar.prev();

  }

  const palante = () => {
    calendar.next();
    let newmonth = month;
    if (newmonth === 11) {
      newmonth = 0
    } else {
      newmonth = newmonth + 1
    }
    setMonth(newmonth)
  }

  const prueba = () => {
    calendar.createEvents([
      {
        id: '1',
        calendarId: 'cal1',
        title: 'my event',
        category: 'time',
        dueDateClass: '',
        start: '2023-07-19',
        end: '2023-07-21',
        backgroundColor: "brown",
        color: "pink"
      },
      {
        id: '2',
        calendarId: 'cal1',
        title: 'second event',
        category: 'time',
        dueDateClass: '',
        start: '2023-07-19',
        end: '2023-07-20',
      },
    ]);
  }
  


  let aa = new TZDate(2023, 2)
  useEffect(() => {
  }, [calendar.prev]);

  return (
    <div className="chat-container">
      <div id="calendar" style={{height: "600px"}}>
      <button onClick={() => prueba()} style={{width: "40px", height: "40px", margin: "20px"}}></button>
      <button onClick={() => {patras()}} style={{width: "40px", height: "40px", margin: "20px", backgroundColor: "yellow"}}></button>
      <button onClick={() => palante()} style={{width: "40px", height: "40px", margin: "20px", backgroundColor: "red"}}></button>
      <button onClick={() => calendar.today()} style={{width: "40px", height: "40px", margin: "20px", backgroundColor: "red"}}></button>
      <button onClick={() => calendar.getDate()} style={{width: "40px", height: "40px", margin: "20px", backgroundColor: "red"}}></button>
      <Text>{month}</Text>
      </div>
    </div>
  );
};
 */