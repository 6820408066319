import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const CenteredTitle = styled(Modal.Title)`
  text-align: center;
  padding: 0;
  margin-top: 0;
`;

export const LayoutGreyShadow = styled.div`
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  position: absolute;
  width: 100%;
  height: 106%;
  margin-top: -36px;
  margin-left: -30px;
  order: 1;
  z-index: 5;
  overflow: hidden;
`;
