import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { filteredAllDevicesActionCreator, saveFirstNodesFromGroupsActionCreator, saveNodesFromGroupsActionCreator } from "../../redux/slices/devices/filteredDevicesSlice";
import { setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setNodesInAreaActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { FilterByGroupStyled } from "./FilterByGroupStyled";
import search from "../../assets/new_icons/Search.svg";

interface FilterByGroupProps {}

export const FilterByGroup = ({ }: FilterByGroupProps) => {  
  const [showList, setShowList] = useState(false);
  //const [checked, setChecked]:any = useState({});
  const [searchGroup, setSearchGroup]:any = useState();
  //const count = Object.values(checked).filter(Boolean).length;
  
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groups.groups);
  const allDevices = useAppSelector((state) => state.devices);
  const nodesFromGroups = useAppSelector((state) => state.filteredDevices.nodesFromGroups);
  const [checked, setChecked] = useState(groups.reduce((acc:any, group:any) => ({ ...acc, [group.groupId]: false }), {}));
  const count = Object.values(checked).filter(Boolean).length;

  const { t } = useTranslation();

  const addOrDeleteNodesToMap = async (newNodes: any, state: boolean) => {
    if (!nodesFromGroups) {
      dispatch(saveFirstNodesFromGroupsActionCreator({ nodes: newNodes, nodesFromGroups: newNodes }))
    } else if (!state) {
      let tempNodesFromGroups = [...nodesFromGroups];
      let tempNewNodes = [...newNodes];

      for (let i = 0; i < tempNodesFromGroups.length; i++) {
        let index = tempNewNodes.indexOf(tempNodesFromGroups[i]);
        if (index !== -1) {
          tempNodesFromGroups.splice(i, 1);
          tempNewNodes.splice(index, 1);
          i--;
        }
      }

      let tempNodesFromGroupsParsed: number[] = [];
      for (let element of tempNodesFromGroups) {
        if (!tempNodesFromGroupsParsed.includes(element)) {
          tempNodesFromGroupsParsed.push(element);
        }
      }

      dispatch(saveFirstNodesFromGroupsActionCreator({ nodes: tempNodesFromGroupsParsed, nodesFromGroups: tempNodesFromGroups }));
    } else {
      dispatch(saveNodesFromGroupsActionCreator({ nodes: newNodes, nodesFromGroups: newNodes }))
    }
  }

  useEffect(() => {
    const handleClickOutsideGroups = (event: MouseEvent) => {
      if (
        showList &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("filter__title-container") &&
        !event.target.classList.contains("title-container__title") &&
        !event.target.classList.contains("picture-class")
      ) {
        setShowList(!showList);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideGroups);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideGroups);
    };
  }, [showList]);

  return (
    <FilterByGroupStyled>
      <div onClick={(e) => { dispatch(setNodesInAreaActionCreator(false)); dispatch(setIsOpenedGroupActionCreator(false));
        !Object.values(checked).some(value => value === true) && dispatch(filteredAllDevicesActionCreator(allDevices));
        e.stopPropagation(); setShowList(!showList); }} className="filter__title-container">
        <span className="title-container__title">
          {t("Groups")} {count !== 0 && !showList && `(${count})`}
        </span>
        <span className="material-symbols-rounded title-container__icon">
          {showList ? "expand_less" : "expand_more"}
        </span>
      </div>
      {showList ?
        <article>
          <div style={{ cursor: "default", paddingLeft: "15px", paddingRight: "15px", marginBottom: "8px", position: 'relative', display: 'inline-block',  width: "100%" }}>
            <img src={search} width={16} style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)' }}/>
            <input type="text" placeholder="Search" value={searchGroup} onChange={(e) => setSearchGroup(e.target.value)} style={{ border: "none", background: "rgba(189, 189, 189, 0.2)",
              paddingLeft: "43px", width: "100%", borderRadius: "4px"}}
            />
          </div>
          <div style={{maxHeight: "336px", overflowY: "auto", width: "100%" }}>
          {groups && (
            <>
              <style>
                {`
                  .group-span:hover {
                    background-color: #1c1b1f11;
                  }
                `}
              </style>
              <span
                className="group-span"    
                style={{ display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  isolation: "isolate",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minHeight: "56px",
                  cursor: "default",
                  textAlign: "left",
                  backgroundColor: Object.keys(checked).length > 0 && Object.values(checked).every(val => val) ? "#1c1b1f11" : "",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <input
                    type="checkbox"
                    color="#ff0000"
                    checked={Object.keys(checked).length > 0 && Object.values(checked).every(val => val)}
                    className="form-check-input"
                    id="all"
                    onChange={() => {
                      dispatch(setIsOpenedDetailActionCreator(false));
                      dispatch(setIsOpenedGroupActionCreator(false));
                      const allChecked = Object.values(checked).every(val => val); // Comprueba si todos los checkboxes están marcados
                      setChecked((prevChecked:any) => {
                        const newChecked = {...prevChecked};
                        for (let key in newChecked) {
                          newChecked[key] = !allChecked; // Cambia el estado de todos los checkboxes al estado opuesto del master checkbox
                        }
                        return newChecked;
                      });
                    }}
                    style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: Object.keys(checked).length > 0 && Object.values(checked).every(val => val) ? "#101e47" : "#FFFFFF" }}
                  />
                  ALL
                </div>
              </span>
            </>
          )}
          {(searchGroup ? groups.filter((group:any) => group.groupName?.toLowerCase().includes(searchGroup?.toLowerCase())) : groups).map((group: any, index: any) => (
            <>
              <style>
                {`
                  .group-span:hover {
                    background-color: #1c1b1f11;
                  }
                `}
              </style>
              <span
                className="group-span"
                key={index}
                style={{ display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  isolation: "isolate",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  minHeight: "56px",
                  cursor: "default",
                  textAlign: "left",
                  backgroundColor: checked[group.groupId] ? "#1c1b1f11" : "",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <input
                    type="checkbox"
                    color="#ff0000"
                    checked={checked[group.groupId] || false}
                    className="form-check-input"
                    id={group.groupId}
                    onChange={() => {
                      dispatch(setIsOpenedDetailActionCreator(false));
                      dispatch(setIsOpenedGroupActionCreator(false));
                      setChecked((prevChecked:any) => {
                        const newChecked = {...prevChecked, [group.groupId]: !prevChecked[group.groupId]};
                        addOrDeleteNodesToMap(group.nodes, newChecked[group.groupId]);
                        return newChecked;
                      });
                    }}
                    style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: checked[group.groupId] ? "#101e47" : "#FFFFFF" }}
                  />
                  {group.groupName}
                </div>
              </span>
            </>
          ))}
          </div>
        </article>
      :
        showList && groups.length === 0 ?
          <>you have none</>
        :
          <></>
      }
    </FilterByGroupStyled>
  );
};
