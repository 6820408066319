import { createSlice } from "@reduxjs/toolkit";
import { generateRandomHexCode } from "../../../utils/hexGenerator";
import { IUserRegistrationState } from "../../../types/interfaces";

const initialState: IUserRegistrationState = {
  user: {
    first_name: "",
    last_name: "",
    phoneNumber: "",
    email: "",
    password: "",
    password_confirm: "",
    user_language: "",
    role_id: "",
    organization_id: "",
    service_id: "",
    color: ""
  },
  step: 1,
  organizationPermits: [],
  servicePermits: []
};

const userRegistrationSlice: any = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    registerUser: (state, action) => {
      state.user.color = generateRandomHexCode();
      const payloadKeys = Object.keys(action.payload);
      // Iterate over keys in action.payload
      payloadKeys.forEach((key: any) => {
        // If the key exists in state.user, replace its value with the new value
        if (state.user.hasOwnProperty(key)) {
        // @ts-ignore
        state.user[key] = action.payload[key];
        } else {
          // If the key does not exist in state.user, add it to the user object
          state.user = { ...state.user, [key]: action.payload[key] };
        }
      });
    },

    resetUser: (state) => {
      state.user = {
        first_name: null,
        last_name: null,
        phoneNumber: null,
        email: null,
        password: null,
        password_confirm: null,
        user_language: null,
        role_id: null,
        organization_id: null,
        service_id: null,
        color: generateRandomHexCode()
      }
      state.step = 1;
    },

    changeStep: (state, action) => {
      state.step = action.payload
    },

    setOrganizationPermits: (state, action) => {
      state.organizationPermits = action.payload;
    },

    setServicePermits: (state, action) => {
      state.servicePermits = action.payload
    }
  }
})

export const userRegistrationReducer = userRegistrationSlice.reducer;

export const {
  registerUser: registerUserActionCreator,
  changeStep: changeStepActionCreator,
  setOrganizationPermits: setOrganizationPermitsActionCreator,
  setServicePermits: setServicePermitsActionCreator,
  resetUser: resetUserActionCreator
} = userRegistrationSlice.actions;
