import { User } from "../types/interfaces";

export const useProfileService = () => {
  const webAddressPrefixGet: string = process.env.REACT_APP_API_GET_PROFILE_LOCAL_BASE_URL as string;
  const webAddressPrefixUpdatePersonalInfo: string = process.env.REACT_APP_API_UPDATE_PERSONAL_INFO_LOCAL_BASE_URL as string;
  const webAddressPrefixUpdateLanguage: string = process.env.REACT_APP_API_UPDATE_LANGUAGE_LOCAL_BASE_URL as string;
  const webAddressPrefixUpdatePassword: string = process.env.REACT_APP_API_UPDATE_PASSWORD_LOCAL_BASE_URL as string;

  const getUserProfile = async (token: string): Promise<User | null> => {
    try {
      const response = await fetch(`${webAddressPrefixGet}`, {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const [userData] = await response.json();

        const user: User = {
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone_number: userData.phone_number,
          email: userData.email,
          language_name: userData.language_name,
          role_name: userData.role_name,
          user_active: userData.user_active,
          color: userData.color
        };

        return user;
      } else {
        console.log("Failed to fetch user profile.", response.status);
        return null;
      }
    } catch (error) {
      console.log("Error while fetching user profile:", error);
      return null;
    }
  };

  const updatePersonalInfo = async (token: string, firstName: string, lastName: string, phoneNumber: string) => {
    try {
      const response = await fetch(`${webAddressPrefixUpdatePersonalInfo}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  };

  const updateLanguage = async (token: string, languageId: number) => {
    try {
      const response = await fetch(`${webAddressPrefixUpdateLanguage}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_language: languageId,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  };

  const updatePassword = async (token: string, currentPassword: string, newPassword: string, confirmPassword: string) => {
    try {
      const response = await fetch(`${webAddressPrefixUpdatePassword}`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          current_password: currentPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw new Error("There was an error. Please try again later.");
    }
  };

  return {
    getUserProfile,
    updatePersonalInfo,
    updateLanguage,
    updatePassword
  };
};
