import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Devices, Lamp } from "../../../types/interfaces";

const initialState: Devices = {
  gateways: [],
  nodes: [],
  rgbNodes: [],
  nodesFromGroups: [],
  nodesInArea: [],
  organizations: [],
  selectedOrganizationId: 0,
  services: [],
  selectedServiceId: 0
};

const filteredDevicesSlice:any = createSlice({
  name: "filteredDevices",
  initialState: initialState,
  reducers: {
    filteredAllDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...action.payload,
    }),

    filteredNodesInArea: (previousState, action) => ({
      ...previousState,
      nodesInArea: action.payload
    }),

    saveFirstNodesFromGroups: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: action.payload.nodes,
      nodesFromGroups: action.payload.nodesFromGroups
    }),

    saveNodesFromGroups: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: [...previousState.nodes, ...action.payload.nodes],
      nodesFromGroups: [...previousState.nodesFromGroups, ...action.payload.nodesFromGroups]
    }),

    filteredAllDevicesFromGroups: (previousState, action) => ({
      ...previousState,
      nodes: action.payload
    }),

    //slice que me traiga toda la info de deviceslice, que es la correcta, pero machaco X partes con la info que he tratado en filtered devices. O quizás esto último ºno haga falta?

    /* filteredSaveNodes: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: [...previousState.nodes, ...action.payload.nodes]
    }), */

    filteredNewOrderDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload
    }),

    filteredNewOrderAreaDevices: (previousState, action) => ({
      ...previousState,
      nodesInArea: action.payload
    }),

    filterOnDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload.filter((node:any) => node.online && node.on),
      nodesInArea: action.payload.filter((node:any) => node.online && node.on)
    }),

    filterOffDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload.filter((node:any) => node.online && !node.on),
      nodesInArea: action.payload.filter((node:any) => node.online && !node.on)
    }),

    filterConnectedDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload.filter((node:any) => node.online),
      nodesInArea: action.payload.filter((node:any) => node.online)
    }),
    
    /*filterConnectedDevices: (previousState, action: PayloadAction<Devices>) => {
      console.log('filterDisconnectedDevices called');
      console.log('previousState:', previousState);
      console.log('action.payload:', action.payload);
      console.log('action.payload.nodes:', action.payload.nodes);
    
      return {
      ...previousState,
      nodes: action.payload.nodes.filter((node: any) => node.online) 
      };
    },*/

    /* filterDisconnectedDevices: (previousState, action) => {
      if (action.type === "hola") {
        return {
          ...previousState,
          filteredNodesFromGroups: action.payload.filter((node: any) => !node.online)
        };
      }

      if (action.type === "hela") {
        return {
          ...previousState,
          nodes: action.payload.filter((node: any) => !node.online)
        };
      }

      return previousState;
    }, */

    filterDisconnectedDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload.filter((node: any) => !node.online),
      nodesInArea: action.payload.filter((node: any) => !node.online)
    }),
    
    filterAlarmDevices: (previousState, action) => ({
      ...previousState,
      nodes: action.payload.filter((node:any) => !node.alarm_status),
      nodesInArea: action.payload.filter((node:any) => !node.alarm_status)
      //nodes: action.payload.nodes.filter((node) => node.alarm_status),
    }),

    updateNodeDeviceStatusRedux: (previousState, action) => {
      const { node_id, msg_type, value } = action.payload;

      const updatedNodes = previousState.nodes.map((node:any) => {
        if (node.node_id === node_id) {
          switch (msg_type) {
            case 2:
              return { ...node, on: 0, online: 1 };
            case 1:
              return { ...node, on: 1, online: 1 };
            case 3:
              return { ...node, on: 1, bri: value, online: 1 };
            case 4:
              return { ...node, on: 0, online: 0 };
            default:
              return node;
          } 
        }
        return node;
      });

      const updatedNodesRgb = previousState.rgbNodes.length > 0 ? 
        previousState.rgbNodes.map((node:any) => {
          if (node.node_id === node_id) {
            switch (msg_type) {
              case 41:
                return { ...node, on: 0, online: 1 };
              case 40:
                const values = value.split(', ');
                const R = parseInt(values[0]);
                const G = parseInt(values[1]);
                const B = parseInt(values[2]);
  
                return { ...node, on: 1, R: R, G: G, B: B, online: 1 };
              case 4:
                return { ...node, on: 0, online: 0 };
              default:
                return node;
            } 
          }
          return node;
        }) : previousState.rgbNodes;

      const updatedAreaNodes = previousState.nodesInArea && previousState.nodesInArea.length > 0 ? 
        previousState.nodesInArea.map((node:any) => {
          if (node.node_id === node_id) {
            switch (msg_type) {
              case 2:
                return { ...node, on: 0, online: 1 };
              case 1:
                return { ...node, on: 1, online: 1 };
              case 3:
                return { ...node, on: 1, bri: value, online: 1 };
              case 4:
                return { ...node, on: 0, online: 0 };
              default:
                return node;
            } 
          }
          return node;
        }) : previousState.nodesInArea;

      return { ...previousState, nodes: updatedNodes, rgbNodes: updatedNodesRgb, nodesInArea: updatedAreaNodes };
    },

    /* updateNodeDeviceStatusRedux: (previousState, action) => {
      //si estás con una selección abierta, una area creada, esto no se verá en pantalla
      const nodeToUpdate = previousState.nodes.find(
        (node) => node.node_id === action.payload.node_id
      );

      console.log("payload redux")
      console.log(action.payload)

      previousState.nodes.forEach((node) => {
        if (nodeToUpdate !== undefined) {
          if (node.node_id === nodeToUpdate.node_id) {
            if(action.payload.msg_type === 2 || action.payload.msg_type === 41) {
              node.on = 0;
              node.online = 1;
            }
            else if(action.payload.msg_type === 1 || action.payload.msg_type === 40) {
              node.on = 1;
              node.online = 1;
            }
            else if(action.payload.msg_type === 3) {
              node.on = 1;
              node.bri = action.payload.value;
              node.online = 1;
            }
            else if(action.payload.msg_type === 4) {
              node.on = 0;
              node.online = 0;
            }
          }
        }  
      });
    }, */

    updateNodeDeviceStatus: (previousState, action: PayloadAction<Lamp>) => {
      const { node_id, on, selected, bri } = action.payload;

      const updatedNodes = previousState.nodes.map((node) => {
        if (node.node_id === node_id) {
          return {
            ...node,
            on,
            selected,
            bri,
          };
        }
        return node;
      });

      const updatedAreaNodes = previousState.nodesInArea && previousState.nodesInArea.length > 0 ? 
        previousState.nodesInArea.map((node:any) => {
          if (node.node_id === node_id) {
            return {
              ...node,
              on,
              selected,
              bri,
            };
          }
          return node;
        }) : previousState.nodesInArea;

      return { ...previousState, nodes: updatedNodes , nodesInArea: updatedAreaNodes };
    },

   /*  updateNodeDeviceStatus: (previousState, action: PayloadAction<Lamp>) => {
      const nodeToUpdate = previousState.nodes.findIndex(
        (node) => node.node_id === action.payload.node_id
      );
      
      console.log("payload")
      console.log(action.payload.selected)
      console.log("previo")
      console.log(previousState.nodes[0].selected)

      previousState.nodes.forEach((node, index) => {
        if (index === nodeToUpdate) {
          node.on = action.payload.on;
          node.selected = action.payload.selected;
          node.bri = action.payload.bri;
        }
      });
    }, */
  },
});

export const filteredDevicesReducer = filteredDevicesSlice.reducer;

export const {
  filteredAllDevices: filteredAllDevicesActionCreator,
  filteredNodesInArea: filteredNodesInAreaActionCreator,
  saveFirstNodesFromGroups: saveFirstNodesFromGroupsActionCreator,
  saveNodesFromGroups: saveNodesFromGroupsActionCreator,
  filteredAllDevicesFromGroups: filteredAllDevicesFromGroupsActionCreator,
  /* filteredSaveNodes: filteredSaveNodesActionCreator, */
  filteredNewOrderDevices: filteredNewOrderDevicesActionCreator,
  filteredNewOrderAreaDevices: filteredNewOrderAreaDevicesActionCreator,
  filterOnDevices: filterOnDevicesActionCreator,
  filterOffDevices: filterOffDevicesActionCreator,
  filterConnectedDevices: filterConnectedDevicesActionCreator,
  filterDisconnectedDevices: filterDisconnectedDevicesActionCreator,
  filterAlarmDevices: filterAlarmDevicesActionCreator,
  updateNodeDeviceStatus: updateNodeDeviceStatusActionCreator,
  updateNodeDeviceStatusRedux: updateNodeDeviceStatusReduxActionCreator
} = filteredDevicesSlice.actions;
