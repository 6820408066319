import { useState, useEffect } from "react";
import { useAppSelector  } from "../../redux/hooks";
import { formatAMPM } from "../../utils/dateutils";
import { LocalWeatherStyled } from "./LocalWeatherStyled";
import sun from "../../assets/new_icons/Solar.svg"

//QUEDA PENDIENTE BUSCAR UNA API DEL TIEMPO QUE TAMBIÉN NOS DE LAS ZONAS HORARIAS
export const LocalWeather = () => {
  const [location, setLocation] = useState("");
  const [time, setTime] = useState(formatAMPM(new Date()));

  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const latitude = useAppSelector((state) => state.selectedOrganization.latitude);
  const longitude = useAppSelector((state) => state.selectedOrganization.longitude); 

  const actualTime = time.hours + ":" + time.formatedMinutes;
  const amPm = time.amPm.toUpperCase();

  useEffect(() => {
    fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`)
    .then(function(response) {
        return response.json();
    })
    .then(function(data) {
        if (data.address.city) {
          setLocation(data.address.city + ", " + data.address.country);
        } else if (data.address.village) {
          setLocation(data.address.village + ", " + data.address.country);
        } else if (data.address.town) {
          setLocation(data.address.town + ", " + data.address.country);
        }
    });
  }, [latitude, longitude]);

  setInterval(() => {
    setTime(formatAMPM(new Date()));
  }, 60000);

  return (
    <LocalWeatherStyled style={!fullView && !hybridView ? { position: "relative", height: "90px", bottom: "0px", left: "0px" } : { position: "absolute" }}>
      <img src={sun} width={44} />
      <div className="weather-card__content-container">
        {/* Podemos gestionar la localización según el openstreetmap o según nuestra propia base de datos */}
        {/* <h1 className="weather-card__title">
          {location ? location : "unknown location"}
        </h1> */}
        <h1 className="weather-card__title">{city}</h1>
        <div className="weather-card__time-container">
          <h2 className="weather-card__content">{actualTime}</h2>
          <p className="weather-card__am-pm">{amPm}</p>
        </div>
      </div>
    </LocalWeatherStyled>
  );
};
