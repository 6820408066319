import Cookies from "js-cookie";
const webAddressPrefixGet: string = process.env
    .REACT_APP_LOCAL_BASE_URL as string;

const token = Cookies.get("token");

export const getOrganizationPermits = async () => {
    try {
        let response = await fetch(
            `${webAddressPrefixGet}/api/users/user-org?userId=26`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (e) {
        return false;
    }
}


export const getServicePermits = async () => {
    try {
        let response = await fetch(
            `${webAddressPrefixGet}/api/users/user-serv?userId=26`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        const data = await response.json();
        return data;
    } catch (e) {
        return false;
    }
}

export const registerUser = async (data: any) => {
    const { phoneNumber, ...user } = data
    try {
        let response = await fetch(
            `${webAddressPrefixGet}/api/users/register`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(user), // Convert data to JSON string
            }
        )
        console.log(response, response.status, "Check")
        const res = await response.json();
        if (response?.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}
