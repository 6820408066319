import styled from "styled-components";

export const FilterElementStyled = styled.article`
  div {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 8px; 
    background: #ffffff;
    border: 1.5px solid #a3aed0;
    border-radius: 30px;
    transition: scale 0.2s ease-in-out;
  }

  div:hover {
    transform: scale(1.05);
  }
 
  h1 {
    margin: 0;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #475569;
  }

  .icon {
    font-size: 1rem;
  }

  .on {
    color: green;
  }

  .off,
  .alarmed {
    color: red;
  }
  .active {
    gap: 10px;
    border: 2px solid #1B2559;
    border-collapse: collapse;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    white-space: nowrap
  }

  .active > h1 {
    color: #1b2559;
    font-weight: bold;
  }

  .selectArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    gap: 10px;
    background: #fff;
    border: 1.5px solid rgb(163, 174, 208);
    border-radius: 30px;
  }
`;
