import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const CenteredTitle = styled(Modal.Title)`
  text-align: center;
  padding: 0;
  margin-top: 0;
`;

export const LayoutGreyShadow = styled.div`
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  position: absolute;
  width: 77.8%;
  max-width: 90%;
  height: 100.3%;
  margin-top: -9px;
  order: 1;
  z-index: 5;
  overflow: hidden;
`;
