import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { resetStrategyActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../../services/strategiesService";
import DimmingChart from "../Step2Dimming/DimmingChart";
import { parseLabelsAndData, getHoursAndValues, calculateEnergySaving } from "../../StrategiesLogic";
import { languages } from "../../../../utils/languages/languages";
import { CardContainer, ButtonStrategy, NextButton } from "../StrategiesStepsStyled";
import { styledMainTheme } from "../../../../styles/shared-styles/styleMainTheme";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../../assets/new_icons/Energy.svg";
import stepDot from "../../../../assets/new_icons/step - dot.svg";
import stepLine from "../../../../assets/new_icons/step - line.svg";
import sunriseIcon from "../../../../assets/new_icons/Sunrise.svg";
import sunsetIcon from "../../../../assets/new_icons/Sunset.svg";

interface ReviewProps {
  onHide?: any;
  setShowDetails?: any;
  setShowDimming?: any;
  showDimming?: any;
  setShowReview?: any;
  setNewChartData?: any;
  newChartData?: any;
}

export const Review = ({
  onHide,
  setShowDetails,
  setShowDimming,
  showDimming,
  setShowReview,
  setNewChartData,
  newChartData,
}: ReviewProps) => {
  const dispatch = useAppDispatch();
  const { postStrategy, getStrategies } = useStrategiesService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const newStrategy = useAppSelector((state) => state.strategies);
  const name = useAppSelector((state) => state.strategies.name);
  const description = useAppSelector((state) => state.strategies.description);
  const color = useAppSelector((state) => state.strategies.color);
  const value = useAppSelector((state) => state.strategies.saving);
  const astroDim = useAppSelector((state) => state.strategies.astroDim);
  const offsetSunset = useAppSelector((state) => state.strategies.offsetSunset);
  const offsetSunrise = useAppSelector((state) => state.strategies.offsetSunrise);
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];

  const theme = {
    primaryColor: styledMainTheme?.light?.smartecGreen,
  };

  const sendInfo = async () => {
    await postStrategy(newStrategy.labels, newStrategy.data);
    await getStrategies(selectedOrgId, selectedServiceId);
    dispatch(resetStrategyActionCreator());
    onHide();
  };

  useEffect(() => {
    if (newChartData) {
      let parsedLabelsAndData = parseLabelsAndData(
        newChartData.labels,
        newChartData.datasets[0].data
      );
      let hoursWithValues = getHoursAndValues(
        parsedLabelsAndData[0],
        parsedLabelsAndData[1]
      );
      calculateEnergySaving(hoursWithValues);
    }
  }, []);

  const { t } = useTranslation();

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "4px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step3")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewConfiguration")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("reviewConfigurationSubtitleStrategies")}</Text>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "600px" }}>
          <Text marginBottom="0px" color={color.value} fontWeight="500" style={{fontSize: "18px"}}>{name}</Text>
          <Text textType="caption" marginBottom="0px" style={{fontSize: "12px", wordWrap: "break-word"}}>{description}</Text>
        </div>
        <NextButton style={{ padding: "3px 16px", paddingLeft: "6px", height: "39px", boxShadow: "none", background: color.value, border: "none", fontSize: "12px",
          cursor: "auto", marginBottom: "0px" }}
        >
          <EnergyIcon height={16} style={{ color: "white", marginTop: "-2px", marginLeft: "0px", paddingLeft: "0px" }} />
          {newChartData ? value : 0}{t("percentageEnergySaved").toUpperCase()}
        </NextButton>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "50px", marginTop: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
          <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{txt.astroDim}</Text>
          <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "100px", marginBottom: "1px",
            boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
          >
            <Text color="#A3AED0" style={{ fontSize: "14px", marginTop: "5px" }}>{astroDim ? txt.yes.toUpperCase() : txt.no.toUpperCase()}</Text>     
          </div>
        </div>
        {astroDim ?
          <div style={{ display: "flex", gap: "18px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <img src={sunsetIcon} width={24} />
              <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "110px", marginBottom: "1px",
                boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
              >
                { offsetSunset ?
                    <Text color="#A3AED0" style={{ fontSize: "14px", marginTop: "5px", }}>{offsetSunset} {txt.minOffset.toUpperCase()}</Text>
                  :
                    <Text color="#A3AED0" style={{ fontSize: "14px", marginTop: "5px", }}>{txt.noOffset.toUpperCase()}</Text>
                }
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <img src={sunriseIcon} width={24} />
              <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "110px", marginBottom: "1px",
                boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
              >
                {offsetSunrise ?
                    <Text color="#A3AED0" style={{ fontSize: "14px", marginTop: "5px" }}>{offsetSunrise} {txt.minOffset.toUpperCase()}</Text>
                  :
                    <Text color="#A3AED0" style={{ fontSize: "14px", marginTop: "5px" }}>{txt.noOffset.toUpperCase()}</Text>
                }
              </div>
            </div>
          </div>
        :
          <></>
        }
      </div>
      <DimmingChart setNewChartData={setNewChartData} newChartData={newChartData} />
      <ButtonStrategy buttonType="primary" style={{justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "96px", marginLeft: "-40px", padding: "8px 32px", lineHeight: "none" }}
        onClick={() => {sendInfo()}}
      >
        {txt.create}
      </ButtonStrategy>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
        <img src={stepDot} width={8} style={{ opacity: 0.3 }} />
        <img src={stepLine} width={22} />
      </div>
    </CardContainer>
  );
};
