export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let amPm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const formatedMinutes =
    minutes / 10 < 1 ? "0" + minutes.toString() : minutes.toString();

  return { hours, formatedMinutes, amPm };
};
