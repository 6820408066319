import { useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);


const labels = ["0", "5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"];

{/* <div style={{ height: "207px", width: "430px", display: "flex" }}>
  <Line options={options} data={data} style={{flexGrow: 1}} />
</div> */}

function ConsumptionChart() {
  const soc = useAppSelector((state) => state.solarParameters.soc);
  const solarBattVolt = useAppSelector((state) => state.solarParameters.solarBattVolt);
  const solarBattCurrent = useAppSelector((state) => state.solarParameters.solarBattCurrent);
  const lumPowerConsum = useAppSelector((state) => state.solarParameters.lumPowerConsum);
  const panelChargePower = useAppSelector((state) => state.solarParameters.panelChargePower);
  const hours = useAppSelector((state) => state.solarParameters.timestamps);

  console.log("y esto", soc, hours)

  const chartRef:any = useRef(null);
  const { t } = useTranslation();
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        align: 'start' as const,
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect', /* 'circle', 'rect', 'rectRounded' */
          /* padding: 12, */
          boxHeight: 12,
          font: {
            size: 16
          }
        }
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false, // Desactiva el salto automático de etiquetas
          }
        }
      }
    }
  };

  const data = {
    labels: hours,
    datasets: [
      {
        fill: true,
        label: t("pannel"),
        data: soc,
        borderColor: "#16D764",
        backgroundColor: "rgba(22, 215, 100, 0.4)",
        lineTension: 0.4,
        pointRadius: 0,
        borderWidth: 1
      },
      {
        fill: true,
        label: t("Battery"),
        data: solarBattVolt,
        borderColor: "#00C7F2",
        backgroundColor: "rgba(0, 199, 242, 0.4)",
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 1
      },
      {
        fill: true,
        label: t("Luminaire"),
        data: solarBattCurrent,
        borderColor: "#FFCB21",
        backgroundColor: "rgba(255, 203, 33, 0.4)",
        stepped: true,
        pointRadius: 0,
        borderWidth: 1
      },
      {
        fill: true,
        label: t("pannel"),
        data: lumPowerConsum,
        borderColor: "#16D764",
        backgroundColor: "red",
        lineTension: 0.4,
        pointRadius: 0,
        borderWidth: 1
      },
      {
        fill: true,
        label: t("Battery"),
        data: panelChargePower,
        borderColor: "#00C7F2",
        backgroundColor: "orange",
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 1
      }
    ],
  };

  console.log("la data", data)

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;

      chartInstance.data = data;
      chartInstance.options = options;

      chartInstance.update();
    }
  }, [data, options, soc, solarBattVolt, solarBattCurrent, lumPowerConsum, panelChargePower]);

  //width 630
  return (
    //width 56
    <Line width={850} height={190} options={options} data={data} />
  )
}

export default ConsumptionChart;
