import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { languages } from "../../../utils/languages/languages";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";
import alarmTime from "../../../assets/new_icons/alarm-time.svg";
import arrowRight from "../../../assets/new_icons//arrow_right_full.svg";
import ellipseBlack from "../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../assets/new_icons/ellipse_blue.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { useTranslation } from "react-i18next";

interface TemporaryEventsCardProps {}

export const TemporaryEventsCard = ({}: TemporaryEventsCardProps) => {
  const navigate = useNavigate();
  const currentLanguage = useAppSelector((state) => state.ui.actualLanguage);
  const txt = languages[currentLanguage];
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  //apply movement to events' tab
  return (
    <div
      className="card"
      style={{
        padding: "10px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        borderRadius: "16px",
        flexGrow: 1,
      }}
    >
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div>
            <Text color="#A3AED0">{t(txt.temporaryEvents)}</Text>
          </div>
          {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
          </div> */}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "140px" }}>
        {/* <Text marginBottom="0px" textType="subTitle" color="#A3AED0">{txt.disabledDemo}</Text> */}
        <Text>{t("noEventAssigned")}</Text>
      </div>
      {/* <div className="row">
        <nav>
          <div
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{ border: "none" }}
          >
            <a
              className="nav-item nav-link active"
              id="nav-upcoming-tab"
              data-toggle="tab"
              href="#nav-upcoming"
              role="tab"
              aria-controls="nav-upcoming"
              aria-selected="true"
              style={{
                padding: "16px 8px",
                border: "none",
                borderBottom: "2px solid #000000",
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {t(txt.upcoming)}
            </a>
            <a
              className="nav-item nav-link"
              id="nav-past-tab"
              data-toggle="tab"
              href="#nav-past"
              role="tab"
              aria-controls="nav-past"
              aria-selected="false"
              style={{
                padding: "16px 48px",
                border: "none",
                borderBottom: "1px solid #bdbdbd",
                fontSize: "12px",
                color: "#bdbdbd",
              }}
            >
              {t(txt.past)}
            </a>
          </div>
        </nav>
        <div
          className="tab-content"
          id="nav-tabContent"
          style={{
            paddingBottom: "5px",
            paddingLeft: "11px",
            paddingRight: "11px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              borderBottom: "1px solid #E7E0EC",
              padding: "5px",
            }}
          >
            <div>
              <Text
                textType=""
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                FEB{" "}
              </Text>
              <Text
                textType="subTitle"
                fontWeight="700"
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                21
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Text
                  fontWeight="700"
                  marginBottom="0px"
                  color={styledMainTheme.light.contentFontColor}
                >
                  Easter{" "}
                </Text>
                <img
                  src={ellipseBlack}
                  style={{
                    width: "10px",
                    height: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    borderRadius: "50%",
                  }}
                />
              </div>
              <Text
                style={{ fontSize: "12px" }}
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                Special event for Easter at the main avenue street
              </Text>
            </div>
            <div style={{ alignSelf: "center", textAlign: "right" }}>
              <img src={arrowRight} width={6} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
              borderBottom: "1px solid #E7E0EC",
              padding: "5px",
            }}
          >
            <div>
              <Text
                textType=""
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                OCT{" "}
              </Text>
              <Text
                textType="subTitle"
                fontWeight="700"
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                31
              </Text>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Text
                  fontWeight="700"
                  marginBottom="0px"
                  color={styledMainTheme.light.contentFontColor}
                >
                  Halloween{" "}
                </Text>
                <img
                  src={ellipseBlue}
                  style={{
                    width: "10px",
                    height: "10px",
                    flex: "none",
                    order: 1,
                    flexGrow: 0,
                    borderRadius: "50%",
                  }}
                />
              </div>
              <Text
                style={{ fontSize: "12px" }}
                marginBottom="0px"
                color={styledMainTheme.light.titleFontColor}
              >
                Special event for Halloween at the main avenue street
              </Text>
            </div>
            <div style={{ alignSelf: "center", textAlign: "right" }}>
              <img src={arrowRight} width={6} />
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <button
              onClick={() => {
                navigate("/coming-soon");
              }}
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#ffffff",
                color: "#475569",
                fontWeight: "700",
                fontSize: "14px",
                padding: "6px 18px",
                border: "1px solid #475569",
                borderRadius: "64px",
                cursor: "pointer",
              }}
            >
              <img
                src={alarmTime}
                height={12}
                style={{ marginRight: "12px" }}
              />
              {t(txt.viewAll)}
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
